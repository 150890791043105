import { Discussion } from '@/models/discussion';
import { WriteTransaction } from 'replicache';

export async function createDiscussion(
  tx: WriteTransaction,
  args: {
    id: string;
    discussableType: string;
    discussableId: string;
    createdAt: string;
    updatedAt: string;
  }
) {
  const discussions = await Discussion.list(tx);
  const existing = discussions.find(
    (d) =>
      d.discussableType === args.discussableType &&
      d.discussableId === args.discussableId
  );

  if (existing) return;

  await Discussion.set(tx, {
    id: args.id,
    discussableType: args.discussableType,
    discussableId: args.discussableId,
    createdAt: args.createdAt,
    updatedAt: args.updatedAt,
  });
}
