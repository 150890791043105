import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ObjectPool } from './object-pool';

export class ObjectLabel extends ApplicationRecord {
  id: string;
  organizationId: string;
  labelableType: string;
  labelableId: string;
  name: string;
  confidence: number;
  xMin: number;
  yMin: number;
  xMax: number;
  yMax: number;
  createdAt: string;
  updatedAt: string;

  static prefix = 'objectLabels' as const;

  static schema = z.object({
    id: z.string(),
    organizationId: z.string(),
    labelableType: z.string(),
    labelableId: z.string(),
    name: z.string(),
    confidence: z.coerce.number(),
    xMin: z.coerce.number(),
    yMin: z.coerce.number(),
    xMax: z.coerce.number(),
    yMax: z.coerce.number(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.organizationId = this.attribute('organizationId');
    this.labelableType = this.attribute('labelableType');
    this.labelableId = this.attribute('labelableId');
    this.name = this.attribute('name');
    this.confidence = this.attribute('confidence');
    this.xMin = this.attribute('xMin');
    this.yMin = this.attribute('yMin');
    this.xMax = this.attribute('xMax');
    this.yMax = this.attribute('yMax');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
  }
}
