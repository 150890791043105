import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { BillingPeriod } from './billing-period';
import { Commitment } from './commitment';
import { InvoiceSubmissionAttachment } from './invoice-submission-attachment';
import { InvoiceSubmissionLineItem } from './invoice-submission-line-item';
import { ObjectPool } from './object-pool';
import { User } from './user';

export class InvoiceSubmission extends ApplicationRecord {
  id: string;
  commitmentId: string;
  submittedById: string | null | undefined;
  billingPeriodId: string | null | undefined;
  status: string;
  createdAt: string;
  updatedAt: string;

  static prefix = 'invoiceSubmissions' as const;

  static schema = z.object({
    id: z.string(),
    commitmentId: z.string(),
    submittedById: z.string().nullish(),
    billingPeriodId: z.string().nullish(),
    status: z.string().default('draft'),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.commitmentId = this.attribute('commitmentId');
    this.submittedById = this.attribute('submittedById');
    this.billingPeriodId = this.attribute('billingPeriodId');
    this.status = this.attribute('status');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
  }

  get commitment() {
    return this.belongsTo(Commitment, this.commitmentId);
  }

  get billingPeriod() {
    return this.belongsToOptional(BillingPeriod, this.billingPeriodId);
  }

  get submittedBy() {
    return this.belongsToOptional(User, this.submittedById);
  }

  get lineItems() {
    return this.hasMany(InvoiceSubmissionLineItem, 'invoiceSubmissionId');
  }

  get attachments() {
    return this.hasMany(InvoiceSubmissionAttachment, 'invoiceSubmissionId');
  }

  get amountCents() {
    return this.lineItems.reduce(
      (sum, lineItem) => sum + lineItem.amountCents,
      0
    );
  }

  get retainageCents() {
    return this.lineItems.reduce(
      (sum, lineItem) => sum + lineItem.retainageCents,
      0
    );
  }

  get subtotalCents() {
    return this.lineItems.reduce(
      (sum, lineItem) => sum + lineItem.subtotalCents,
      0
    );
  }

  get companyName() {
    return this.commitment.company.name;
  }

  get billingPeriodName() {
    return this.billingPeriod?.name;
  }

  get isForOwner() {
    return this.commitment.company.type === 'owner';
  }

  get isForSubcontractor() {
    return this.commitment.company.type === 'subcontractor';
  }
}
