import { formatDateForDisplay } from '@/lib/utils';
import { ReadonlyJSONObject } from '@rocicorp/rails';
import {
  endOfDay,
  isBefore,
  isWithinInterval,
  parseISO,
  startOfDay,
} from 'date-fns';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { InvoiceSubmission } from './invoice-submission';
import { ObjectPool } from './object-pool';

export class BillingPeriod extends ApplicationRecord {
  id: string;
  startOn: string;
  endOn: string;
  dueOn: string;
  createdAt: string;
  updatedAt: string;

  static prefix = 'billingPeriods' as const;

  static schema = z.object({
    id: z.string(),
    startOn: z.string(),
    endOn: z.string(),
    dueOn: z.string(),
    createdAt: z.string(),
    updatedAt: z.string(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.startOn = this.attribute('startOn');
    this.endOn = this.attribute('endOn');
    this.dueOn = this.attribute('dueOn');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
  }

  static current(objectPool: ObjectPool) {
    const now = new Date();
    return objectPool
      .all(BillingPeriod)
      .find((period) =>
        isWithinInterval(now, { start: period.startDate, end: period.endDate })
      );
  }

  static previous(objectPool: ObjectPool) {
    const now = new Date();
    return objectPool
      .all(BillingPeriod)
      .filter((period) => isBefore(period.endDate, now));
  }

  static ascending(objectPool: ObjectPool) {
    const all = objectPool.all(BillingPeriod);
    return all.sort((a, b) => (isBefore(a.endDate, b.endDate) ? -1 : 1));
  }

  get periodsBefore() {
    const all = this.objectPool.all(BillingPeriod);
    return all.filter((period) => isBefore(period.endDate, this.endDate));
  }

  get startDate() {
    return startOfDay(parseISO(this.startOn));
  }

  get endDate() {
    return endOfDay(parseISO(this.endOn));
  }

  get dueDate() {
    return endOfDay(parseISO(this.dueOn));
  }

  get name() {
    return `${formatDateForDisplay(this.startDate)} - ${formatDateForDisplay(this.endDate)}`;
  }

  get invoiceSubmissions() {
    return this.hasMany(InvoiceSubmission, 'billingPeriodId');
  }
}
