import { CacheType, ObjectPool } from '@/models/object-pool';
import { makeAutoObservable, runInAction } from 'mobx';
import { createContext, useContext } from 'react';
import { ReadonlyJSONObject } from 'replicache';
import { ManagedCache } from './managed-cache';
import {
  createMembershipReplicache,
  createProjectReplicache,
  createUserReplicache,
  MembershipReplicache,
  ProjectReplicache,
  UserReplicache,
} from './replicache';
import { SqliteSystem } from './sqlite';

export class CacheStore {
  private _userCache: ManagedCache<UserReplicache> | null = null;
  private _membershipCache: ManagedCache<MembershipReplicache> | null = null;
  private _projectCache: ManagedCache<ProjectReplicache> | null = null;

  public objectPool: ObjectPool;

  static initializeSingleton(objectPool: ObjectPool) {
    cacheStore = new CacheStore(objectPool);
    return cacheStore;
  }

  constructor(objectPool: ObjectPool) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.objectPool = objectPool;
  }

  public get userCache() {
    return this._userCache;
  }

  public get membershipCache() {
    return this._membershipCache;
  }

  public get projectCache() {
    return this._projectCache;
  }

  public get finishedSyncing() {
    const caches = [this.userCache, this.membershipCache, this.projectCache];
    return caches.every((cache) => !cache || cache.finishedSyncing);
  }

  public get initialLoadFinished() {
    const caches = [this.userCache, this.membershipCache, this.projectCache];
    return caches.every((cache) => !cache || cache.initialLoadFinished);
  }

  public pullAll() {
    this.userCache?.pull();
    this.membershipCache?.pull();
    this.projectCache?.pull();
  }

  public async setUserAndOrganization(
    userId: string,
    userToken: string,
    organizationId: string,
    membershipToken: string
  ) {
    const kvStore = await this.getKvStore();

    const userReplicache = createUserReplicache({
      userId,
      userToken,
      kvStore,
    });
    const userManagedCache = new ManagedCache('user-' + userId, userReplicache);
    await this.setUserCache(userManagedCache);

    const membershipReplicache = createMembershipReplicache({
      userId,
      organizationId,
      token: membershipToken,
      kvStore,
    });
    const membershipManagedCache = new ManagedCache(
      'membership-' + organizationId,
      membershipReplicache
    );
    await this.setMembershipCache(membershipManagedCache);
  }

  public async setProjectId(
    userId: string,
    organizationId: string,
    projectId: string,
    projectToken: string
  ) {
    if (this.projectCache?.cacheId === 'project-' + projectId) {
      return;
    }

    const replicache = createProjectReplicache({
      userId,
      organizationId,
      projectId,
      token: projectToken,
      kvStore: await this.getKvStore(),
    });
    const managedCache = new ManagedCache('project-' + projectId, replicache);
    await this.setProjectCache(managedCache);
  }

  async setUserCache(userCache: ManagedCache<UserReplicache>) {
    try {
      await this.userCache?.close();
    } catch (e) {
      console.error('failed to close user cache', e);
    }

    runInAction(() => (this._userCache = userCache));
    this.objectPool.removeCache('user');

    await this.watchCache('user', userCache);
    await userCache.initializeCache();
  }

  async setMembershipCache(
    membershipCache: ManagedCache<MembershipReplicache>
  ) {
    try {
      await this.membershipCache?.close();
    } catch (e) {
      console.error('failed to close membership cache', e);
    }

    runInAction(() => (this._membershipCache = membershipCache));
    this.objectPool.removeCache('membership');

    await this.watchCache('membership', membershipCache);
    await membershipCache.initializeCache();
  }

  async setProjectCache(projectCache: ManagedCache<ProjectReplicache>) {
    try {
      await this.projectCache?.close();
    } catch (e) {
      console.error('failed to close project cache', e);
    }

    runInAction(() => (this._projectCache = projectCache));
    this.objectPool.removeCache('project');

    await this.watchCache('project', projectCache);
    await projectCache.initializeCache();
  }

  private async watchCache(cacheType: CacheType, managedCache: ManagedCache) {
    await managedCache.startWatch({
      onRecordAdded: (key, value) => {
        this.objectPool.addObject(cacheType, key, value as ReadonlyJSONObject);
      },
      onRecordRemoved: (key) => {
        this.objectPool.removeObject(key);
      },
      onRecordUpdated: (key, _oldValue, newValue) => {
        this.objectPool.updateObject(key, newValue as ReadonlyJSONObject);
      },
    });
  }

  private async getKvStore() {
    try {
      if (SqliteSystem.isEnabled) {
        const sqliteSystem = await SqliteSystem.initializeSingleton();
        return sqliteSystem.kvStoreProvider;
      }
    } catch (e) {
      console.error('Error initializing sqlite system', e);
    }

    return 'idb';
  }
}

export let cacheStore: CacheStore;

export const CacheStoreContext = createContext<CacheStore | null>(null);

export const useCacheStore = () => {
  const cacheStore = useContext(CacheStoreContext);
  if (!cacheStore) {
    throw new Error('useCacheStore must be used within a CacheStoreProvider');
  }
  return cacheStore;
};
