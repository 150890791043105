import { join, removePrefix } from '@/lib/file-utils';
import { WriteTransaction } from 'replicache';
import { Document } from '../models/document';

export async function renameFolder(
  tx: WriteTransaction,
  args: {
    from: string;
    to: string;
  }
) {
  const docs = await Document.list(tx);
  for (const doc of docs) {
    const newPath = join([args.to, removePrefix(doc.path, args.from)]);

    if (newPath !== doc.path) {
      await Document.set(tx, { ...doc, path: newPath });
    }
  }
}
