import { naturalSort } from '@/lib/utils';
import { compact } from 'lodash';
import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { Commitment } from './commitment';
import { DrawingArea } from './drawing-area';
import { DrawingDiscipline } from './drawing-discipline';
import { DrawingSet } from './drawing-set';
import { ObjectPool } from './object-pool';
import { Organization } from './organization';
import { ProjectMembership } from './project-membership';
import { SubProject } from './sub-project';

export const projectStages = [
  'Bidding',
  'Course of Construction',
  'Post-Construction',
  'Pre-Construction',
  'Warranty',
  'Design',
] as const;

export type ProjectStage = (typeof projectStages)[number];

export class Project extends ApplicationRecord {
  id: string;
  organizationId: string;
  name: string;
  stage?: ProjectStage | null | undefined;
  number?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postalCode?: string | null;
  country?: string | null;
  coverPhotoUrl?: string | null | undefined;
  createdAt: string;
  updatedAt: string;
  infoUpdatedAt?: string | null;
  settingsUpdatedAt?: string | null;

  static prefix = 'projects' as const;

  static schema = z.object({
    id: z.string(),
    organizationId: z.string(),
    name: z.string(),
    stage: z.enum(projectStages).nullish(),
    number: z.string().nullish(),
    address1: z.string().nullish(),
    address2: z.string().nullish(),
    city: z.string().nullish(),
    state: z.string().nullish(),
    postalCode: z.string().nullish(),
    country: z.string().nullish(),
    coverPhotoUrl: z.string().nullish(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    infoUpdatedAt: z.string().datetime().nullish(),
    settingsUpdatedAt: z.string().datetime().nullish(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.name = this.attribute('name');
    this.stage = this.attribute('stage');
    this.number = this.attribute('number');
    this.organizationId = this.attribute('organizationId');
    this.address1 = this.attribute('address1');
    this.address2 = this.attribute('address2');
    this.city = this.attribute('city');
    this.state = this.attribute('state');
    this.postalCode = this.attribute('postalCode');
    this.country = this.attribute('country');
    this.coverPhotoUrl = this.attribute('coverPhotoUrl');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
    this.infoUpdatedAt = this.attribute('infoUpdatedAt');
    this.settingsUpdatedAt = this.attribute('settingsUpdatedAt');
  }

  get drawingSets() {
    return this.hasMany(DrawingSet, 'projectId');
  }

  get organization() {
    return this.belongsTo(Organization, this.organizationId);
  }

  get drawingAreas() {
    return this.hasMany(DrawingArea, 'projectId');
  }

  get hasMultipleDrawingAreas() {
    return this.drawingAreas.length > 1;
  }

  get drawingDisciplines() {
    return this.hasMany(DrawingDiscipline, 'projectId');
  }

  get commitments() {
    return this.hasMany(Commitment, 'projectId');
  }

  get fullName() {
    return compact([this.number, this.name]).join(' - ');
  }

  get hasNumber() {
    return !!this.number;
  }

  get projectMemberships() {
    return this.hasMany(ProjectMembership, 'projectId');
  }

  get subProjects() {
    return this.hasMany(SubProject, 'projectId').sort((a, b) =>
      naturalSort(a.name, b.name)
    );
  }

  get hasSubProjects() {
    return this.subProjects.length >= 1;
  }
}
