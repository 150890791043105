import { Project } from '@/models/project';
import { SubProject } from '@/models/sub-project';
import { WriteTransaction } from 'replicache';

export default async function editProjectSettings(
  tx: WriteTransaction,
  args: {
    id: string;
    organizationId: string;
    name: string;
    number?: string | null | undefined;
    coverPhotoUrl?: string | null;
    coverPhotoSignedId?: string | null;
    removeCoverPhoto?: boolean;
    settingsUpdatedAt: string;
    subProjects: { id: string; name: string }[];
  }
) {
  const project = await Project.mustGet(tx, args.id);
  await Project.set(tx, {
    ...project,
    name: args.name,
    number: args.number,
    coverPhotoUrl: args.coverPhotoUrl,
    settingsUpdatedAt: args.settingsUpdatedAt,
  });

  for (const sp of args.subProjects) {
    const found = await SubProject.get(tx, sp.id);

    if (found) {
      await SubProject.set(tx, {
        ...found,
        name: sp.name,
      });
    } else {
      await SubProject.set(tx, {
        id: sp.id,
        projectId: args.id,
        organizationId: args.organizationId,
        name: sp.name,
        createdAt: args.settingsUpdatedAt,
        updatedAt: args.settingsUpdatedAt,
      });
    }
  }
}
