import { CommentThread } from '@/models/comment-thread';
import { WriteTransaction } from 'replicache';

export async function resolveCommentThread(
  tx: WriteTransaction,
  {
    commentThreadId,
    resolvedAt,
    resolvedById,
    updatedAt,
  }: {
    commentThreadId: string;
    resolvedAt: string;
    resolvedById: string;
    updatedAt: string;
  }
) {
  const thread = await CommentThread.mustGet(tx, commentThreadId);
  await CommentThread.set(tx, {
    ...thread,
    resolvedAt,
    resolvedById,
    updatedAt,
  });
}
