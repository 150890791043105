import { SubmittalApproverDocument } from '@/models/submittal-approver-document';
import { WriteTransaction } from 'replicache';

export async function createSubmittalApproverDocument(
  tx: WriteTransaction,
  args: {
    id: string;
    title: string;
    createdAt: string;
    updatedAt: string;
  }
) {
  await SubmittalApproverDocument.set(tx, {
    id: args.id,
    title: args.title,
    createdAt: args.createdAt,
    updatedAt: args.updatedAt,
  });
}
