import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import InvoiceLineItem from './invoice-line-item';
import { ObjectPool } from './object-pool';
import { InvoiceSubmission } from './invoice-submission';

export class InvoiceSubmissionLineItem extends ApplicationRecord {
  id: string;
  amountCents: number;
  completedPercent: number;
  notes: string;
  retentionPercent: number;
  invoiceSubmissionId: string;
  invoiceLineItemId: string | null | undefined;
  projectId: string;
  sovLineItemId: string;
  createdAt: string;
  updatedAt: string;

  static prefix = 'invoiceSubmissionLineItems' as const;

  static schema = z.object({
    id: z.string(),
    amountCents: z.number(),
    completedPercent: z.number(),
    notes: z.string(),
    retentionPercent: z.number(),
    invoiceSubmissionId: z.string(),
    invoiceLineItemId: z.string().nullish(),
    projectId: z.string(),
    sovLineItemId: z.string(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.amountCents = this.attribute('amountCents');
    this.completedPercent = this.attribute('completedPercent');
    this.notes = this.attribute('notes');
    this.retentionPercent = this.attribute('retentionPercent');
    this.invoiceSubmissionId = this.attribute('invoiceSubmissionId');
    this.invoiceLineItemId = this.attribute('invoiceLineItemId');
    this.projectId = this.attribute('projectId');
    this.sovLineItemId = this.attribute('sovLineItemId');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
  }

  get invoiceSubmission() {
    return this.belongsTo(InvoiceSubmission, this.invoiceSubmissionId);
  }

  get subtotalCents() {
    return this.amountCents - this.retainageCents;
  }

  get retainageCents() {
    return this.retentionPercent * this.amountCents;
  }

  get invoiceLineItem() {
    return this.belongsToOptional(InvoiceLineItem, this.invoiceLineItemId);
  }
}
