import { ChangeOrderAttachment } from '@/models/change-order-attachment';
import { WriteTransaction } from 'replicache';

type Args = {
  id: string;
  changeOrderId: string;
  projectId: string;
  createdAt: string;
};

export default async function (tx: WriteTransaction, args: Args) {
  await ChangeOrderAttachment.set(tx, {
    id: args.id,
    changeOrderId: args.changeOrderId,
    projectId: args.projectId,
    fileName: null,
    fileUrl: null,
    createdAt: args.createdAt,
    updatedAt: args.createdAt,
  });
}
