import { naturalSort } from '@/lib/utils';
import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { DrawingDiscipline } from './drawing-discipline';
import { DrawingSet } from './drawing-set';
import { MarkupShape } from './markup-shape';
import { ObjectPool } from './object-pool';
import { ProjectPreference } from './project-preference';
import { SheetNumberLink } from './sheet-number-link';

export const drawingSetPageStates = [
  'initialized',
  'extracting_metadata',
  'awaiting_confirmation',
  'awaiting_publish',
  'published',
] as const;

export type DrawingSetPageState = (typeof drawingSetPageStates)[number];

export class DrawingSetPage extends ApplicationRecord {
  id: string;
  pageNumber: number;
  state: DrawingSetPageState;
  createdAt: string;
  updatedAt: string;
  drawingSetId: string;
  organizationId: string;
  sheetNumber?: string | null | undefined;
  title?: string | null | undefined;
  sourceUrl?: string | null | undefined;
  sourceFileName?: string | null | undefined;
  pageUrl?: string | null | undefined;
  lowResImageUrl?: string | null | undefined;
  sheetDate?: string | null | undefined;
  drawingDisciplineId?: string | null | undefined;
  width?: number | null | undefined;
  height?: number | null | undefined;
  alignmentOffsetX?: number | null | undefined;
  alignmentOffsetY?: number | null | undefined;
  deletedAt?: string | null | undefined;
  pageMeasure?: number | null | undefined;
  realMeasure?: number | null | undefined;
  measureConversion?: string | null | undefined;
  measureShape?: string | null | undefined;
  roundToNearest?: string | null | undefined;

  static prefix = 'drawingSetPages' as const;

  static schema = z.object({
    id: z.string(),
    pageNumber: z.number().int(),
    sheetNumber: z.string().nullable(),
    title: z.string().nullable(),
    state: z.enum(drawingSetPageStates),
    sourceUrl: z.string().nullable(),
    sourceFileName: z.string().nullable(),
    pageUrl: z.string().nullable(),
    lowResImageUrl: z.string().nullish(),
    sheetDate: z.string().nullable(),
    organizationId: z.string(),
    drawingSetId: z.string(),
    drawingDisciplineId: z.string().nullable(),
    width: z.number().int().nullable(),
    height: z.number().int().nullable(),
    alignmentOffsetX: z.number().nullish(),
    alignmentOffsetY: z.number().nullish(),
    deletedAt: z.string().datetime().nullish(),
    pageMeasure: z.number().nullish(),
    realMeasure: z.number().nullish(),
    measureConversion: z.string().nullish(),
    measureShape: z.string().nullish(),
    roundToNearest: z.string().nullish(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.pageNumber = this.attribute('pageNumber');
    this.sheetNumber = this.attribute('sheetNumber');
    this.title = this.attribute('title');
    this.state = this.attribute('state', z.enum(drawingSetPageStates));
    this.sourceUrl = this.attribute('sourceUrl');
    this.sourceFileName = this.attribute('sourceFileName');
    this.pageUrl = this.attribute('pageUrl');
    this.lowResImageUrl = this.attribute('lowResImageUrl');
    this.sheetDate = this.attribute('sheetDate');
    this.organizationId = this.attribute('organizationId');
    this.drawingSetId = this.attribute('drawingSetId');
    this.drawingDisciplineId = this.attribute('drawingDisciplineId');
    this.width = this.attribute('width');
    this.height = this.attribute('height');
    this.alignmentOffsetX = this.attribute('alignmentOffsetX');
    this.alignmentOffsetY = this.attribute('alignmentOffsetY');
    this.deletedAt = this.attribute('deletedAt');
    this.pageMeasure = this.attribute('pageMeasure');
    this.realMeasure = this.attribute('realMeasure');
    this.measureConversion = this.attribute('measureConversion');
    this.measureShape = this.attribute('measureShape');
    this.roundToNearest = this.attribute('roundToNearest');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
  }

  static disciplineGroups({
    pages,
    projectPreference,
    groupStarred,
  }: {
    pages: DrawingSetPage[];
    projectPreference?: ProjectPreference | null | undefined;
    groupStarred?: boolean | null | undefined;
  }) {
    const disciplines = new Map<DrawingDiscipline | null, DrawingSetPage[]>();

    // group pages by discipline
    pages.forEach((page) => {
      const discipline = page.discipline;
      if (!disciplines.has(discipline)) {
        disciplines.set(discipline, []);
      }
      disciplines.get(discipline)!.push(page);
    });

    // sort pages within each discipline
    Array.from(disciplines.entries()).forEach(([, pages]) => {
      pages.sort((a, b) =>
        naturalSort(a.sheetNumber || '', b.sheetNumber || '')
      );
    });

    // sort disciplines by position
    let res: [
      DrawingDiscipline | null | { id: string; name: string },
      DrawingSetPage[],
    ][] = Array.from(disciplines.entries()).toSorted(
      (a, b) => (a[0]?.position || 999) - (b[0]?.position || 999)
    ); // uncategorized last

    if (projectPreference && groupStarred) {
      const starredPages = pages
        .filter((x) =>
          projectPreference.starredSheetNumbers?.includes(x.sheetNumber)
        )
        .sort((a, b) => naturalSort(a.sheetNumber || '', b.sheetNumber || ''));
      res =
        starredPages.length > 0
          ? [
              [
                {
                  id: 'starred',
                  name: 'Starred Sheets',
                },
                starredPages,
              ],
              ...res,
            ]
          : res;
    }

    return res;
  }

  // A sorted set of pages first sorts by discipline, then within each discipline
  // pages are sorted by sheet number.
  static sortedPages(pages: DrawingSetPage[]) {
    const disciplineGroups = DrawingSetPage.disciplineGroups({ pages });
    return disciplineGroups.flatMap(([, pages]) => pages);
  }

  get drawingSet() {
    return this.belongsTo(DrawingSet, this.drawingSetId);
  }

  get drawingArea() {
    return this.drawingSet?.drawingArea;
  }

  get discipline() {
    return this.belongsToOptional(DrawingDiscipline, this.drawingDisciplineId);
  }

  get published() {
    return this.state === 'published';
  }

  get humanizedState() {
    if (this.published) {
      return 'Published';
    }

    return 'Unpublished';
  }

  get revisionNumber() {
    const revs =
      this.drawingSet.drawingArea.pageNumberRevisions[this.sheetNumber || ''];

    if (!revs) return 1;

    return revs.length - revs.findIndex((x) => x.id === this.id);
  }

  get markupShapes() {
    return this.hasMany(MarkupShape, 'drawingSetPageId');
  }

  get sheetNumberLinks() {
    return this.hasMany(SheetNumberLink, 'sourcePageId');
  }
}
