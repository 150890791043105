import { InvoiceSubmission } from '@/models/invoice-submission';
import { WriteTransaction } from 'replicache';

export default async function submitInvoice(
  tx: WriteTransaction,
  {
    id,
    submittedById,
    submittedAt,
  }: {
    id: string;
    submittedById: string;
    submittedAt: string;
  }
) {
  const invoiceSubmission = await InvoiceSubmission.mustGet(tx, id);
  await InvoiceSubmission.set(tx, {
    ...invoiceSubmission,
    status: 'under_review',
    submittedById,
    updatedAt: submittedAt,
  });
}
