import { DrawingSet, DrawingSetProcessingState } from '@/models/drawing-set';
import { WriteTransaction } from 'replicache';

export const createDrawingSet = async (
  tx: WriteTransaction,
  {
    id,
    name,
    revisionNumber,
    description,
    receivedOn,
    defaultSheetDate,
    createdAt,
    updatedAt,
    drawingAreaId,
    processingState,
  }: {
    id: string;
    name: string;
    revisionNumber?: string | null | undefined;
    description?: string | null | undefined;
    receivedOn?: string | null | undefined;
    defaultSheetDate?: string | null | undefined;
    createdAt: string;
    updatedAt: string;
    drawingAreaId: string;
    processingState: DrawingSetProcessingState;
    signedIds: string[];
  }
) => {
  await DrawingSet.set(tx, {
    id,
    name,
    revisionNumber,
    description,
    receivedOn,
    defaultSheetDate,
    createdAt,
    updatedAt,
    drawingAreaId,
    processingState,
  });
};
