import { ReadonlyJSONObject } from '@rocicorp/rails';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { DrawingArea } from './drawing-area';
import { DrawingSetPage } from './drawing-set-page';
import { ObjectPool } from './object-pool';

export const processingStates = [
  'initialized',
  'processing',
  'finished',
  'error',
] as const;

export type DrawingSetProcessingState = (typeof processingStates)[number];

export enum WorkflowStage {
  Processing,
  NeedsReview,
  Unpublished,
  Published,
}

export type DrawingSetStatus = {
  workflowStage: WorkflowStage;
  incompletePages: number;
  totalPages: number;
};

export class DrawingSet extends ApplicationRecord {
  id: string;
  name: string;
  receivedOn: string | null | undefined;
  defaultSheetDate: string | null | undefined;
  processingState: DrawingSetProcessingState;
  revisionNumber: string | null | undefined;
  description: string | null | undefined;
  drawingAreaId: string;
  thumbnailUrl: string | null | undefined;
  createdAt: string;
  updatedAt: string;

  static prefix = 'drawingSets' as const;

  static schema = z.object({
    id: z.string(),
    name: z.string(),
    receivedOn: z.string().date().nullish(),
    defaultSheetDate: z.string().date().nullish(),
    processingState: z.enum(processingStates),
    revisionNumber: z.string().max(8).nullish(),
    description: z.string().max(1024).nullish(),
    drawingAreaId: z.string(),
    thumbnailUrl: z.string().nullish(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.name = this.attribute('name');
    this.receivedOn = this.attribute('receivedOn');
    this.defaultSheetDate = this.attribute('defaultSheetDate');
    this.processingState = this.attribute('processingState');
    this.revisionNumber = this.attribute('revisionNumber');
    this.description = this.attribute('description');
    this.drawingAreaId = this.attribute('drawingAreaId');
    this.thumbnailUrl = this.attribute('thumbnailUrl');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
  }

  get drawingArea() {
    return this.belongsTo(DrawingArea, this.drawingAreaId);
  }

  get drawingSetPages() {
    return this.hasMany(DrawingSetPage, 'drawingSetId').filter(
      (x) => !x.deletedAt
    );
  }

  get uploadStatus() {
    let processing = 0;
    let needsReview = 0;
    let unpublished = 0;
    let published = 0;

    this.drawingSetPages.forEach((page) => {
      const stage = pageWorkflowStage(page);
      if (stage === WorkflowStage.Processing) {
        processing += 1;
      } else if (stage === WorkflowStage.NeedsReview) {
        needsReview += 1;
      } else if (stage === WorkflowStage.Unpublished) {
        unpublished += 1;
      } else if (stage === WorkflowStage.Published) {
        published += 1;
      }
    });

    let workflowStage = WorkflowStage.Published;
    let incompletePages = 0;
    if (processing > 0) {
      workflowStage = WorkflowStage.Processing;
      incompletePages = processing;
    } else if (needsReview > 0) {
      workflowStage = WorkflowStage.NeedsReview;
      incompletePages = needsReview;
    } else if (unpublished > 0) {
      workflowStage = WorkflowStage.Unpublished;
      incompletePages = unpublished;
    }

    return {
      workflowStage: workflowStage,
      incompletePages: incompletePages,
      totalPages: this.drawingSetPages.length,
    };
  }
}

function pageWorkflowStage(drawingSetPage: DrawingSetPage): WorkflowStage {
  if (
    drawingSetPage.state === 'initialized' ||
    drawingSetPage.state === 'extracting_metadata'
  ) {
    return WorkflowStage.Processing;
  }

  if (drawingSetPage.state === 'awaiting_confirmation') {
    return WorkflowStage.NeedsReview;
  }

  if (drawingSetPage.state === 'awaiting_publish') {
    return WorkflowStage.Unpublished;
  }

  return WorkflowStage.Published;
}

export function workflowStageName(workflowStage: WorkflowStage) {
  switch (workflowStage) {
    case WorkflowStage.Processing:
      return 'Processing';
    case WorkflowStage.NeedsReview:
      return 'Needs Review';
    case WorkflowStage.Unpublished:
      return 'Unpublished';
    case WorkflowStage.Published:
      return 'Published';
  }
}
