import { Rfi } from '@/models/rfi';
import { RfiResponse } from '@/models/rfi-response';
import { WriteTransaction } from 'replicache';

export async function acceptRfiResponse(
  tx: WriteTransaction,
  args: {
    id: string;
    acceptedAt: string;
  }
) {
  const response = await RfiResponse.mustGet(tx, args.id);
  const rfi = await Rfi.mustGet(tx, response.rfiId);

  await RfiResponse.set(tx, { ...response, acceptedAt: args.acceptedAt });
  await Rfi.set(tx, { ...rfi, status: 'closed' });
}
