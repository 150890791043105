import CommitmentAttachment from '@/models/commitment-attachment';
import { WriteTransaction } from 'replicache';

type Args = {
  id: string;
  commitmentId: string;
  projectId: string;
  createdAt: string;
};

export default async function (tx: WriteTransaction, args: Args) {
  await CommitmentAttachment.set(tx, {
    id: args.id,
    commitmentId: args.commitmentId,
    projectId: args.projectId,
    fileName: null,
    fileUrl: null,
    createdAt: args.createdAt,
    updatedAt: args.createdAt,
  });
}
