import { ChangeOrderStatus } from '@/models/change-order';
import { Commitment } from '@/models/commitment';
import { WriteTransaction } from 'replicache';

export default async function changeCommitmentStatus(
  tx: WriteTransaction,
  {
    id,
    newStatus,
    now,
  }: {
    id: string;
    newStatus: ChangeOrderStatus;
    now: string;
  }
) {
  const commitment = await Commitment.mustGet(tx, id);

  if (commitment.status === newStatus) return;
  if (commitment.status === 'executed') return;

  await Commitment.set(tx, {
    ...commitment,
    status: newStatus,
    updatedAt: now,
  });
}
