import { ReadonlyJSONObject } from '@rocicorp/rails';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ObjectPool } from './object-pool';
import { DrawingDisciplineAbbreviation } from './drawing-discipline-abbreviation';

export class DrawingDiscipline extends ApplicationRecord {
  id: string;
  projectId: string;
  organizationId: string;
  name: string;
  position: number;
  createdAt: string;
  updatedAt: string;

  static prefix = 'drawingDisciplines' as const;

  static schema = z.object({
    id: z.string(),
    projectId: z.string(),
    organizationId: z.string(),
    name: z.string().max(80),
    position: z.number(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.projectId = this.attribute('projectId');
    this.organizationId = this.attribute('organizationId');
    this.name = this.attribute('name');
    this.position = this.attribute('position');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
  }

  get abbreviations() {
    return this.hasMany(DrawingDisciplineAbbreviation, 'drawingDisciplineId');
  }
}
