import { Commitment } from '@/models/commitment';
import { WriteTransaction } from 'replicache';

export default async function createCommitment(
  tx: WriteTransaction,
  {
    id,
    projectId,
    companyId,
    createdAt,
    updatedAt,
  }: {
    id: string;
    projectId: string;
    companyId: string;
    createdAt: string;
    updatedAt: string;
  }
) {
  await Commitment.set(tx, {
    id,
    projectId,
    companyId,
    createdAt,
    updatedAt,
    status: 'draft',
  });
}
