import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import Account from './account';
import { ApplicationRecord } from './application-record';
import JournalCommit from './journal-commit';
import { ObjectPool } from './object-pool';

export default class JournalEntry extends ApplicationRecord {
  static prefix = 'journalEntries' as const;

  id: string;
  creditCents: number;
  debitCents: number;
  accountId: string;
  journalCommitId: string;
  organizationId: string;
  createdAt: string;

  static schema = z.object({
    id: z.string(),
    createdAt: z.string().datetime(),
    organizationId: z.string(),
    creditCents: z.number(),
    debitCents: z.number(),
    accountId: z.string(),
    journalCommitId: z.string(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.createdAt = this.attribute('createdAt');
    this.organizationId = this.attribute('organizationId');
    this.creditCents = this.attribute('creditCents');
    this.debitCents = this.attribute('debitCents');
    this.accountId = this.attribute('accountId');
    this.journalCommitId = this.attribute('journalCommitId');
  }

  get account() {
    return this.belongsTo(Account, this.accountId);
  }

  get journalCommit() {
    return this.belongsTo(JournalCommit, this.journalCommitId);
  }
}
