import { Company } from '@/models/company';
import {
  CompanyMembership,
  CompanyMembershipRole,
} from '@/models/company-membership';
import { Organization } from '@/models/organization';
import {
  OrganizationMembership,
  OrganizationMembershipRole,
} from '@/models/organization-membership';
import { User } from '@/models/user';
import { WriteTransaction } from 'replicache';

export default async function addMembershipWithUser(
  tx: WriteTransaction,
  {
    userIdIfNew,
    membershipId,
    firstName,
    lastName,
    email,
    phoneNumber,
    role,
    companyOrOrganizationId,
    now,
    organizationId,
    sendEmailNotification,
  }: {
    userIdIfNew: string;
    membershipId: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber?: string | null | undefined;
    role: CompanyMembershipRole | OrganizationMembershipRole;
    companyOrOrganizationId: string;
    now: string;
    organizationId: string;
    sendEmailNotification: boolean;
  }
) {
  let user = (await User.list(tx)).find((user) => user.email === email);
  const company = (await Company.list(tx)).find(
    (company) => company.id === companyOrOrganizationId
  );
  const organization = (await Organization.list(tx)).find(
    (organization) => organization.id === companyOrOrganizationId
  );

  if (!user) {
    user = await User.set(tx, {
      id: userIdIfNew,
      email,
      firstName,
      lastName,
      phoneNumber,
      otpEnabled: false,
      verified: true,
      createdAt: now,
      updatedAt: now,
    });
  }

  if (company) {
    await CompanyMembership.set(tx, {
      id: membershipId,
      userId: user.id,
      companyId: company.id,
      organizationId: organizationId,
      role: role as CompanyMembershipRole,
      createdAt: now,
      updatedAt: now,
    });
  } else if (organization) {
    await OrganizationMembership.set(tx, {
      id: membershipId,
      userId: user.id,
      organizationId: organization.id,
      role: role as OrganizationMembershipRole,
      createdAt: now,
      updatedAt: now,
    });
  }
}
