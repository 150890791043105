import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ObjectPool } from './object-pool';
import { Organization } from './organization';
import { User } from './user';

export class OrganizationPreference extends ApplicationRecord {
  id: string;
  createdAt: string;
  updatedAt: string;
  organizationId: string;
  userId: string;
  recentProjectIdListJson: string;

  static prefix = 'organizationPreferences' as const;

  static schema = z.object({
    id: z.string(),
    recentProjectIdListJson: z.string(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    organizationId: z.string(),
    userId: z.string(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.recentProjectIdListJson = this.attribute('recentProjectIdListJson');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
    this.organizationId = this.attribute('organizationId');
    this.userId = this.attribute('userId');
  }

  get user() {
    return this.belongsTo(User, this.userId);
  }

  get organization() {
    return this.belongsTo(Organization, this.organizationId);
  }

  get recentProjectIds() {
    return JSON.parse(this.recentProjectIdListJson).slice(0, 10);
  }
}
