import { Capacitor } from '@capacitor/core';
import type { GenericDatabase } from './generic-database';

const SqlitePlugin = Capacitor.registerPlugin<any>('SQLite');

export class CapacitorDatabase implements GenericDatabase {
  private readonly dbName: string;

  constructor() {
    this.dbName = `constructable-db-plugin`;
  }

  async initialize() {
    console.log('Opening database', this.dbName);
    await SqlitePlugin.open({ database: this.dbName });
    console.log('Opened database', this.dbName);
  }

  async beginTransaction() {
    console.log('Starting transaction', this.dbName);
    await SqlitePlugin.beginTransaction({ database: this.dbName });
    console.log('Started transaction', this.dbName);

    return new Transaction(
      this.query.bind(this),
      this.commitTransaction.bind(this)
    );
  }

  async createTable(name: string) {
    console.log('Creating table', name);
    await SqlitePlugin.execute({
      database: this.dbName,
      sql: `CREATE TABLE IF NOT EXISTS "${name}" (key TEXT PRIMARY KEY, value TEXT);`,
    });
    console.log('Created table', name);
  }

  async dropTable(name: string) {
    console.log('Dropping table', name);
    await SqlitePlugin.execute({
      database: this.dbName,
      sql: `DROP TABLE IF EXISTS "${name}";`,
    });
    console.log('Dropped table', name);
  }

  private async commitTransaction() {
    console.log('Committing transaction', this.dbName);
    await SqlitePlugin.commitTransaction({ database: this.dbName });
    console.log('Committed transaction', this.dbName);
  }

  private async query(sql: string, args: any[] = []): Promise<any[]> {
    if (import.meta.env.VITE_DEBUG_SQLITE) {
      console.log('Querying', sql, args);
    }

    const { values } = await SqlitePlugin.execute({
      database: this.dbName,
      sql,
      values: args,
    });

    return values ?? [];
  }

  async close() {
    console.log('Closing database', this.dbName);
    await SqlitePlugin.close({ database: this.dbName });
    console.log('Closed database', this.dbName);
  }
}

export class Transaction {
  constructor(
    private readonly queryImpl: (sql: string, args: any[]) => Promise<any[]>,
    private readonly commitImpl: () => Promise<void>
  ) {}

  async query(sql: string, args: any[] = []) {
    return this.queryImpl(sql, args);
  }

  async commit() {
    await this.commitImpl();
  }
}
