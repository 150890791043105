import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ObjectPool } from './object-pool';

export class MyUser extends ApplicationRecord {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  otpEnabled: boolean;
  verified: boolean;
  createdAt: string;
  updatedAt: string;
  avatarUrl?: string | null | undefined;
  phoneNumber?: string | null | undefined;

  static prefix = 'myUsers' as const;

  static schema = z.object({
    id: z.string(),
    email: z.string().email(),
    firstName: z.string(),
    lastName: z.string(),
    otpEnabled: z.boolean(),
    verified: z.boolean(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    avatarUrl: z.string().nullish(),
    phoneNumber: z.string().nullish(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.email = this.attribute('email');
    this.firstName = this.attribute('firstName');
    this.lastName = this.attribute('lastName');
    this.otpEnabled = this.attribute('otpEnabled');
    this.verified = this.attribute('verified');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
    this.avatarUrl = this.attribute('avatarUrl');
    this.phoneNumber = this.attribute('phoneNumber');
  }

  get isSuperUser() {
    return this.email.endsWith('@constructable.ai');
  }

  get name() {
    return `${this.firstName} ${this.lastName}`;
  }
}
