import {
  Comment,
  MediaAttachment,
  ScreenRecordingInfo,
} from '@/models/comment';
import { WriteTransaction } from 'replicache';

export async function addCommentToThread(
  tx: WriteTransaction,
  {
    commentId,
    commentThreadId,
    commentContent,
    commenterId,
    mediaAttachments,
    screenRecordingInfo,
    commentCreatedAt,
  }: {
    commentId: string;
    commentThreadId: string;
    commentContent: string;
    commenterId: string;
    mediaAttachments: Array<MediaAttachment>;
    screenRecordingInfo: ScreenRecordingInfo | null;
    commentCreatedAt: string;
    signedMediaAttachmentFileIds: Array<string>;
    signedScreenRecordingId: string | null;
  }
) {
  await Comment.set(tx, {
    id: commentId,
    content: commentContent,
    commentThreadId,
    commenterId,
    mediaAttachments,
    screenRecordingInfo,
    createdAt: commentCreatedAt,
    updatedAt: commentCreatedAt,
  });
}
