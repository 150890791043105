import { uuidv7 } from 'uuidv7';
import invariant from '../invariant';
import type { GenericDatabase } from './generic-database';
import { MessageHighway } from './message-highway';
import type { InputMessage, OutputMessage } from './sqlite-worker';

export class WasmSqliteDatabase implements GenericDatabase {
  private messageHighway: MessageHighway<InputMessage, OutputMessage>;

  constructor(
    private readonly clientId: string,
    workerOrPort: Worker | MessagePort
  ) {
    console.log('WasmSqliteDatabase constructor', clientId);
    this.messageHighway = new MessageHighway<InputMessage, OutputMessage>(
      workerOrPort
    );
  }

  async beginTransaction() {
    const tx = new Transaction(
      this.query.bind(this),
      this.commitTransaction.bind(this)
    );

    await this.messageHighway.postMessage({
      type: 'startTransaction',
      clientId: this.clientId,
      messageId: uuidv7(),
    });

    return tx;
  }

  async createTable(name: string) {
    await this.query(
      `create table if not exists "${name}" (key text primary key, value text)`
    );
  }

  async dropTable(name: string) {
    await this.query(`drop table if exists "${name}"`);
  }

  private async commitTransaction() {
    await this.messageHighway.postMessage({
      type: 'commitTransaction',
      clientId: this.clientId,
      messageId: uuidv7(),
    });
  }

  private async query(sql: string, args: any[] = []) {
    if (import.meta.env.VITE_DEBUG_SQLITE) {
      console.log(sql, args);
    }

    const data = await this.messageHighway.postMessage({
      type: 'query',
      sql,
      args,
      clientId: this.clientId,
      messageId: uuidv7(),
    });
    invariant(
      data.type === 'query',
      'Expected query response from query message'
    );

    return data.rows;
  }
}

export class Transaction {
  constructor(
    private readonly queryImpl: (sql: string, args: string[]) => Promise<any[]>,
    private readonly commitImpl: () => Promise<void>
  ) {}

  async query(sql: string, args: string[]) {
    return this.queryImpl(sql, args);
  }

  async commit() {
    await this.commitImpl();
  }
}
