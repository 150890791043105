import { compact } from 'lodash';
import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ObjectPool } from './object-pool';

export class CostCode extends ApplicationRecord {
  id: string;
  costCodeGroupId: string;
  code1: string;
  code2?: string | null | undefined;
  code3?: string | null | undefined;
  title: string;
  createdAt: string;
  updatedAt: string;
  static prefix = 'costCodes' as const;

  static schema = z.object({
    id: z.string(),
    costCodeGroupId: z.string(),
    code1: z.string(),
    code2: z.string().nullish(),
    code3: z.string().nullish(),
    title: z.string(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.costCodeGroupId = this.attribute('costCodeGroupId');
    this.code1 = this.attribute('code1');
    this.code2 = this.attribute('code2');
    this.code3 = this.attribute('code3');
    this.title = this.attribute('title');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
  }

  get fullCode() {
    return compact([this.code1, this.code2, this.code3]).join('.');
  }

  get fullCodeWithTitle() {
    return `${this.fullCode}: ${this.title}`;
  }
}
