import { uniqBy } from 'lodash';
import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ActivityLog } from './activity-log';
import { ApplicationRecord } from './application-record';
import { MarkupShape } from './markup-shape';
import { ObjectPool } from './object-pool';
import { Topic } from './topic';
import { User } from './user';

export type PunchListItemStatus = 'open' | 'in_review' | 'resolved';

export const punchListItemStatuses = ['open', 'in_review', 'resolved'] as const;

export type PunchItemLogTransition =
  | 'punch_list_item_open'
  | 'punch_list_item_in_review'
  | 'punch_list_item_resolved';

export const punchItemLogTransitions = {
  open: 'punch_list_item_open',
  in_review: 'punch_list_item_in_review',
  resolved: 'punch_list_item_resolved',
};

export class PunchListItem extends ApplicationRecord {
  id: string;
  title: string;
  status: PunchListItemStatus;
  topicId: string;
  creatorUserId: string;
  approverUserId: string;
  createdAt: string;
  updatedAt: string;
  assigneeUserId?: string | null | undefined;
  dueOn?: string | null | undefined;
  description?: string | null | undefined;
  resolvedAt?: string | null | undefined;

  static prefix = 'punchListItems' as const;
  static schema = z.object({
    id: z.string(),
    title: z.string(),
    description: z.string().nullish(),
    status: z.enum(punchListItemStatuses),
    topicId: z.string(),
    creatorUserId: z.string(),
    approverUserId: z.string(),
    assigneeUserId: z.string().nullish(),
    dueOn: z.string().date().nullish(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    resolvedAt: z.string().datetime().nullish(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.description = this.attribute('description');
    this.title = this.attribute('title');
    this.status = this.attribute('status');
    this.topicId = this.attribute('topicId');
    this.creatorUserId = this.attribute('creatorUserId');
    this.approverUserId = this.attribute('approverUserId');
    this.assigneeUserId = this.attribute('assigneeUserId');
    this.dueOn = this.attribute('dueOn');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
    this.resolvedAt = this.attribute('resolvedAt');
  }

  get creatorUser() {
    return this.belongsTo(User, this.creatorUserId);
  }

  get approverUser() {
    return this.belongsTo(User, this.approverUserId);
  }

  get assigneeUser() {
    return this.belongsToOptional(User, this.assigneeUserId);
  }

  get topic() {
    return this.belongsTo(Topic, this.topicId);
  }

  get activityLogs() {
    return this.hasMany(ActivityLog, 'punchListItemId');
  }

  get markupShapes() {
    return this.hasMany(MarkupShape, 'punchListItemId');
  }

  get drawingSetPages() {
    return uniqBy(
      this.markupShapes.map((shape) => shape.drawingSetPage),
      'id'
    );
  }
}
