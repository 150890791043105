import invariant from '@/lib/invariant';
import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { CompanyMembership } from './company-membership';
import { ObjectPool } from './object-pool';
import { OrganizationMembership } from './organization-membership';
import { Project } from './project';

export const projectMembershipRoles = [
  'tombstone',
  'subcontractor',
  'consultant',
  'owner',
  'manager',
  'admin',
] as const;

export type ProjectMembershipRole = (typeof projectMembershipRoles)[number];

const projectMembershipRoleDisplay = {
  tombstone: 'Tombstone',
  subcontractor: 'Subcontractor - External',
  consultant: 'Consultant - External',
  owner: 'Owner - External',
  manager: 'Manager - Internal',
  admin: 'Admin - Internal',
};

export class ProjectMembership extends ApplicationRecord {
  id: string;
  projectId: string;
  role: ProjectMembershipRole;
  createdAt: string;
  updatedAt: string;
  organizationMembershipId?: string | null | undefined;
  companyMembershipId?: string | null | undefined;

  static prefix = 'projectMemberships' as const;

  static schema = z.object({
    id: z.string(),
    projectId: z.string(),
    role: z.enum(projectMembershipRoles),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    organizationMembershipId: z.string().nullish(),
    companyMembershipId: z.string().nullish(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.projectId = this.attribute('projectId');
    this.organizationMembershipId = this.attribute('organizationMembershipId');
    this.companyMembershipId = this.attribute('companyMembershipId');
    this.role = this.attribute('role');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
  }

  get organizationMembership() {
    return this.belongsToOptional(
      OrganizationMembership,
      this.organizationMembershipId
    );
  }

  get companyMembership() {
    return this.belongsToOptional(CompanyMembership, this.companyMembershipId);
  }

  get typeOfCompanyOrOrganization() {
    return this.membership.companyOrOrganization.type;
  }

  get humanizedTypeOfCompanyOrOrganization() {
    return this.membership.companyOrOrganization.humanizedType;
  }

  get humanizedRole() {
    return projectMembershipRoleDisplay[this.role];
  }

  get project() {
    return this.belongsTo(Project, this.projectId);
  }

  get company() {
    if (this.companyMembership) {
      return this.companyMembership.company;
    } else {
      return null;
    }
  }

  get organization() {
    if (this.organizationMembership) {
      return this.organizationMembership.organization;
    } else {
      return null;
    }
  }

  get companyOrOrganization() {
    if (this.company) return this.company;
    if (this.organization) return this.organization;

    invariant(false, 'No company or organization for a project membership');
  }

  get membership() {
    if (this.organizationMembership) return this.organizationMembership;
    if (this.companyMembership) return this.companyMembership;

    invariant(false, 'No parent membership for a project membership');
  }

  get user() {
    invariant(this.membership.user, 'No user for a project membership');

    return this.membership.user;
  }

  // TODO: merge these, currently it causes an issue on the project team where tombstone'd
  // users are not shown. that's probably desired behavior but can't change it right now
  get active() {
    return this.membership.active;
  }

  get actuallyActive() {
    if (this.role === 'tombstone') return false;

    return this.membership.active;
  }
}
