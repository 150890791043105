import { PermissionSelectorAccessLevel } from '@/components/permissions-selector';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import { useOrganization } from '@/lib/organization-context';
import { useProject } from '@/lib/project-context';
import { documentsPath } from '@/lib/routes';
import { last, replace, trim } from 'lodash';
import { Link } from 'react-router';

import { UsersFoldersGrant } from '@/models/users-folders-grant';
import React from 'react';
import { z } from 'zod';

export function depth(path: string) {
  return path.split('/').length - 1;
}

export function join(paths: string[] | string) {
  const joined = replace(
    `/${[paths]
      .flat()
      .join('/')
      .split('/')
      .map((x) => trim(x))
      .join('/')}`,
    /\/+/g,
    '/'
  );

  return joined !== '/' && joined.endsWith('/') ? joined.slice(0, -1) : joined;
}

export function dirname(path: string) {
  return join(path).split('/').slice(0, -1).join('/');
}

export function basename(path: string) {
  return last(join(path).split('/')) || '/';
}

export function isRoot(path: string) {
  return join(['/', path]) === '/';
}

export function removePrefix(str: string, sub: string) {
  if (str.startsWith(sub)) {
    return str.slice(sub.length);
  }
  return str;
}

export function removeSuffix(str: string, sub: string) {
  if (str.endsWith(sub)) {
    return str.slice(0, -sub.length);
  }
  return str;
}

export function startsWithDir(path: string, dir: string) {
  return path.startsWith(`${join(dir)}/`);
}

export const fileOrFolderSchema = z.object({
  title: z
    .string()
    .min(1, { message: 'Must be at least 1 character long' })
    .refine((val) => !val.includes('/'), {
      message: `Cannot contain the "/" character`,
    })
    .refine((val) => !val.includes('\\'), {
      message: `Cannot contain the "\\" character`,
    })
    .refine((val) => !val.includes(':'), {
      message: `Cannot contain the ":" character`,
    })
    .refine((val) => !val.includes('*'), {
      message: `Cannot contain the "*" character`,
    })
    .refine((val) => !val.includes('?'), {
      message: `Cannot contain the "?" character`,
    })
    .refine((val) => !val.includes('#'), {
      message: `Cannot contain the "#" character`,
    })
    .refine((val) => !val.includes('^'), {
      message: `Cannot contain the "^" character`,
    })
    .refine((val) => !val.includes('%'), {
      message: `Cannot contain the "%" character`,
    }),
});

export const determineDefaultAccessLevel = ({
  folderOrganizationGrant,
  folderProjectGrant,
  parentProjectGrant,
}: {
  folderOrganizationGrant: boolean;
  folderProjectGrant: boolean;
  parentOrganizationGrant: boolean;
  parentProjectGrant: boolean;
}) => {
  if (parentProjectGrant) return 'entire_project';
  if (folderProjectGrant) return 'entire_project';
  if (folderOrganizationGrant) return 'entire_organization';
  return 'only_me';
};

export const initPermissionValue = ({
  folder,
  grants,
  parentProjectGrant,
  parentOrganizationGrant,
}: {
  folder: { organizationGrant: boolean; projectGrant: boolean };
  grants: UsersFoldersGrant[];
  parentProjectGrant: boolean;
  parentOrganizationGrant: boolean;
}) => {
  return {
    accessLevel: determineDefaultAccessLevel({
      folderOrganizationGrant: !!folder.organizationGrant,
      folderProjectGrant: !!folder.projectGrant,
      parentOrganizationGrant: !!parentOrganizationGrant,
      parentProjectGrant: !!parentProjectGrant,
    }) as PermissionSelectorAccessLevel,
    onlyMeUserIds: folder?.organizationGrant ? [] : grants.map((x) => x.userId),
    entireOrganizationUserIds: folder?.organizationGrant
      ? grants.map((x) => x.userId)
      : [],
  };
};

export const DocCrumbs = ({ path }: { path: string }) => {
  const organization = useOrganization();
  const project = useProject();
  const segments = join(path).split('/').slice(0, -1);
  const currentSegment = basename(path);

  return (
    <Breadcrumb>
      <BreadcrumbList>
        {segments.map((s, i) => (
          <React.Fragment key={i}>
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link
                  to={documentsPath(
                    organization.id,
                    project.id,
                    segments.slice(0, i + 1).join('/')
                  )}
                >
                  {isRoot(s) ? 'Documents' : s}
                </Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            {i < segments.length - 1 && <BreadcrumbSeparator />}
          </React.Fragment>
        ))}
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbPage>{currentSegment}</BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  );
};
