import { ProjectPreference } from '@/models/project-preference';
import { WriteTransaction } from 'replicache';

export default async function toggleSheetNumberStarred(
  tx: WriteTransaction,
  {
    projectPreferenceId,
    projectId,
    userId,
    organizationId,
    sheetNumber,
    now,
  }: {
    projectPreferenceId: string;
    projectId: string;
    userId: string;
    organizationId: string;
    sheetNumber: string;
    now: string;
  }
) {
  const projectPreference = await ProjectPreference.get(
    tx,
    projectPreferenceId
  );

  if (projectPreference) {
    const numbers = JSON.parse(projectPreference.starredSheetNumbersJson);
    let newNumbers = numbers;

    if (numbers.includes(sheetNumber)) {
      newNumbers = numbers.filter((x: string) => x !== sheetNumber);
    } else {
      newNumbers = [...numbers, sheetNumber];
    }

    await ProjectPreference.set(tx, {
      ...projectPreference,
      starredSheetNumbersJson: JSON.stringify(newNumbers),
      updatedAt: now,
    });
  } else {
    await ProjectPreference.set(tx, {
      id: projectPreferenceId,
      projectId,
      userId,
      organizationId,
      starredSheetNumbersJson: JSON.stringify([sheetNumber]),
      createdAt: now,
      updatedAt: now,
    });
  }
}
