import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ObjectPool } from './object-pool';

export class PendingAttachment extends ApplicationRecord {
  id: string;
  recordAttachmentName: string;
  fileName: string;
  state: string;
  recordType: string;
  recordId: string;
  updatedAt: string;

  static prefix = 'pendingAttachments' as const;

  static schema = z.object({
    id: z.string(),
    recordAttachmentName: z.string(),
    fileName: z.string(),
    state: z.string(),
    recordType: z.string(),
    recordId: z.string(),
    updatedAt: z.string().datetime(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.recordAttachmentName = this.attribute('recordAttachmentName');
    this.fileName = this.attribute('fileName');
    this.state = this.attribute('state');
    this.recordType = this.attribute('recordType');
    this.recordId = this.attribute('recordId');
    this.updatedAt = this.attribute('updatedAt');
  }
}
