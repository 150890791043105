import { orderBy, sortBy } from 'lodash';
import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { Comment } from './comment';
import { CommentThreadTag } from './comment-thread-tag';
import { Discussion } from './discussion';
import { MarkupShape } from './markup-shape';
import { ObjectPool } from './object-pool';
import { User } from './user';

export class CommentThread extends ApplicationRecord {
  id: string;
  discussionId: string;
  resolvedAt?: string | null;
  resolvedById?: string | null;
  createdAt: string;
  updatedAt: string;

  static prefix = 'commentThreads' as const;

  static schema = z.object({
    id: z.string(),
    discussionId: z.string(),
    resolvedAt: z.string().datetime({ offset: true }).nullish(),
    resolvedById: z.string().nullish(),
    createdAt: z.string().datetime({ offset: true }),
    updatedAt: z.string().datetime({ offset: true }),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.discussionId = this.attribute('discussionId');
    this.resolvedAt = this.attribute('resolvedAt');
    this.resolvedById = this.attribute('resolvedById');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
  }

  get resolvedBy() {
    return this.belongsToOptional(User, this.resolvedById);
  }

  get isResolved() {
    return !!this.resolvedAt;
  }

  get discussion() {
    return this.belongsTo(Discussion, this.discussionId);
  }

  get drawingSetPage() {
    return this.latestCommentShape?.drawingSetPage;
  }

  get ogComment() {
    return this.comments[0];
  }

  get comments() {
    return sortBy(this.hasMany(Comment, 'commentThreadId'), 'createdAt');
  }

  get tags() {
    return sortBy(this.hasMany(CommentThreadTag, 'commentThreadId'), 'name');
  }

  get topic() {
    return this.latestCommentShape?.topic;
  }

  get latestCommentShape() {
    return this.commentShapes[0];
  }

  get commentShapes() {
    if (this.discussion.discussableType !== 'MarkupShape') return [];

    return orderBy(
      this.objectPool
        .all(MarkupShape)
        .filter((x) => x.type === 'comment' && x.commentThreadId === this.id),
      [
        (x) => x.drawingSetPage.drawingSet.receivedOn,
        (x) => x.drawingSetPage.drawingSet.createdAt,
      ],
      ['desc', 'desc']
    );
  }
}
