import CommitmentAttachment from '@/models/commitment-attachment';
import { WriteTransaction } from 'replicache';

type Args = {
  id: string;
};

export default async function (tx: WriteTransaction, args: Args) {
  await CommitmentAttachment.delete(tx, args.id);
}
