import { compact } from 'lodash';
import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { CompanyMembership } from './company-membership';
import { ObjectPool } from './object-pool';
import { OrganizationMembership } from './organization-membership';

export class User extends ApplicationRecord {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  otpEnabled: boolean;
  verified: boolean;
  createdAt: string;
  updatedAt: string;
  avatarUrl?: string | null | undefined;
  phoneNumber?: string | null | undefined;

  static prefix = 'users' as const;

  static schema = z.object({
    id: z.string(),
    email: z.string().email(),
    firstName: z.string(),
    lastName: z.string(),
    otpEnabled: z.boolean(),
    verified: z.boolean(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    avatarUrl: z.string().nullish(),
    phoneNumber: z.string().nullish(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.email = this.attribute('email');
    this.firstName = this.attribute('firstName');
    this.lastName = this.attribute('lastName');
    this.otpEnabled = this.attribute('otpEnabled');
    this.verified = this.attribute('verified');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
    this.avatarUrl = this.attribute('avatarUrl');
    this.phoneNumber = this.attribute('phoneNumber');
  }

  get isSuperUser() {
    return this.email.endsWith('@constructable.ai');
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  get name() {
    return this.fullName;
  }

  get organizationMemberships() {
    return this.hasMany(OrganizationMembership, 'userId');
  }

  get organizations() {
    return this.organizationMemberships.map(
      (membership) => membership.organization
    );
  }

  get companyMemberships() {
    return this.hasMany(CompanyMembership, 'userId');
  }

  get companies() {
    return this.companyMemberships.map((membership) => membership.company);
  }

  get isCompanyMember() {
    if (this.organizationMemberships.length > 0) return false;

    return this.companyMemberships.length > 0;
  }

  get companiesAndOrganizations() {
    return compact([...this.companies, ...this.organizations]);
  }

  get activeCompanyOrOrganizationMemberships() {
    return compact([
      ...this.companyMemberships,
      ...this.organizationMemberships,
    ]).filter((membership) => membership.active);
  }

  get memberOfString() {
    return this.companiesAndOrganizations.map((m) => m.name).join(' / ');
  }

  get asOption() {
    return {
      value: this.id,
      label: this.fullName,
    };
  }
}
