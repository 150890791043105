import { Comment } from '@/models/comment';
import { WriteTransaction } from 'replicache';

export async function deleteComment(
  tx: WriteTransaction,
  {
    id,
    deletedAt,
    updatedAt,
  }: {
    id: string;
    deletedAt: string;
    updatedAt: string;
  }
) {
  const comment = await Comment.mustGet(tx, id);
  await Comment.set(tx, {
    ...comment,
    deletedAt,
    updatedAt,
  });
}
