import { Topic, TopicStatus } from '@/models/topic';
import { WriteTransaction } from 'replicache';

export async function updateIssue(
  tx: WriteTransaction,
  args: {
    id: string;
    title: string;
    status: TopicStatus;
    companyAccessible: boolean;
    organizationAccessible: boolean;
    directAccessUserIds: string[];
    updatedAt: string;
  }
) {
  const topic = await Topic.mustGet(tx, args.id);
  await Topic.set(tx, {
    ...topic,
    title: args.title,
    status: args.status,
    updatedAt: args.updatedAt,
    companyAccessible: args.companyAccessible,
    organizationAccessible: args.organizationAccessible,
  });
}
