import { compareAsc, formatISO, isSameDay, parseISO } from 'date-fns';
import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ActivityLog } from './activity-log';
import { ApplicationRecord } from './application-record';
import { DailyLogEquipmentEntry } from './daily-log-equipment-entry';
import { DailyLogPersonnelEntry } from './daily-log-personnel-entry';
import { Discussion } from './discussion';
import { HourlyWeatherLog } from './hourly-weather-log';
import { ObjectPool } from './object-pool';

export class DailyLog extends ApplicationRecord {
  id: string;
  date: string;
  notes: string;
  weatherNotes: string;
  createdAt: string;
  updatedAt: string;
  photoAlbumId?: string | null | undefined;

  static prefix = 'dailyLogs' as const;

  static schema = z.object({
    id: z.string(),
    date: z.string().date(),
    notes: z.string(),
    weatherNotes: z.string(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    photoAlbumId: z.string().nullish(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.date = this.attribute('date');
    this.notes = this.attribute('notes');
    this.weatherNotes = this.attribute('weatherNotes');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
    this.photoAlbumId = this.attribute('photoAlbumId');
  }

  static todaysLog(objectPool: ObjectPool) {
    const todayString = formatISO(new Date(), { representation: 'date' });
    return objectPool.all(DailyLog).find((log) => {
      return log.date === todayString;
    });
  }

  static allDailyLogsAscending(objectPool: ObjectPool) {
    return objectPool.all(DailyLog).sort((a, b) => {
      return compareAsc(a.parsedDate, b.parsedDate);
    });
  }

  get previousDailyLog(): DailyLog | null | undefined {
    const sortedLogs = DailyLog.allDailyLogsAscending(this.objectPool);
    const i = sortedLogs.findIndex((log) => log.id === this.id);

    if (i < 1) return null;
    return sortedLogs[i - 1];
  }

  get personnelEntries() {
    return this.hasMany(DailyLogPersonnelEntry, 'dailyLogId');
  }

  get equipmentEntries() {
    return this.hasMany(DailyLogEquipmentEntry, 'dailyLogId');
  }

  get discussion() {
    return this.hasOne(Discussion, 'discussableId');
  }

  get photos() {
    return this.discussion?.photos ?? [];
  }

  get previousLog(): DailyLog | null {
    const sortedLogs = this.objectPool
      .all(DailyLog)
      .sort((a, b) => compareAsc(a.date, b.date));
    const myIndex = sortedLogs.findIndex((log) => log.id === this.id);
    if (myIndex < 1) return null;
    return sortedLogs[myIndex - 1]!;
  }

  get hasPreviousLog() {
    return !!this.previousLog;
  }

  get hasPreviousEquipmentEntries() {
    return this.previousLog && this.previousLog.equipmentEntries.length > 0;
  }

  get hasPreviousPersonnelEntries() {
    return this.previousLog && this.previousLog.personnelEntries.length > 0;
  }

  get nextLog(): DailyLog | null {
    const sortedLogs = this.objectPool
      .all(DailyLog)
      .sort((a, b) => compareAsc(a.date, b.date));
    const myIndex = sortedLogs.findIndex((log) => log.id === this.id);

    if (myIndex === -1) return null;
    if (myIndex === sortedLogs.length - 1) return null;

    return sortedLogs[myIndex + 1]!;
  }

  get activityLogs() {
    return this.hasMany(ActivityLog, 'redirectId');
  }

  get parsedDate() {
    return parseISO(this.date);
  }

  get weatherLogs() {
    return this.objectPool.all(HourlyWeatherLog).filter((log) => {
      return isSameDay(log.parsedTimestamp, this.parsedDate);
    });
  }

  get workDayWeatherLogs() {
    return this.weatherLogs.filter((log) => {
      return (
        log.parsedTimestamp.getHours() >= 6 &&
        log.parsedTimestamp.getHours() <= 18
      );
    });
  }
}
