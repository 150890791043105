import { ActivityLog, ActivityLogType } from '@/models/activity-log';
import { WriteTransaction } from 'replicache';

export async function logActivity(
  tx: WriteTransaction,
  args: {
    id: string;
    userId: string;
    type: ActivityLogType;
    createdAt: string;
    projectId: string;
    message?: string | null | undefined;
    commentId?: string | null | undefined;
    commentThreadId?: string | null | undefined;
    companyId?: string | null | undefined;
    issueId?: string | null | undefined;
    markupSessionId?: string | null | undefined;
    punchListItemId?: string | null | undefined;
    redirectId?: string | null | undefined;
    toUserId?: string | null | undefined;
  }
) {
  await ActivityLog.set(tx, args);
}
