import { DrawingSetPage } from '@/models/drawing-set-page';
import { WriteTransaction } from 'replicache';

export async function deleteDrawingSetPage(
  tx: WriteTransaction,
  args: {
    id: string;
    deletedAt: string;
  }
) {
  const page = await DrawingSetPage.mustGet(tx, args.id);
  await DrawingSetPage.set(tx, {
    ...page,
    deletedAt: args.deletedAt,
  });
}
