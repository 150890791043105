import { ApplicationRecord } from '@/models/application-record';
import { ObjectPool } from '@/models/object-pool';
import { User } from '@/models/user';
import { compact, trim } from 'lodash';
import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';

export const activityLogTypes = [
  'generic',
  'add_comment',
  'add_thread',
  'resolve_thread',
  'reopen_thread',
  'add_issue',
  'add_user_to_issue',
  'remove_user_from_issue',
  'markup_session',
  'punch_list_item_create',
  'punch_list_item_open',
  'punch_list_item_in_review',
  'punch_list_item_resolved',
] as const;

export type ActivityLogType = (typeof activityLogTypes)[number];

const typeToUserActionMessage = {
  add_comment: 'added a comment',
  add_thread: 'started a new comment thread',
  resolve_thread: 'resolved a comment thread',
  reopen_thread: 'reopened a comment thread',
  add_issue: 'added a new issue',
  add_user_to_issue: 'added a user to an issue',
  remove_user_from_issue: 'removed a user from an issue',
  markup_session: 'added markup',
  punch_list_item_create: 'created a new punch item',
  punch_list_item_open: 'moved a punch item to open',
  punch_list_item_in_review: 'moved a punch item to in review',
  punch_list_item_resolved: 'moved a punch item to resolved',
  generic: null,
} as const;

export class ActivityLog extends ApplicationRecord {
  id: string;
  userId: string;
  type: ActivityLogType;
  createdAt: string;
  projectId: string;
  message?: string | null | undefined;
  commentId?: string | null | undefined;
  commentThreadId?: string | null | undefined;
  companyId?: string | null | undefined;
  issueId?: string | null | undefined;
  markupSessionId?: string | null | undefined;
  punchListItemId?: string | null | undefined;
  redirectId?: string | null | undefined;
  toUserId?: string | null | undefined;

  static prefix = 'activityLogs' as const;

  static schema = z.object({
    id: z.string(),
    userId: z.string(),
    type: z.enum(activityLogTypes),
    createdAt: z.string(),
    projectId: z.string(),

    message: z.string().nullish(),
    commentId: z.string().nullish(),
    commentThreadId: z.string().nullish(),
    companyId: z.string().nullish(),
    issueId: z.string().nullish(),
    markupSessionId: z.string().nullish(),
    punchListItemId: z.string().nullish(),
    redirectId: z.string().nullish(),
    toUserId: z.string().nullish(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.userId = this.attribute('userId');
    this.type = this.attribute('type');
    this.createdAt = this.attribute('createdAt');
    this.projectId = this.attribute('projectId');
    this.message = this.attribute('message');
    this.commentId = this.attribute('commentId');
    this.commentThreadId = this.attribute('commentThreadId');
    this.companyId = this.attribute('companyId');
    this.issueId = this.attribute('issueId');
    this.markupSessionId = this.attribute('markupSessionId');
    this.punchListItemId = this.attribute('punchListItemId');
    this.redirectId = this.attribute('redirectId');
    this.toUserId = this.attribute('toUserId');
  }

  get user() {
    return this.belongsTo(User, this.userId);
  }

  get messageAsUserAction() {
    const msg = trim(this.message || '') || typeToUserActionMessage[this.type];
    if (!msg) return null;

    return msg.charAt(0).toLowerCase() + msg.slice(1);
  }

  get isCommentLog() {
    return this.type === 'add_comment' || this.type === 'add_thread';
  }

  get referenceIds() {
    return compact([
      this.commentId,
      this.commentThreadId,
      this.companyId,
      this.issueId,
      this.markupSessionId,
      this.punchListItemId,
      this.redirectId,
      this.toUserId,
      this.userId,
    ]);
  }
}
