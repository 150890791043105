import { WriteTransaction } from 'replicache';

type LineItem = {
  id: string | null | undefined;
  newId: string;
  newOrderId: string;
  costCodeId: string;
  description: string;
  commitmentId: string;
  amountCents: number;
  retentionRate: number;
  subProjectId?: string | null | undefined;
};

export async function updateChangeEventLineItems(
  tx: WriteTransaction,
  {
    changeEventId,
    projectId,
    organizationId,
    now,
    lineItems,
  }: {
    changeEventId: string;
    projectId: string;
    organizationId: string;
    now: string;
    lineItems: LineItem[];
  }
) {
  // This mutation is huge pain to write without the object pool and I'd expect
  // its going to change soon anyway so TODO...
  //
  //
  //
  // const changeEvent = await ChangeEvent.mustGet(tx, changeEventId);
  // const changeOrders = (await ChangeOrder.list(tx)).filter((x) => x.changeEventId === changeEventId);
  // const changeOrderIds = changeOrders.map((x) => x.id);
  // const changeOrderLineItems = (await ChangeOrderLineItem.list(tx)).filter(
  //   (x) => changeOrderIds.includes(x.changeOrderId)
  // );
  // //
  // // Remove records which no longer appear in the submission
  // //
  // const newLineItemIds = lineItems.map((x) => x.id);
  // const existingLineItemIds = changeOrderLineItems.map((x) => x.id);
  // for (const delId in existingLineItemIds.filter(
  //   (eid) => !newLineItemIds.includes(eid)
  // )) {
  //   await ChangeOrderLineItem.delete(tx, delId);
  // }
  // //
  // // Create or update existing records
  // //
  // const groups = Object.values(groupBy(lineItems, 'commitmentId'))
  // for (const lineItemGroup of groups) {
  //   const commitmentId = lineItemGroup[0]!.commitmentId
  //   const newOrderId =lineItemGroup[0]!.newOrderId
  //   let order = changeOrders.find((x) => x.commitmentId === commitmentId);
  //   if (!order) {
  //     const changeOrders = (await ChangeOrder.list(tx)).filter((x) => x.changeEventId === changeEventId);
  //     const commitments = (await Commitment.list(tx)).filter((x) => x.id === commitmentId);
  //     const companies = (await Company.list(tx)).filter((x) => commitments.map(x => x.companyId).includes(x.id));
  //     order = await ChangeOrder.set({
  //       id: newOrderId,
  //       number
  //       createdAt
  //       updatedAt
  //       changeEventId
  //       commitmentId
  //       organizationId
  //       projectId
  //     })
  //   }
  // }
}
