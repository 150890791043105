import {
  Comment,
  MediaAttachment,
  ScreenRecordingInfo,
} from '@/models/comment';
import { WriteTransaction } from 'replicache';

export async function editComment(
  tx: WriteTransaction,
  {
    id,
    content,
    editedAt,
    updatedAt,
    mediaAttachments,
    screenRecordingInfo,
  }: {
    id: string;
    content: string;
    editedAt: string;
    updatedAt: string;
    mediaAttachments: Array<MediaAttachment>;
    screenRecordingInfo: ScreenRecordingInfo | null;
    mediaAttachmentIds: Array<string>;
    signedMediaAttachmentFileIds: Array<string>;
    screenRecordingFileId: string | null;
    signedScreenRecordingId: string | null;
  }
) {
  const comment = await Comment.mustGet(tx, id);
  await Comment.set(tx, {
    ...comment,
    content,
    editedAt,
    updatedAt,
    mediaAttachments,
    screenRecordingInfo,
  });
}
