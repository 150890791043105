import { InvoiceSubmissionAttachment } from '@/models/invoice-submission-attachment';
import { WriteTransaction } from 'replicache';

type Args = {
  id: string;
  invoiceSubmissionId: string;
  projectId: string;
  createdAt: string;
};

export default async function (tx: WriteTransaction, args: Args) {
  await InvoiceSubmissionAttachment.set(tx, {
    id: args.id,
    invoiceSubmissionId: args.invoiceSubmissionId,
    projectId: args.projectId,
    fileName: null,
    fileUrl: null,
    createdAt: args.createdAt,
    updatedAt: args.createdAt,
  });
}
