import { ChangeEvent } from '@/models/change-event';
import { WriteTransaction } from 'replicache';

export async function createChangeEvent(
  tx: WriteTransaction,
  {
    id,
    number,
    title,
    now,
    projectId,
    organizationId,
  }: {
    id: string;
    number: number;
    title: string;
    now: string;
    projectId: string;
    organizationId: string;
  }
) {
  await ChangeEvent.set(tx, {
    id,
    number,
    title,
    projectId,
    organizationId,
    updatedAt: now,
    createdAt: now,
  });
}
