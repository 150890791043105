import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ObjectPool } from './object-pool';

export class Notification extends ApplicationRecord {
  id: string;
  type: string;
  url?: string | null;
  message: string;
  organizationName?: string | null;
  projectName?: string | null;
  topicName?: string | null;
  avatarFirstName?: string | null;
  avatarLastName?: string | null;
  avatarId?: string | null;
  readAt?: string | null;
  seenAt?: string | null;
  createdAt: string;
  updatedAt: string;

  static prefix = 'notifications' as const;

  static schema = z.object({
    id: z.string(),
    type: z.string(),
    url: z.string().nullish(),
    message: z.string(),
    organizationName: z.string().nullish(),
    projectName: z.string().nullish(),
    topicName: z.string().nullish(),
    avatarFirstName: z.string().nullish(),
    avatarLastName: z.string().nullish(),
    avatarId: z.string().nullish(),
    readAt: z.string().nullish(),
    seenAt: z.string().datetime().nullish(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.type = this.attribute('type');
    this.url = this.attribute('url');
    this.message = this.attribute('message');
    this.organizationName = this.attribute('organizationName');
    this.projectName = this.attribute('projectName');
    this.topicName = this.attribute('topicName');
    this.avatarFirstName = this.attribute('avatarFirstName');
    this.avatarLastName = this.attribute('avatarLastName');
    this.avatarId = this.attribute('avatarId');
    this.readAt = this.attribute('readAt');
    this.seenAt = this.attribute('seenAt');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
  }

  static unreadCount(objectPool: ObjectPool) {
    return objectPool.all(Notification).filter((x) => !x.readAt).length;
  }
}
