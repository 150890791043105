import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { CommentThread } from './comment-thread';
import { DrawingSetPage } from './drawing-set-page';
import { ObjectPool } from './object-pool';
import { Topic } from './topic';

export class MarkupShape extends ApplicationRecord {
  id: string;
  tldrawId: string;
  drawingSetPageId: string;
  issueId: string;
  props: string;
  rotation: number;
  x: number;
  y: number;
  type: string;
  deleted: boolean;
  lastModifiedOnCanvasId?: string | null;
  createdAt: string;
  updatedAt: string;

  static prefix = 'markupShapes' as const;

  static schema = z.object({
    id: z.string(),
    tldrawId: z.string(),
    drawingSetPageId: z.string(),
    issueId: z.string(),
    props: z.string(),
    rotation: z.number(),
    x: z.number(),
    y: z.number(),
    type: z.string(),
    deleted: z.boolean(),
    lastModifiedOnCanvasId: z.string().nullish(),
    createdAt: z.string().datetime({ offset: true }),
    updatedAt: z.string().datetime({ offset: true }),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.tldrawId = this.attribute('tldrawId');
    this.drawingSetPageId = this.attribute('drawingSetPageId');
    this.issueId = this.attribute('issueId');
    this.props = this.attribute('props');
    this.rotation = this.attribute('rotation');
    this.x = this.attribute('x');
    this.y = this.attribute('y');
    this.type = this.attribute('type');
    this.deleted = this.attribute('deleted');
    this.lastModifiedOnCanvasId = this.attribute('lastModifiedOnCanvasId');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
  }

  get topic() {
    return this.belongsToOptional(Topic, this.issueId);
  }

  get drawingSetPage() {
    return this.belongsTo(DrawingSetPage, this.drawingSetPageId);
  }

  get drawingSet() {
    return this.drawingSetPage.drawingSet;
  }

  get punchListItemId() {
    return JSON.parse(this.props)['punchListItemId'];
  }

  get commentThreadId() {
    return JSON.parse(this.props)['commentThreadId'] as string | undefined;
  }

  get commentThread() {
    return this.belongsToOptional(CommentThread, this.commentThreadId);
  }
}
