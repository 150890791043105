import { Rfi, RfiStatus } from '@/models/rfi';
import { RfiRespondent } from '@/models/rfi-respondent';
import { RfiSubscriber } from '@/models/rfi-subscriber';
import { Topic } from '@/models/topic';
import { max } from 'lodash';
import { WriteTransaction } from 'replicache';

export async function createRfi(
  tx: WriteTransaction,
  args: {
    id: string;
    dueOn?: string | null | undefined;
    openedAt: string;
    question: string;
    status: RfiStatus;
    subject: string;
    createdAt: string;
    updatedAt: string;
    managerUserId: string;
    subscribers: { id: string; userId: string }[];
    respondents: { id: string; userId: string }[];
    topicId: string;
  }
) {
  await Topic.set(tx, {
    id: args.topicId,
    title: args.subject,
    status: 'editing',
    type: 'rfi',
    companyAccessible: true,
    organizationAccessible: true,
    createdAt: args.createdAt,
    updatedAt: args.updatedAt,
  });

  const maxNumber = max((await Rfi.list(tx)).map((rfi) => rfi.number)) || 0;

  await Rfi.set(tx, {
    id: args.id,
    dueOn: args.dueOn,
    openedAt: args.openedAt,
    question: args.question,
    status: args.status,
    subject: args.subject,
    createdAt: args.createdAt,
    updatedAt: args.updatedAt,
    managerUserId: args.managerUserId,
    closedAt: null,
    number: maxNumber + 1,
    ballInCourtStatus: 'manager',
    topicId: args.topicId,
  });

  for (const { id, userId } of args.subscribers) {
    await RfiSubscriber.set(tx, {
      id,
      userId,
      rfiId: args.id,
      createdAt: args.createdAt,
      updatedAt: args.createdAt,
    });
  }

  for (const { id, userId } of args.respondents) {
    await RfiRespondent.set(tx, {
      id,
      userId,
      rfiId: args.id,
      createdAt: args.createdAt,
      updatedAt: args.createdAt,
    });
  }
}
