import { CompanyMembership } from '@/models/company-membership';
import { OrganizationMembership } from '@/models/organization-membership';
import { ProjectMembership } from '@/models/project-membership';
import { WriteTransaction } from 'replicache';
import { uuidv7 } from 'uuidv7';

export default async function addOrRemovePeopleToProjectTeam(
  tx: WriteTransaction,
  {
    projectId,
    companyOrOrganizationMembershipIdsToAdd,
    projectMembershipIdsToRemove,
    now,
  }: {
    projectId: string;
    companyOrOrganizationMembershipIdsToAdd: string[];
    projectMembershipIdsToRemove: string[];
    now: string;
  }
) {
  for (const id in projectMembershipIdsToRemove) {
    const membership = await ProjectMembership.get(tx, id);
    if (!membership) continue;

    await ProjectMembership.set(tx, {
      id: membership.id,
      projectId,
      role: 'tombstone',
      createdAt: now,
      updatedAt: now,
    });
  }

  for (const id in companyOrOrganizationMembershipIdsToAdd) {
    const companyMembership = await CompanyMembership.get(tx, id);
    const organizationMembership = await OrganizationMembership.get(tx, id);

    if (companyMembership) {
      await ProjectMembership.set(tx, {
        id: uuidv7(),
        projectId,
        companyMembershipId: companyMembership.id,
        role: companyMembership.role,
        createdAt: now,
        updatedAt: now,
      });
    } else if (organizationMembership) {
      await ProjectMembership.set(tx, {
        id: uuidv7(),
        projectId,
        organizationMembershipId: organizationMembership.id,
        role: organizationMembership.role,
        createdAt: now,
        updatedAt: now,
      });
    }
  }
}
