import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ObjectPool } from './object-pool';
import { parseISO } from 'date-fns';

export class HourlyWeatherLog extends ApplicationRecord {
  id: string;
  conditions: string;
  humidity: number;
  precipitationIntensity: number;
  precipitationProbability: number;
  precipitationType: number;
  temperature: number;
  temperatureApparent: number;
  timestamp: string;
  windDirection: number;
  windGust: number;
  windSpeed: number;

  static prefix = 'hourlyWeatherLogs' as const;

  static schema = z.object({
    id: z.string(),
    conditions: z.string(),
    humidity: z.coerce.number(),
    precipitationIntensity: z.coerce.number(),
    precipitationProbability: z.coerce.number(),
    precipitationType: z.coerce.number(),
    temperature: z.coerce.number(),
    temperatureApparent: z.coerce.number(),
    timestamp: z.string(),
    windDirection: z.coerce.number(),
    windGust: z.coerce.number(),
    windSpeed: z.coerce.number(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.conditions = this.attribute('conditions');
    this.humidity = this.attribute('humidity');
    this.precipitationIntensity = this.attribute('precipitationIntensity');
    this.precipitationProbability = this.attribute('precipitationProbability');
    this.precipitationType = this.attribute('precipitationType');
    this.temperature = this.attribute('temperature');
    this.temperatureApparent = this.attribute('temperatureApparent');
    this.timestamp = this.attribute('timestamp');
    this.windDirection = this.attribute('windDirection');
    this.windGust = this.attribute('windGust');
    this.windSpeed = this.attribute('windSpeed');
  }

  get parsedTimestamp() {
    return parseISO(this.timestamp);
  }
}
