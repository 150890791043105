import { ReadonlyJSONObject } from '@rocicorp/rails';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { DrawingSetPage } from './drawing-set-page';
import { ObjectPool } from './object-pool';
import { Organization } from './organization';

export class SheetNumberLink extends ApplicationRecord {
  id: string;
  organizationId: string;
  sourcePageId: string;
  sheetNumber: string;
  xMin: number;
  yMin: number;
  xMax: number;
  yMax: number;

  static prefix = 'sheetNumberLinks' as const;

  static schema = z.object({
    id: z.string(),
    organizationId: z.string(),
    sourcePageId: z.string(),
    sheetNumber: z.string(),
    xMin: z.coerce.number(),
    yMin: z.coerce.number(),
    xMax: z.coerce.number(),
    yMax: z.coerce.number(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.organizationId = this.attribute('organizationId');
    this.sourcePageId = this.attribute('sourcePageId');
    this.sheetNumber = this.attribute('sheetNumber');
    this.xMin = this.attribute('xMin');
    this.yMin = this.attribute('yMin');
    this.xMax = this.attribute('xMax');
    this.yMax = this.attribute('yMax');
  }

  get organization() {
    return this.belongsToOptional(Organization, this.organizationId);
  }

  get sourcePage() {
    return this.belongsToOptional(DrawingSetPage, this.sourcePageId);
  }
}
