import { join } from '@/lib/file-utils';
import { WriteTransaction } from 'replicache';
import { Document } from '../models/document';

export async function createDocument(
  tx: WriteTransaction,
  args: {
    id: string;
    uploaderId: string;
    path: string;
    mimeType: string;
    sizeBytes: number;
    modifiedAt: string;
    updatedAt: string;
    createdAt: string;
  }
) {
  await Document.set(tx, {
    id: args.id,
    uploaderId: args.uploaderId,
    path: join(args.path),
    mimeType: args.mimeType,
    sizeBytes: args.sizeBytes,
    modifiedAt: args.modifiedAt,
    updatedAt: args.updatedAt,
    createdAt: args.createdAt,
    deletedAt: null,
  });
}
