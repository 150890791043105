import { CommentThreadTag } from '@/models/comment-thread-tag';
import { WriteTransaction } from 'replicache';

export default async function tagCommentThread(
  tx: WriteTransaction,
  {
    commentThreadTagId,
    commentThreadId,
    name,
    taggedAt,
  }: {
    commentThreadTagId: string;
    commentThreadId: string;
    name: string;
    taggedAt: string;
  }
) {
  await CommentThreadTag.set(tx, {
    id: commentThreadTagId,
    name,
    commentThreadId,
    createdAt: taggedAt,
    updatedAt: taggedAt,
  });
}
