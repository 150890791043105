import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ChangeOrderLineItem } from './change-order-line-item';
import { Commitment } from './commitment';
import { CostCode } from './cost-code';
import { InvoiceSubmissionLineItem } from './invoice-submission-line-item';
import { ObjectPool } from './object-pool';
import { SubProject } from './sub-project';

export class SovLineItem extends ApplicationRecord {
  id: string;
  commitmentId: string;
  costCodeId: string;
  description: string;
  amountCents: number;
  amountCurrency: string;
  retentionRate: number | null | undefined;
  createdAt: string;
  updatedAt: string;
  changeOrderLineItemId?: string | null | undefined;
  subProjectId?: string | null | undefined;

  static prefix = 'sovLineItems' as const;

  static schema = z.object({
    id: z.string(),
    commitmentId: z.string(),
    costCodeId: z.string(),
    description: z.string(),
    amountCents: z.number(),
    amountCurrency: z.string(),
    retentionRate: z.number().nullish(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    changeOrderLineItemId: z.string().nullish(),
    subProjectId: z.string().nullish(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.commitmentId = this.attribute('commitmentId');
    this.costCodeId = this.attribute('costCodeId');
    this.description = this.attribute('description');
    this.amountCents = this.attribute('amountCents');
    this.amountCurrency = this.attribute('amountCurrency');
    this.retentionRate = this.attribute('retentionRate');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
    this.changeOrderLineItemId = this.attribute('changeOrderLineItemId');
    this.subProjectId = this.attribute('subProjectId');
  }

  get commitment() {
    return this.belongsTo(Commitment, this.commitmentId);
  }

  get costCode() {
    return this.belongsTo(CostCode, this.costCodeId);
  }

  get subProject() {
    return this.belongsToOptional(SubProject, this.subProjectId);
  }

  get changeOrderLineItem() {
    return this.belongsToOptional(
      ChangeOrderLineItem,
      this.changeOrderLineItemId
    );
  }

  get isFromChangeOrder() {
    return !!this.changeOrderLineItem;
  }

  get invoiceSubmissionLineItems() {
    return this.hasMany(InvoiceSubmissionLineItem, 'sovLineItemId');
  }

  lineItemForInvoiceSubmission(invoiceSubmissionId: string) {
    return this.invoiceSubmissionLineItems.find(
      (item) => item.invoiceSubmissionId === invoiceSubmissionId
    );
  }

  get invoicedToDateCents() {
    return this.invoiceSubmissionLineItems.reduce((acc, lineItem) => {
      const journalEntries =
        lineItem.invoiceLineItem?.journalCommit?.journalEntries ?? [];

      const invoicedCents = journalEntries.reduce((acc, entry) => {
        if (this.commitment.isPrime && entry.account.type === 'revenue') {
          return acc + entry.creditCents;
        }

        if (!this.commitment.isPrime && entry.account.type === 'expense') {
          return acc + entry.debitCents;
        }

        return acc;
      }, 0);

      return acc + invoicedCents;
    }, 0);
  }
}
