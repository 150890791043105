import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ReadonlyJSONObject } from 'replicache';
import { ObjectPool } from './object-pool';

export default class Invoice extends ApplicationRecord {
  static prefix = 'invoices' as const;

  id: string;
  description: string | null | undefined;
  projectId: string;
  createdAt: string;
  organizationId: string;

  static schema = z.object({
    id: z.string(),
    description: z.string().nullish(),
    projectId: z.string(),
    createdAt: z.string().datetime(),
    organizationId: z.string(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.description = this.attribute('description');
    this.projectId = this.attribute('projectId');
    this.createdAt = this.attribute('createdAt');
    this.organizationId = this.attribute('organizationId');
  }
}
