import { dirname, join } from '@/lib/file-utils';
import { WriteTransaction } from 'replicache';
import { Document } from '../models/document';

export async function renameDocument(
  tx: WriteTransaction,
  args: {
    id: string;
    newName: string;
  }
) {
  const doc = await Document.mustGet(tx, args.id);
  await Document.set(tx, {
    ...doc,
    path: join([dirname(doc.path), args.newName]),
  });
}
