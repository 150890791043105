import invariant from '@/lib/invariant';
import { compact } from '@/lib/query';
import { ReadonlyJSONObject } from '@rocicorp/rails';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { Commitment } from './commitment';
import { CompanyMembership } from './company-membership';
import { ObjectPool } from './object-pool';
import { Organization } from './organization';

export const companyTypes = ['subcontractor', 'consultant', 'owner'] as const;

export type CompanyType = (typeof companyTypes)[number];

export const companyTypesDisplay = {
  subcontractor: 'Subcontractor',
  consultant: 'Consultant',
  owner: 'Owner',
};

export class Company extends ApplicationRecord {
  id: string;
  organizationId: string;
  name: string;
  url?: string | null | undefined;
  type: CompanyType;
  createdAt: string;
  updatedAt: string;

  static prefix = 'companies' as const;

  static schema = z.object({
    id: z.string(),
    organizationId: z.string(),
    name: z.string(),
    url: z.string().nullish(),
    type: z.enum(companyTypes),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.organizationId = this.attribute('organizationId');
    this.name = this.attribute('name');
    this.url = this.attribute('url');
    this.type = this.attribute('type');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
  }

  get organization() {
    return this.belongsToOptional(Organization, this.organizationId);
  }

  get companyMemberships() {
    return this.hasMany(CompanyMembership, 'companyId');
  }

  get memberships() {
    return this.companyMemberships;
  }

  get users() {
    return compact(
      this.companyMemberships.map((membership) => membership.user)
    );
  }

  get commitments() {
    return this.hasMany(Commitment, 'companyId');
  }

  get humanizedType() {
    const res = companyTypesDisplay[this.type];
    invariant(res, 'Invalid company type');
    return res;
  }

  get faviconUrl() {
    if (!this.url) return null;

    try {
      return `https://www.google.com/s2/favicons?domain=${new URL(this.url).hostname}&sz=256`;
    } catch (e) {
      return null;
    }
  }

  get isOwner() {
    return this.type === 'owner';
  }
}
