import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { Document } from './document';
import { ObjectPool } from './object-pool';

export class DocumentPage extends ApplicationRecord {
  id: string;
  projectId: string;
  organizationId: string;
  documentId: string;
  pageNumber: number;
  thumbnailUrl?: string | null;
  fileUrl?: string | null;

  static prefix = 'documentPages' as const;

  static schema = z.object({
    id: z.string(),
    projectId: z.string(),
    organizationId: z.string(),
    documentId: z.string(),
    pageNumber: z.number(),
    thumbnailUrl: z.string(),
    fileUrl: z.string(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.projectId = this.attribute('projectId');
    this.organizationId = this.attribute('organizationId');
    this.documentId = this.attribute('documentId');
    this.pageNumber = this.attribute('pageNumber');
    this.thumbnailUrl = this.attribute('thumbnailUrl');
    this.fileUrl = this.attribute('fileUrl');
  }

  get document() {
    return this.belongsTo(Document, this.documentId);
  }
}
