import { DrawingSetPage } from '@/models/drawing-set-page';
import { WriteTransaction } from 'replicache';

export async function updateDrawingSetPage(
  tx: WriteTransaction,
  args: {
    id: string;
    sheetNumber: string | null;
    title: string | null;
    sheetDate: string | null;
    disciplineId: string | null;
    updatedAt: string;
  }
) {
  const page = await DrawingSetPage.mustGet(tx, args.id);
  await DrawingSetPage.set(tx, {
    ...page,
    ...args,
  });
}
