import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ChangeEvent } from './change-event';
import { ChangeOrderAttachment } from './change-order-attachment';
import { ChangeOrderLineItem } from './change-order-line-item';
import { Commitment } from './commitment';
import { ObjectPool } from './object-pool';

export const changeOrderStatuses = [
  'void',
  'draft',
  'signing',
  'countersigning',
  'executed',
] as const;

export type ChangeOrderStatus = (typeof changeOrderStatuses)[number];

export const changeOrderStatusLabels = {
  void: 'Void',
  draft: 'Draft',
  signing: 'Out for Signing',
  countersigning: 'Ready to Countersign',
  executed: 'Fully Executed',
} as const;

export const changeOrderStatusVariants = {
  void: 'destructiveOutline',
  draft: 'warningOutline',
  signing: 'warning',
  countersigning: 'primary',
  executed: 'success',
} as const;

export class ChangeOrder extends ApplicationRecord {
  id: string;
  number: number;
  createdAt: string;
  updatedAt: string;
  changeEventId: string;
  commitmentId: string;
  organizationId: string;
  projectId: string;
  status: ChangeOrderStatus;

  static prefix = 'changeOrders' as const;

  static schema = z.object({
    id: z.string(),
    number: z.number(),
    createdAt: z.string(),
    updatedAt: z.string(),
    changeEventId: z.string(),
    commitmentId: z.string(),
    organizationId: z.string(),
    projectId: z.string(),
    status: z.enum(changeOrderStatuses),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.number = this.attribute('number');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
    this.changeEventId = this.attribute('changeEventId');
    this.commitmentId = this.attribute('commitmentId');
    this.organizationId = this.attribute('organizationId');
    this.projectId = this.attribute('projectId');
    this.status = this.attribute('status');
  }

  get commitment() {
    return this.belongsTo(Commitment, this.commitmentId);
  }

  get changeEvent() {
    return this.belongsTo(ChangeEvent, this.changeEventId);
  }

  get changeOrderLineItems() {
    return this.hasMany(ChangeOrderLineItem, 'changeOrderId');
  }

  get attachments() {
    return this.hasMany(ChangeOrderAttachment, 'changeOrderId');
  }

  get isPrime() {
    return this.commitment.isPrime;
  }

  get isVendor() {
    return this.commitment.isVendor;
  }

  get isExecuted() {
    return this.status === 'executed';
  }

  get isVoidable() {
    return this.status !== 'void' && this.status !== 'executed';
  }

  get amountCents() {
    return this.changeOrderLineItems.reduce(
      (sum, li) => sum + li.amountCents,
      0
    );
  }
}
