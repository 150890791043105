import { Topic } from '@/models/topic';
import { TopicMembership } from '@/models/topic-membership';
import { WriteTransaction } from 'replicache';

export async function newEditorUpdateIssue(
  tx: WriteTransaction,
  args: {
    id: string;
    title: string;
    companyAccessible: boolean;
    organizationAccessible: boolean;
    updatedAt: string;
    memberships: { id: string; userId: string }[];
  }
) {
  const issue = await Topic.mustGet(tx, args.id);

  await Topic.set(tx, {
    id: args.id,
    title: args.title,
    companyAccessible: args.companyAccessible,
    organizationAccessible: args.organizationAccessible,
    createdAt: issue.createdAt,
    updatedAt: args.updatedAt,
    status: 'editing',
    type: 'topic',
  });

  const existingMemberships = (await TopicMembership.list(tx)).filter(
    (m) => m.issueId === args.id
  );

  for (const membership of args.memberships) {
    if (existingMemberships.some((m) => m.userId === membership.userId)) {
      continue;
    }

    await TopicMembership.set(tx, {
      id: membership.id,
      issueId: args.id,
      userId: membership.userId,
      createdAt: args.updatedAt,
      updatedAt: args.updatedAt,
    });
  }

  for (const membership of existingMemberships) {
    if (args.memberships.some((m) => m.userId === membership.userId)) {
      continue;
    }

    await TopicMembership.delete(tx, membership.id);
  }
}
