import { PendingAttachment } from '@/models/pending-attachment';
import { WriteTransaction } from 'replicache';

export async function failedPendingAttachment(
  tx: WriteTransaction,
  {
    id,
    updatedAt,
  }: {
    id: string;
    updatedAt: string;
  }
) {
  const attachment = await PendingAttachment.mustGet(tx, id);
  await PendingAttachment.set(tx, {
    ...attachment,
    state: 'initialized',
    updatedAt,
  });
}
