import { WriteTransaction } from 'replicache';
import { Document } from '../models/document';

export async function deleteDocument(
  tx: WriteTransaction,
  args: {
    id: string;
    deletedAt: string;
  }
) {
  const doc = await Document.mustGet(tx, args.id);
  await Document.set(tx, {
    ...doc,
    deletedAt: args.deletedAt,
  });
}
