import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ChangeOrder } from './change-order';
import { ObjectPool } from './object-pool';

export class ChangeEvent extends ApplicationRecord {
  id: string;
  description?: string | null | undefined;
  number: number;
  title: string;
  createdAt: string;
  updatedAt: string;
  organizationId: string;
  projectId: string;

  static prefix = 'changeEvents' as const;

  static schema = z.object({
    id: z.string(),
    description: z.string().nullish(),
    number: z.number(),
    title: z.string(),
    createdAt: z.string(),
    updatedAt: z.string(),
    organizationId: z.string(),
    projectId: z.string(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.description = this.attribute('description');
    this.number = this.attribute('number');
    this.title = this.attribute('title');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
    this.organizationId = this.attribute('organizationId');
    this.projectId = this.attribute('projectId');
  }

  get changeOrders() {
    return this.hasMany(ChangeOrder, 'changeEventId');
  }

  get changeOrderLineItems() {
    return this.changeOrders.flatMap((x) => x.changeOrderLineItems);
  }

  get primeChangeOrders() {
    return this.changeOrders.filter((x) => x.isPrime);
  }

  get vendorChangeOrders() {
    return this.changeOrders.filter((x) => x.isVendor);
  }

  get primeChangeOrderLineItems() {
    return this.changeOrderLineItems.filter((x) => x.commitment.isPrime);
  }

  get subChangeOrderLineItems() {
    return this.changeOrderLineItems.filter((x) => !x.commitment.isPrime);
  }
}
