import { MarkupSession } from '@/models/markup-session';
import { WriteTransaction } from 'replicache';

export async function putMarkupSession(
  tx: WriteTransaction,
  args: {
    id: string;
    shapesCreated: string[];
    shapesDeleted: string[];
    shapesUpdated: string[];
    userId: string;
    issueId: string;
    createdAt: string;
    updatedAt: string;
  }
) {
  await MarkupSession.set(tx, args);
}
