import { Capacitor } from '@capacitor/core';
import { uuidv7 } from 'uuidv7';
import { CapacitorDatabase } from './capacitor-database';
import { GenericDatabase } from './generic-database';
import { SqliteKvStoreProvider } from './kv-store';
import { WasmSqliteDatabase } from './wasm-sqlite-database';

export class SqliteSystem {
  static isEnabled = Capacitor.getPlatform() === 'ios';
  static isCapacitorAvailable = Capacitor.isPluginAvailable('SQLite');

  private static initializationPromise: Promise<SqliteSystem> | null = null;

  static async initializeSingleton() {
    if (this.initializationPromise) {
      return this.initializationPromise;
    }

    this.initializationPromise = (async () => {
      if (sqliteSystem) {
        return sqliteSystem;
      }

      if (this.isCapacitorAvailable) {
        const database = new CapacitorDatabase();
        await database.initialize();
        sqliteSystem = new SqliteSystem(database);
      } else {
        const { default: SqliteWorker } = await import(
          './sqlite-worker?worker'
        );
        if (sqliteSystem) {
          return sqliteSystem;
        }
        sqliteSystem = new SqliteSystem(
          new WasmSqliteDatabase(uuidv7(), new SqliteWorker())
        );
      }

      return sqliteSystem;
    })();

    return this.initializationPromise;
  }

  private _kvStoreProvider: SqliteKvStoreProvider;

  constructor(private database: GenericDatabase) {
    this._kvStoreProvider = new SqliteKvStoreProvider(this.database);
  }

  get kvStoreProvider() {
    return this._kvStoreProvider;
  }
}

let sqliteSystem: SqliteSystem;
