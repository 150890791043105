import { makeAutoObservable } from 'mobx';
import { ReadonlyJSONObject } from 'replicache';

import invariant from '@/lib/invariant';
import { createContext, useContext } from 'react';
import Account from './account';
import { ActivityLog } from './activity-log';
import { BillingPeriod } from './billing-period';
import { ChangeEvent } from './change-event';
import { ChangeOrder } from './change-order';
import { ChangeOrderAttachment } from './change-order-attachment';
import { ChangeOrderLineItem } from './change-order-line-item';
import { Comment } from './comment';
import { CommentThread } from './comment-thread';
import { CommentThreadTag } from './comment-thread-tag';
import { Commitment } from './commitment';
import CommitmentAttachment from './commitment-attachment';
import { Company } from './company';
import { CompanyMembership } from './company-membership';
import { CostCode } from './cost-code';
import { CostCodeGroup } from './cost-code-group';
import { DailyLog } from './daily-log';
import { DailyLogEquipmentEntry } from './daily-log-equipment-entry';
import { DailyLogPersonnelEntry } from './daily-log-personnel-entry';
import { Discussion } from './discussion';
import { Document } from './document';
import { DocumentPage } from './document-page';
import { DrawingArea } from './drawing-area';
import { DrawingDiscipline } from './drawing-discipline';
import { DrawingDisciplineAbbreviation } from './drawing-discipline-abbreviation';
import { DrawingSet } from './drawing-set';
import { DrawingSetPage } from './drawing-set-page';
import { Folder } from './folder';
import { HourlyWeatherLog } from './hourly-weather-log';
import Invoice from './invoice';
import InvoiceLineItem from './invoice-line-item';
import { InvoiceSubmission } from './invoice-submission';
import { InvoiceSubmissionAttachment } from './invoice-submission-attachment';
import { InvoiceSubmissionLineItem } from './invoice-submission-line-item';
import JournalCommit from './journal-commit';
import JournalEntry from './journal-entry';
import { MarkupSession } from './markup-session';
import { MarkupShape } from './markup-shape';
import { MyUser } from './my-user';
import { Notification } from './notification';
import { ObjectLabel } from './object-label';
import { Organization } from './organization';
import { OrganizationMembership } from './organization-membership';
import { OrganizationPreference } from './organization-preference';
import { PendingAttachment } from './pending-attachment';
import { Permission } from './permission';
import { Photo } from './photo';
import { PhotoAlbum } from './photo-album';
import { Project } from './project';
import { ProjectMembership } from './project-membership';
import { ProjectPreference } from './project-preference';
import { PunchListItem } from './punch-list-item';
import { Question } from './question';
import { Rfi } from './rfi';
import { RfiQuestionAttachment } from './rfi-question-attachment';
import { RfiRespondent } from './rfi-respondent';
import { RfiResponse } from './rfi-response';
import { RfiResponseAttachment } from './rfi-response-attachment';
import { RfiSubscriber } from './rfi-subscriber';
import { SheetNumberLink } from './sheet-number-link';
import { SovLineItem } from './sov-line-item';
import { SubProject } from './sub-project';
import { Submittal } from './submittal';
import { SubmittalApprover } from './submittal-approver';
import { SubmittalApproverDocument } from './submittal-approver-document';
import { SubmittalDocument } from './submittal-document';
import { SubmittalDocumentPage } from './submittal-document-page';
import { SubmittalGroup } from './submittal-group';
import { SubmittalItem } from './submittal-item';
import { SubmittalStage } from './submittal-stage';
import { SubmittalSubscriber } from './submittal-subscriber';
import { SyncedFeature } from './synced-feature';
import { Topic } from './topic';
import { TopicMembership } from './topic-membership';
import { User } from './user';
import { UsersFoldersGrant } from './users-folders-grant';

export type ModelRegistry = typeof modelRegistry;

export type RegisteredModelPrefix = keyof ModelRegistry;
export type RegisteredModelPrototype =
  (typeof modelRegistry)[keyof ModelRegistry];
export type RegisteredModelInstance = InstanceType<RegisteredModelPrototype>;

const modelRegistry = {
  accounts: Account,
  activityLogs: ActivityLog,
  billingPeriods: BillingPeriod,
  changeEvents: ChangeEvent,
  changeOrderAttachments: ChangeOrderAttachment,
  changeOrderLineItems: ChangeOrderLineItem,
  changeOrders: ChangeOrder,
  comments: Comment,
  commentThreads: CommentThread,
  commentThreadTags: CommentThreadTag,
  commitmentAttachments: CommitmentAttachment,
  commitments: Commitment,
  companies: Company,
  companyMemberships: CompanyMembership,
  costCodeGroups: CostCodeGroup,
  costCodes: CostCode,
  dailyLogEquipmentEntries: DailyLogEquipmentEntry,
  dailyLogPersonnelEntries: DailyLogPersonnelEntry,
  dailyLogs: DailyLog,
  discussions: Discussion,
  documentPages: DocumentPage,
  documents: Document,
  drawingAreas: DrawingArea,
  drawingDisciplineAbbreviations: DrawingDisciplineAbbreviation,
  drawingDisciplines: DrawingDiscipline,
  drawingSetPages: DrawingSetPage,
  drawingSets: DrawingSet,
  folders: Folder,
  hourlyWeatherLogs: HourlyWeatherLog,
  invoiceLineItems: InvoiceLineItem,
  invoices: Invoice,
  invoiceSubmissionAttachments: InvoiceSubmissionAttachment,
  invoiceSubmissionLineItems: InvoiceSubmissionLineItem,
  invoiceSubmissions: InvoiceSubmission,
  issueMemberships: TopicMembership,
  issues: Topic,
  journalCommits: JournalCommit,
  journalEntries: JournalEntry,
  markupSessions: MarkupSession,
  markupShapes: MarkupShape,
  myUsers: MyUser,
  notifications: Notification,
  objectLabels: ObjectLabel,
  organizationMemberships: OrganizationMembership,
  organizationPreferences: OrganizationPreference,
  organizations: Organization,
  pendingAttachments: PendingAttachment,
  permissions: Permission,
  photoAlbums: PhotoAlbum,
  photos: Photo,
  projectMemberships: ProjectMembership,
  projectPreferences: ProjectPreference,
  projects: Project,
  punchListItems: PunchListItem,
  questions: Question,
  rfiQuestionAttachments: RfiQuestionAttachment,
  rfiRespondents: RfiRespondent,
  rfiResponseAttachments: RfiResponseAttachment,
  rfiResponses: RfiResponse,
  rfis: Rfi,
  rfiSubscribers: RfiSubscriber,
  sheetNumberLinks: SheetNumberLink,
  sovLineItems: SovLineItem,
  submittalApproverDocuments: SubmittalApproverDocument,
  submittalApprovers: SubmittalApprover,
  submittalDocumentPages: SubmittalDocumentPage,
  submittalDocuments: SubmittalDocument,
  submittalGroups: SubmittalGroup,
  submittalItems: SubmittalItem,
  submittals: Submittal,
  submittalStages: SubmittalStage,
  submittalSubscribers: SubmittalSubscriber,
  subProjects: SubProject,
  syncedFeatures: SyncedFeature,
  users: User,
  usersFoldersGrants: UsersFoldersGrant,
};

export function getRegisteredModelPrefix(
  model: RegisteredModelPrototype
): RegisteredModelPrefix {
  for (const [prefix, registeredModel] of Object.entries(modelRegistry)) {
    if (model === registeredModel) {
      return prefix as RegisteredModelPrefix;
    }
  }
  invariant(false, `Unknown model: ${model.name}`);
}

export interface PoolObject {
  id: string;
  __prefix: string;
  objectPool: ObjectPool;
}

export class RecordNotFoundError extends Error {
  constructor(replicacheId: string) {
    super(`Record not found: ${replicacheId}`);
  }
}

export type CacheType = 'user' | 'membership' | 'project';

type WatchOp =
  | { type: 'add'; record: RegisteredModelInstance }
  | { type: 'remove'; record: RegisteredModelInstance }
  | {
      type: 'update';
      record: RegisteredModelInstance;
      oldAttributes: ReadonlyJSONObject;
      newAttributes: ReadonlyJSONObject;
    };

type WatchFunction = (op: WatchOp) => void;

export class ObjectPool {
  private objectMap: Map<
    RegisteredModelPrefix,
    Map<string, RegisteredModelInstance>
  > = new Map();
  private objectCaches: Record<string, CacheType> = {};
  private watchers: WatchFunction[] = [];

  static initializeSingleton() {
    invariant(!objectPool, 'ObjectPool instance already initialized');
    objectPool = new ObjectPool();
    return objectPool;
  }

  constructor(private readonly observe: boolean = true) {
    if (observe) {
      makeAutoObservable(this, {}, { autoBind: true });
    }
    (window as any).objectMap ||= this.objectMap;
  }

  addObject(cache: CacheType, key: string, attributes: ReadonlyJSONObject) {
    const [prefix, id] = key.split('/');
    if (!prefix || !id) return;
    if (!(prefix in modelRegistry)) return;

    const ModelClass = modelRegistry[prefix as RegisteredModelPrefix];
    const model = new ModelClass(id, attributes, this);
    if (this.observe) {
      model.observe();
    }

    const prefixMap =
      this.objectMap.get(prefix as RegisteredModelPrefix) || new Map();
    prefixMap.set(id, model);
    this.objectMap.set(prefix as RegisteredModelPrefix, prefixMap);

    this.objectCaches[model.id] = cache;
    this.watchers.forEach((fn) => fn({ type: 'add', record: model }));
    return model;
  }

  removeObject(key: string) {
    const [prefix, id] = key.split('/');
    if (!prefix || !id) return;
    delete this.objectCaches[id];
    const object = this.objectMap.get(prefix as RegisteredModelPrefix)?.get(id);
    if (object) {
      this.watchers.forEach((fn) => fn({ type: 'remove', record: object }));
    }

    this.objectMap.get(prefix as RegisteredModelPrefix)?.delete(id);
  }

  updateObject(key: string, attributes: ReadonlyJSONObject) {
    const [prefix, id] = key.split('/');
    if (!prefix || !id) return;

    const object = this.objectMap.get(prefix as RegisteredModelPrefix)?.get(id);
    if (object) {
      const oldAttributes = { ...object.attributes };
      Object.assign(object, attributes);

      this.watchers.forEach((fn) =>
        fn({
          type: 'update',
          record: object,
          oldAttributes,
          newAttributes: attributes,
        })
      );
    }
  }

  removeCache(cache: CacheType) {
    for (const [prefix, prefixMap] of this.objectMap.entries()) {
      prefixMap.forEach((obj) => {
        if (this.objectCaches[obj.id] === cache) {
          delete this.objectCaches[obj.id];
          this.objectMap.get(prefix)?.delete(obj.id);
        }
      });
    }
  }

  all<T extends RegisteredModelPrototype>(type: T): Array<InstanceType<T>> {
    const prefix = getRegisteredModelPrefix(type);
    const objects = (this.objectMap.get(prefix) || new Map()).values();
    return Array.from(objects);
  }

  find<T extends RegisteredModelPrototype>(
    type: T,
    id: string
  ): InstanceType<T> | null {
    const prefix = getRegisteredModelPrefix(type);
    const objectsOfType = this.objectMap.get(prefix) || new Map();
    const object = objectsOfType.get(id) || null;
    return object as InstanceType<T> | null;
  }

  watch(fn: WatchFunction) {
    this.watchers.push(fn);
    return () => {
      this.watchers = this.watchers.filter((f) => f !== fn);
    };
  }
}

export let objectPool: ObjectPool;

export const ObjectPoolContext = createContext<ObjectPool | null>(null);

export const useObjectPool = () => {
  const objectPool = useContext(ObjectPoolContext);
  if (!objectPool) {
    throw new Error('useObjectPool must be used within a ObjectPoolProvider');
  }
  return objectPool;
};
