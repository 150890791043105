import { DrawingSetPage } from '@/models/drawing-set-page';
import { WriteTransaction } from 'replicache';

export async function bulkEditDrawingSetPage(
  tx: WriteTransaction,
  args: {
    ids: string[];
    sheetDate: string | null;
    updatedAt: string;
  }
) {
  for (const id of args.ids) {
    const page = await DrawingSetPage.mustGet(tx, id);
    await DrawingSetPage.set(tx, {
      ...page,
      sheetDate: args.sheetDate,
      updatedAt: args.updatedAt,
    });
  }
}
