import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ReadonlyJSONObject } from 'replicache';
import { ObjectPool } from './object-pool';
import { Commitment } from './commitment';
import { Project } from './project';
import { PendingAttachment } from './pending-attachment';

export default class CommitmentAttachment extends ApplicationRecord {
  static prefix = 'commitmentAttachments' as const;

  id: string;
  createdAt: string;
  updatedAt: string;
  commitmentId: string;
  fileName: string | null | undefined;
  fileUrl: string | null | undefined;
  projectId: string;

  static schema = z.object({
    id: z.string(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    commitmentId: z.string(),
    fileName: z.string().nullish(),
    fileUrl: z.string().nullish(),
    projectId: z.string(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
    this.commitmentId = this.attribute('commitmentId');
    this.fileName = this.attribute('fileName');
    this.fileUrl = this.attribute('fileUrl');
    this.projectId = this.attribute('projectId');
  }

  get commitment() {
    return this.belongsTo(Commitment, this.commitmentId);
  }

  get project() {
    return this.belongsTo(Project, this.projectId);
  }

  get pendingAttachment() {
    return this.hasOne(PendingAttachment, 'recordId');
  }
}
