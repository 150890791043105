import { WriteTransaction } from 'replicache';

export default async function updateCostCodes(
  tx: WriteTransaction,
  args: {
    costCodeGroups: {
      id?: string | null | undefined;
      newId: string;
      title: string;
      costCodes: {
        id?: string | null | undefined;
        newId: string;
        code1: string;
        code2?: string | null | undefined;
        code3?: string | null | undefined;
        title: string;
      }[];
    }[];
    now: string;
  }
) {}
