import { DrawingSet } from '@/models/drawing-set';
import { WriteTransaction } from 'replicache';

export async function updateDrawingSet(
  tx: WriteTransaction,
  args: {
    id: string;
    drawingAreaId: string;
    name: string;
    description: string;
    receivedOn: string;
    updatedAt: string;
  }
) {
  const set = await DrawingSet.mustGet(tx, args.id);
  await DrawingSet.set(tx, {
    ...set,
    name: args.name,
    description: args.description,
    receivedOn: args.receivedOn,
  });
}
