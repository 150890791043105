import { DrawingArea } from '@/models/drawing-area';
import { Project, ProjectStage } from '@/models/project';
import { WriteTransaction } from 'replicache';

export const createProject = async (
  tx: WriteTransaction,
  {
    id,
    organizationId,
    name,
    stage,
    number,
    address1,
    address2,
    city,
    state,
    postalCode,
    country,
    coverPhotoUrl,
    createdAt,
    updatedAt,
    infoUpdatedAt,
    settingsUpdatedAt,
    drawingAreaId,
  }: {
    id: string;
    organizationId: string;
    name: string;
    stage?: ProjectStage | null | undefined;
    number?: string | null;
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    state?: string | null;
    postalCode?: string | null;
    country?: string | null;
    coverPhotoUrl?: string | null | undefined;
    createdAt: string;
    updatedAt: string;
    infoUpdatedAt?: string | null;
    settingsUpdatedAt?: string | null;
    drawingAreaId: string;
  }
) => {
  await Project.set(tx, {
    id,
    organizationId,
    name,
    stage,
    number,
    address1,
    address2,
    city,
    state,
    postalCode,
    country,
    coverPhotoUrl,
    createdAt,
    updatedAt,
    infoUpdatedAt,
    settingsUpdatedAt,
  });
  await DrawingArea.set(tx, {
    id: drawingAreaId,
    projectId: id,
    organizationId: organizationId,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  });
};
