import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ObjectPool } from './object-pool';
import { Rfi } from './rfi';
import { User } from './user';

export class RfiSubscriber extends ApplicationRecord {
  id: string;
  rfiId: string;
  userId: string;
  createdAt: string;
  updatedAt: string;

  static prefix = 'rfiSubscribers' as const;

  static schema = z.object({
    id: z.string(),
    rfiId: z.string(),
    userId: z.string(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.rfiId = this.attribute('rfiId');
    this.userId = this.attribute('userId');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
  }

  get rfi() {
    return this.belongsTo(Rfi, this.rfiId);
  }

  get user() {
    return this.belongsTo(User, this.userId);
  }
}
