import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { DailyLog } from './daily-log';
import { ObjectPool } from './object-pool';

export class DailyLogEquipmentEntry extends ApplicationRecord {
  id: string;
  dailyLogId: string;
  name: string;
  notes: string;
  inspectedAt?: string | null | undefined;
  createdAt: string;
  updatedAt: string;

  static prefix = 'dailyLogEquipmentEntries' as const;

  static schema = z.object({
    id: z.string(),
    dailyLogId: z.string(),
    name: z.string(),
    notes: z.string(),
    inspectedAt: z.string().nullish(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.dailyLogId = this.attribute('dailyLogId');
    this.name = this.attribute('name');
    this.inspectedAt = this.attribute('inspectedAt');
    this.notes = this.attribute('notes');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
  }

  get dailyLog() {
    return this.belongsTo(DailyLog, this.dailyLogId);
  }
}
