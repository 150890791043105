import { Topic } from '@/models/topic';
import { WriteTransaction } from 'replicache';

export async function moveIssueFromNewToDraft(
  tx: WriteTransaction,
  args: { id: string; title: string }
) {
  const issue = await Topic.mustGet(tx, args.id);
  if (issue.status === 'new') {
    await Topic.set(tx, { ...issue, status: 'draft', title: args.title });
  }
}
