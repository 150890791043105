import { Company, CompanyType } from '@/models/company';
import { Organization, OrganizationType } from '@/models/organization';
import { WriteTransaction } from 'replicache';

export default async function updateCompanyOrOrganizationInfo(
  tx: WriteTransaction,
  {
    companyOrOrganizationId,
    name,
    url,
    type,
    now,
  }: {
    companyOrOrganizationId: string;
    name: string;
    url?: string | null | undefined;
    type: CompanyType | OrganizationType;
    now: string;
  }
) {
  const company = await Company.get(tx, companyOrOrganizationId);
  const organization = await Organization.get(tx, companyOrOrganizationId);

  if (company) {
    await Company.set(tx, {
      ...company,
      name,
      url,
      type: type as CompanyType,
      updatedAt: now,
    });
  } else if (organization) {
    await Organization.set(tx, {
      ...organization,
      name,
      url,
      updatedAt: now,
    });
  } else {
    throw new Error('Company or organization not found');
  }
}
