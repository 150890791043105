import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ObjectPool } from './object-pool';
import { Project } from './project';

export class Question extends ApplicationRecord {
  id: string;
  projectId: string;
  content: string;
  completedAt?: string | null;
  answer?: string | null;
  createdAt: string;
  updatedAt: string;

  static prefix = 'questions' as const;

  static schema = z.object({
    id: z.string(),
    projectId: z.string(),
    content: z.string(),
    answer: z.string().nullish(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    completedAt: z.string().datetime().nullish(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.projectId = this.attribute('projectId');
    this.content = this.attribute('content');
    this.completedAt = this.attribute('completedAt');
    this.answer = this.attribute('answer');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
  }

  get project() {
    return this.belongsTo(Project, this.projectId);
  }
}
