import { Comment } from '@/models/comment';
import { DailyLog } from '@/models/daily-log';
import { DrawingSetPage } from '@/models/drawing-set-page';
import { ObjectPool } from '@/models/object-pool';
import { PhotoAlbum } from '@/models/photo-album';
import { PunchListItem } from '@/models/punch-list-item';
import { Rfi } from '@/models/rfi';
import { Submittal } from '@/models/submittal';
import { compact, replace } from 'lodash';

export const baseUrl: string = import.meta.env['VITE_API_URL'];

export function path(
  objectPool: ObjectPool,
  {
    objectId,
    projectId,
    organizationId,
    contextParams = {},
  }: {
    objectId: string;
    projectId: string;
    organizationId: string;
    contextParams?: Record<string, string | null>;
  }
) {
  const drawingSetPage = objectPool.find(DrawingSetPage, objectId);
  if (drawingSetPage) {
    const sheetNumber = drawingSetPage.sheetNumber;
    const revisions = sheetNumber
      ? drawingSetPage.drawingSet.drawingArea.pageNumberRevisions[sheetNumber]
      : null;
    const revision = revisions?.[0] ?? drawingSetPage;

    return newDrawingViewerPath({
      orgId: organizationId,
      projectId: revision.drawingSet.drawingArea.project.id,
      drawingAreaId: revision.drawingSet.drawingArea.id,
      pageId: revision.id,
      ...contextParams,
    });
  }

  const dailyLog = objectPool.find(DailyLog, objectId);
  if (dailyLog) {
    return dailyLogPath(organizationId, projectId, dailyLog.id);
  }

  const punchListItem = objectPool.find(PunchListItem, objectId);
  if (punchListItem) {
    return punchListPath(organizationId, projectId, punchListItem.id);
  }

  const rfi = objectPool.find(Rfi, objectId);
  if (rfi) {
    return rfiPath(organizationId, projectId, rfi.id);
  }

  const submittal = objectPool.find(Submittal, objectId);
  if (submittal) {
    return submittalsPath(organizationId, projectId, submittal.id);
  }

  const photoAlbum = objectPool.find(PhotoAlbum, objectId);
  if (photoAlbum) {
    return photoAlbumPath(organizationId, projectId, photoAlbum.id);
  }

  const comment = objectPool.find(Comment, objectId);
  if (comment) {
    if (
      !comment.commentThread.drawingSetPage ||
      !comment.commentThread.latestCommentShape ||
      !comment.commentThread.topic
    ) {
      return null;
    }

    return commentPath({
      organizationId,
      projectId,
      drawingAreaId:
        comment.commentThread.drawingSetPage.drawingSet.drawingArea.id,
      drawingSetPageId: comment.commentThread.drawingSetPage.id,
      commentId: comment.id,
      markupShapeTldrawId: comment.commentThread.latestCommentShape.tldrawId,
      issueId: comment.commentThread.topic?.id,
    });
  }

  return null;
}

export function orgPath(orgId: string) {
  return `/org/${orgId}`;
}

export function projectPath(orgId: string, projectId: string) {
  return `/org/${orgId}/projects/${projectId}`;
}

export function myUserPath(orgId: string) {
  return `/org/${orgId}/my-user`;
}

export function topicsPath(orgId: string, projectId: string) {
  return `/org/${orgId}/projects/${projectId}/topics`;
}

export function topicPath(orgId: string, projectId: string, topicId: string) {
  return `/org/${orgId}/projects/${projectId}/topics/${topicId}`;
}

export function permissionsPath(orgId: string) {
  return `/org/${orgId}/permissions`;
}

export function directoryPath(orgId: string, projectId?: string) {
  if (projectId) {
    return `/org/${orgId}/projects/${projectId}/directory`;
  } else {
    return `/org/${orgId}/directory`;
  }
}

export function organizationSettingsPath(orgId: string) {
  return `/org/${orgId}/settings`;
}

export function directoryCompanyPath(
  orgId: string,
  companyOrOrganizationId: string
) {
  return `/org/${orgId}/directory/company/${companyOrOrganizationId}`;
}

export function directoryMembershipPath(orgId: string, membershipId: string) {
  return `/org/${orgId}/directory/user/${membershipId}`;
}

export function documentsPath(
  orgId: string,
  projectId: string,
  folder?: string
) {
  return replace(
    `/org/${orgId}/projects/${projectId}/documents/${folder || ''}`,
    /\/+/g,
    '/'
  );
}

export function punchListPath(
  orgId: string,
  projectId: string,
  punchListItemId?: string,
  anchor?: string
) {
  if (punchListItemId) {
    return `/org/${orgId}/projects/${projectId}/punch-list/${punchListItemId}#${anchor}`;
  } else {
    return `/org/${orgId}/projects/${projectId}/punch-list`;
  }
}

export function editPunchListPath(
  orgId: string,
  projectId: string,
  punchListItemId: string
) {
  return `/org/${orgId}/projects/${projectId}/punch-list-edit/${punchListItemId}`;
}

export function rfiPath(orgId: string, projectId: string, rfiId?: string) {
  if (rfiId) {
    return `/org/${orgId}/projects/${projectId}/rfi/${rfiId}`;
  } else {
    return `/org/${orgId}/projects/${projectId}/rfi`;
  }
}

export function rfiEditPath(orgId: string, projectId: string, rfiId: string) {
  return `/org/${orgId}/projects/${projectId}/rfi-edit/${rfiId}`;
}

export function submittalsPath(orgId: string, projectId: string, id?: string) {
  if (id) {
    return `/org/${orgId}/projects/${projectId}/submittals/${id}`;
  } else {
    return `/org/${orgId}/projects/${projectId}/submittals`;
  }
}

export function newSubmittalPath(
  orgId: string,
  projectId: string,
  parentSubmittalId?: string
) {
  return `/org/${orgId}/projects/${projectId}/submittals/new${parentSubmittalId ? `?parentSubmittalId=${parentSubmittalId}` : ''}`;
}

export function activityLogPath(orgId: string, projectId: string) {
  return `/org/${orgId}/projects/${projectId}/activity_log`;
}

export function drawingsPath(
  orgId: string,
  projectId: string,
  drawingAreaId: string
) {
  return `/org/${orgId}/projects/${projectId}/drawing-areas/${drawingAreaId}`;
}

export function drawingSetsPath(
  orgId: string,
  projectId: string,
  drawingAreaId: string
) {
  return `/org/${orgId}/projects/${projectId}/drawing-areas/${drawingAreaId}/drawing-sets`;
}

export function drawingAreaPath(
  orgId: string,
  projectId: string,
  drawingAreaId: string
) {
  return `/org/${orgId}/projects/${projectId}/drawing-areas/${drawingAreaId}`;
}

export function photosPath(orgId: string, projectId: string) {
  return `/org/${orgId}/projects/${projectId}/photos`;
}

export function photoAlbumPath(
  orgId: string,
  projectId: string,
  photoAlbumId: string
) {
  return `/org/${orgId}/projects/${projectId}/photos/album/${photoAlbumId}`;
}

export function drawingSetPath(
  orgId: string,
  projectId: string,
  drawingAreaId: string,
  drawingSetId: string
) {
  return `/org/${orgId}/projects/${projectId}/drawing-areas/${drawingAreaId}/drawing-sets/${drawingSetId}`;
}

export function drawingSetReviewPath(
  orgId: string,
  projectId: string,
  drawingAreaId: string,
  drawingSetId: string
) {
  return `/org/${orgId}/projects/${projectId}/drawing-areas/${drawingAreaId}/drawing-sets/${drawingSetId}/review`;
}

export function commentPath({
  organizationId,
  projectId,
  drawingAreaId,
  drawingSetPageId,
  commentId,
  markupShapeTldrawId,
  issueId,
}: {
  organizationId: string;
  projectId: string;
  drawingAreaId: string;
  drawingSetPageId: string;
  commentId: string;
  markupShapeTldrawId: string;
  issueId: string;
}) {
  const queryParams = [
    `primarySelectedShapeId=${markupShapeTldrawId}`,
    `activeTopicId=${issueId}`,
  ];

  return `/org/${organizationId}/projects/${projectId}/drawing-areas/${drawingAreaId}/new-viewer/${drawingSetPageId}?${queryParams.join('&')}`;
}

export function drawingAreaViewerPath({
  orgId,
  projectId,
  drawingAreaId,
  pageId,
  rightPageId,
  drawingSetId,
  tabId,
  topicId,
  overlayMode,
  overlayPageId,
  searchPhrase,
}: {
  orgId: string;
  projectId: string;
  drawingAreaId: string;
  pageId: string;
  rightPageId?: string | null;
  tabId?: string | null;
  drawingSetId?: string;
  topicId?: string;
  overlayMode?: string;
  overlayPageId?: string;
  searchPhrase?: string;
}) {
  return newDrawingViewerPath({
    orgId,
    projectId,
    drawingAreaId,
    pageId,
    secondaryPageId: rightPageId,
    drawingSetId,
    activeTopicId: topicId,
    overlayMode,
    overlayPageId,
    searchPhrase,
  });
}

export function newDrawingViewerPath({
  orgId,
  projectId,
  drawingAreaId,
  pageId,
  secondaryPageId,
  drawingSetId,
  activeTopicId,
  primarySelectedShapeId,
  overlayMode,
  overlayPageId,
  searchPhrase,
}: {
  orgId: string;
  projectId: string;
  drawingAreaId: string;
  pageId: string;
  secondaryPageId?: string | null;
  drawingSetId?: string | null;
  activeTopicId?: string;
  primarySelectedShapeId?: string;
  overlayMode?: string;
  overlayPageId?: string;
  searchPhrase?: string;
}) {
  const queryParams = compact([
    secondaryPageId ? `secondaryPageId=${secondaryPageId}` : null,
    drawingSetId ? `drawingSetId=${drawingSetId}` : null,
    activeTopicId ? `activeTopicId=${activeTopicId}` : null,
    drawingSetId ? `drawingSetId=${drawingSetId}` : null,
    primarySelectedShapeId
      ? `primarySelectedShapeId=${primarySelectedShapeId}`
      : null,
    overlayMode ? `overlayMode=${overlayMode}` : null,
    overlayPageId ? `overlayPageId=${overlayPageId}` : null,
    searchPhrase ? `searchPhrase=${encodeURIComponent(searchPhrase)}` : null,
  ]);

  return `/org/${orgId}/projects/${projectId}/drawing-areas/${drawingAreaId}/new-viewer/${pageId}?${queryParams.join('&')}`;
}

export function dailyLogsPath(orgId: string, projectId: string) {
  return `/org/${orgId}/projects/${projectId}/daily-logs`;
}

export function dailyLogPath(
  orgId: string,
  projectId: string,
  dailyLogId: string
) {
  return `/org/${orgId}/projects/${projectId}/daily-logs/${dailyLogId}`;
}

export function newDailyLogPath(orgId: string, projectId: string) {
  return `/org/${orgId}/projects/${projectId}/daily-logs/new`;
}

export function redirectPath(id: string) {
  return `/r/${id}`;
}

export function notificationsPath(orgId: string, projectId?: string) {
  if (projectId) {
    return `/org/${orgId}/projects/${projectId}/notifications`;
  } else {
    return `/org/${orgId}/notifications`;
  }
}

export function commitmentPath(
  orgId: string,
  projectId: string,
  commitmentId: string
) {
  return `/org/${orgId}/projects/${projectId}/commitments/${commitmentId}`;
}

export function schedulePath(orgId: string, projectId: string) {
  return `/org/${orgId}/projects/${projectId}/schedule`;
}

export function invoicesPath(orgId: string, projectId: string) {
  return `/org/${orgId}/projects/${projectId}/invoices`;
}

export function primeCommitmentPath(orgId: string, projectId: string) {
  return `/org/${orgId}/projects/${projectId}/prime-commitment`;
}

export function invoiceSubmissionPath(
  orgId: string,
  projectId: string,
  invoiceSubmissionId: string
) {
  return `/org/${orgId}/projects/${projectId}/invoice-submissions/${invoiceSubmissionId}`;
}

export function changeEventsPath(
  orgId: string,
  projectId: string,
  changeEventId?: string | null | undefined
) {
  if (changeEventId) {
    return `/org/${orgId}/projects/${projectId}/change-events/${changeEventId}`;
  } else {
    return `/org/${orgId}/projects/${projectId}/change-events`;
  }
}

export function changeOrderPath(
  orgId: string,
  projectId: string,
  changeOrderId: string
) {
  return `/org/${orgId}/projects/${projectId}/change-orders/${changeOrderId}`;
}
