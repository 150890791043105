import { WriteTransaction } from 'replicache';

export default async function updateMembershipInfo(
  tx: WriteTransaction,
  args: {
    membershipId: string;
    newMembershipId: string;
    role: string;
    companyOrOrganizationId: string;
  }
) {
  // This one is a bit complicated and my hunch is that it will change
  // soon regardless, so let's wait a bit before implementing it.
}
