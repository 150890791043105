import { apiEndpoint } from '@/lib/utils';
import { acceptRfiResponse } from '@/mutations/accept-rfi-response';
import { addCommentToThread } from '@/mutations/add-comment-to-thread';
import { addFolder } from '@/mutations/add-folder';
import addMembershipWithUser from '@/mutations/add-membership-with-user';
import { addOrEditBillingPeriod } from '@/mutations/add-or-edit-billing-period';
import addOrRemovePeopleToProjectTeam from '@/mutations/add-or-remove-people-to-project-team';
import addPhotos from '@/mutations/add-photos';
import approveInvoiceSubmission from '@/mutations/approve-invoice-submission';
import { archiveIssue } from '@/mutations/archive-issue';
import { askQuestion } from '@/mutations/ask-question';
import { bulkEditDrawingSetPage } from '@/mutations/bulk-edit-drawing-set-page';
import { cancelRfi } from '@/mutations/cancel-rfi';
import changeAvatar from '@/mutations/change-avatar';
import changeChangeOrderStatus from '@/mutations/change-change-order-status';
import changeCommitmentStatus from '@/mutations/change-commitment-status';
import changePunchListItemStatus from '@/mutations/change-punch-list-item-status';
import { confirmDrawingSetPage } from '@/mutations/confirm-drawing-set-page';
import { createChangeEvent } from '@/mutations/create-change-event';
import createChangeOrderAttachment from '@/mutations/create-change-order-attachment';
import createCommitment from '@/mutations/create-commitment';
import createCommitmentAttachment from '@/mutations/create-commitment-attachment';
import createCompany from '@/mutations/create-company';
import { createDiscussion } from '@/mutations/create-discussion';
import { createDocument } from '@/mutations/create-document';
import createDrawingAreas from '@/mutations/create-drawing-areas';
import { createDrawingDiscipline } from '@/mutations/create-drawing-discipline';
import { createDrawingSet } from '@/mutations/create-drawing-set';
import createInvoiceSubmission from '@/mutations/create-invoice-submission';
import createInvoiceSubmissionAttachment from '@/mutations/create-invoice-submission-attachment';
import { createIssue } from '@/mutations/create-issue';
import { createIssueMembership } from '@/mutations/create-issue-membership';
import { createPendingAttachment } from '@/mutations/create-pending-attachment';
import createPhoto from '@/mutations/create-photo';
import { createProject } from '@/mutations/create-project';
import createPunchListItem from '@/mutations/create-punch-list-item';
import { createRfi } from '@/mutations/create-rfi';
import createRfiQuestionAttachment from '@/mutations/create-rfi-question-attachment';
import { createRfiResponseAttachment } from '@/mutations/create-rfi-response-attachment';
import { createSubmittal } from '@/mutations/create-submittal';
import { createSubmittalApproverDocument } from '@/mutations/create-submittal-approver-document';
import { createSubmittalDocument } from '@/mutations/create-submittal-document';
import { createSubmittalGroup } from '@/mutations/create-submittal-group';
import createUnattachedPhoto from '@/mutations/create-unattached-photo';
import { deleteBillingPeriod } from '@/mutations/delete-billing-period';
import deleteChangeOrderAttachment from '@/mutations/delete-change-order-attachment';
import { deleteComment } from '@/mutations/delete-comment';
import deleteCommitmentAttachment from '@/mutations/delete-commitment-attachment';
import { deleteDailyLogEquipmentEntry } from '@/mutations/delete-daily-log-equipment-entry';
import { deleteDailyLogPersonnelEntry } from '@/mutations/delete-daily-log-personnel-entry';
import { deleteDocument } from '@/mutations/delete-document';
import { deleteDrawingSetPage } from '@/mutations/delete-drawing-set-page';
import { deleteFolder } from '@/mutations/delete-folder';
import deleteInvoiceSubmission from '@/mutations/delete-invoice-submission';
import deleteInvoiceSubmissionAttachment from '@/mutations/delete-invoice-submission-attachment';
import destroyRfiQuestionAttachment from '@/mutations/destroy-rfi-question-attachment';
import { destroyRfiResponseAttachment } from '@/mutations/destroy-rfi-response-attachment';
import distributeSubmittal from '@/mutations/distribute-submittal';
import { editComment } from '@/mutations/edit-comment';
import editProjectInfo from '@/mutations/edit-project-info';
import editProjectSettings from '@/mutations/edit-project-settings';
import { failedPendingAttachment } from '@/mutations/failed-pending-attachment';
import { logActivity } from '@/mutations/log-activity';
import { markNotificationAsRead } from '@/mutations/mark-notification-as-read';
import { mergeTopics } from '@/mutations/merge-topics';
import { moveIssueFromNewToDraft } from '@/mutations/move-issue-from-new-to-draft';
import { newEditorCreateIssue } from '@/mutations/new-editor-create-issue';
import { newEditorUpdateIssue } from '@/mutations/new-editor-update-issue';
import projectVisited from '@/mutations/project-visited';
import { publishDrawingSetPages } from '@/mutations/publish-drawing-set-pages';
import { putDailyLog } from '@/mutations/put-daily-log';
import { putDailyLogEquipmentEntry } from '@/mutations/put-daily-log-equipment-entry';
import { putDailyLogPersonnelEntry } from '@/mutations/put-daily-log-personnel-entry';
import { putMarkupSession } from '@/mutations/put-markup-session';
import { putMarkupShape } from '@/mutations/put-markup-shape';
import { removeIssueMembership } from '@/mutations/remove-issue-membership';
import { renameDocument } from '@/mutations/rename-document';
import { renameFolder } from '@/mutations/rename-folder';
import renameRfi from '@/mutations/rename-rfi';
import renameSubmittal from '@/mutations/rename-submittal';
import { reopenCommentThread } from '@/mutations/reopen-comment-thread';
import { resolveCommentThread } from '@/mutations/resolve-comment-thread';
import { respondToRfi } from '@/mutations/respond-to-rfi';
import respondToSubmittal from '@/mutations/respond-to-submittal';
import saveInvoiceSubmissionLineItems from '@/mutations/save-invoice-submission-line-items';
import { setDrawingSetPageMeasure } from '@/mutations/set-drawing-set-page-measure';
import { shareFolder } from '@/mutations/share-folder';
import { startCommentThread } from '@/mutations/start-comment-thread';
import { startUploadingPendingAttachment } from '@/mutations/start-uploading-pending-attachment';
import submitInvoice from '@/mutations/submit-invoice';
import syncFeature from '@/mutations/sync-feature';
import tagCommentThread from '@/mutations/tag-comment-thread';
import toggleSheetNumberStarred from '@/mutations/toggle-sheet-number-starred';
import { unarchiveIssue } from '@/mutations/unarchive-issue';
import { unconfirmDrawingSetPage } from '@/mutations/unconfirm-drawing-set-page';
import { unpublishDrawingSetPages } from '@/mutations/unpublish-drawing-set-pages';
import untagCommentThread from '@/mutations/untag-comment-thread';
import { updateChangeEventLineItems } from '@/mutations/update-change-event-line-items';
import { updateChangeEventOverview } from '@/mutations/update-change-event-overview';
import updateCommitmentOriginalContractSov from '@/mutations/update-commitment-original-contract-sov';
import updateCompanyOrOrganizationInfo from '@/mutations/update-company-or-organization-info';
import updateCostCodes from '@/mutations/update-cost-codes';
import updateDrawingAreas from '@/mutations/update-drawing-areas';
import { updateDrawingSet } from '@/mutations/update-drawing-set';
import { updateDrawingSetPage } from '@/mutations/update-drawing-set-page';
import { updateIssue } from '@/mutations/update-issue';
import updateMembershipInfo from '@/mutations/update-membership-info';
import { updateMyEmail } from '@/mutations/update-my-email';
import updatePunchListItem from '@/mutations/update-punch-list-item';
import updateRfiDescription from '@/mutations/update-rfi-description';
import updateRfiOverview from '@/mutations/update-rfi-overview';
import updateSubmittalDescription from '@/mutations/update-submittal-description';
import updateSubmittalOverview from '@/mutations/update-submittal-overview';
import { uploadedPendingAttachment } from '@/mutations/uploaded-pending-attachment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import type { KVStoreProvider, ReadTransaction } from 'replicache';
import { Replicache } from 'replicache';

const USER_SCHEMA_VERSION = '100';
const MEMBERSHIP_SCHEMA_VERSION = '100';
const PROJECT_SCHEMA_VERSION = '102';

//
// Log Mutations
//

const logMutator = (mutatorName: string, mutator: Function) => {
  return (...args: any[]) => {
    console.log(`mutate ${mutatorName}`, args);
    return mutator(...args);
  };
};

function wrapMutators<T extends Record<string, any>>(mutators: T): T {
  if (import.meta.env.VITE_LOG_MUTATORS) {
    return Object.fromEntries(
      Object.entries(mutators).map(([name, mutator]) => [
        name,
        logMutator(name, mutator),
        mutator,
      ])
    ) as T;
  }

  return mutators;
}

//
// User Replicache
//

export const userMutators = wrapMutators({
  markNotificationAsRead,
  toggleSheetNumberStarred,
  projectVisited,
  updateMyEmail,
  changeAvatar,
} as const);

export const createUserReplicache = ({
  userId,
  userToken,
  kvStore,
}: {
  userId: string;
  userToken: string;
  kvStore: KVStoreProvider | 'mem' | 'idb';
}) => {
  const replicache = new Replicache({
    name: `user/${userId}`,
    auth: `Bearer ${userToken}`,
    licenseKey: import.meta.env.VITE_REPLICACHE_LICENSE_KEY,
    pullURL: apiEndpoint('/replicache/pulls/user'),
    pushURL: apiEndpoint('/replicache/pushes/user'),
    schemaVersion: USER_SCHEMA_VERSION,
    pullInterval: 60000,
    mutators: userMutators,
    kvStore,
  });

  return replicache;
};

export type UserReplicache = ReturnType<typeof createUserReplicache>;

export const UserCacheContext = React.createContext<ReturnType<
  typeof createUserReplicache
> | null>(null);

export const useUserCache = () => {
  const cache = useContext(UserCacheContext);
  if (!cache) throw new Error('User replicache not configured');

  return cache;
};

//
// Membership Replicache
//

export const membershipMutators = wrapMutators({
  createCompany,
  createProject,
  editProjectInfo,
  editProjectSettings,
  addMembershipWithUser,
  updateMembershipInfo,
  addOrRemovePeopleToProjectTeam,
  updateCompanyOrOrganizationInfo,
  updateCostCodes,
} as const);

export const createMembershipReplicache = ({
  userId,
  organizationId,
  token,
  kvStore,
}: {
  userId: string;
  organizationId: string;
  token: string;
  kvStore: KVStoreProvider | 'mem' | 'idb';
}) => {
  const replicache = new Replicache({
    name: `organizationMembership/${userId}/${organizationId}`, // should be under 'membership/*' but no harm
    auth: `Bearer ${token}`,
    licenseKey: import.meta.env.VITE_REPLICACHE_LICENSE_KEY,
    pullURL: apiEndpoint('/replicache/pulls/membership'),
    pushURL: apiEndpoint('/replicache/pushes/membership'),
    schemaVersion: MEMBERSHIP_SCHEMA_VERSION,
    pullInterval: 60000,
    mutators: membershipMutators,
    kvStore,
  });

  return replicache;
};

export type MembershipReplicache = ReturnType<
  typeof createMembershipReplicache
>;

export const MembershipCacheContext = React.createContext<ReturnType<
  typeof createMembershipReplicache
> | null>(null);

export const useMembershipCache = () => {
  const cache = useContext(MembershipCacheContext);
  if (!cache) throw new Error('Membership replicache not configured');

  return cache;
};

//
// Project Replicache
//

export const projectMutators = wrapMutators({
  acceptRfiResponse,
  addCommentToThread,
  addFolder,
  addOrEditBillingPeriod,
  addPhotos,
  approveInvoiceSubmission,
  archiveIssue,
  askQuestion,
  bulkEditDrawingSetPage,
  cancelRfi,
  changeChangeOrderStatus,
  changeCommitmentStatus,
  changePunchListItemStatus,
  confirmDrawingSetPage,
  createChangeEvent,
  createChangeOrderAttachment,
  createCommitment,
  createCommitmentAttachment,
  createDiscussion,
  createDocument,
  createDrawingAreas,
  createDrawingDiscipline,
  createDrawingSet,
  createInvoiceSubmission,
  createInvoiceSubmissionAttachment,
  createIssue,
  createIssueMembership,
  createPendingAttachment,
  createPhoto,
  createPunchListItem,
  createRfi,
  createRfiQuestionAttachment,
  createRfiResponseAttachment,
  createSubmittal,
  createSubmittalApproverDocument,
  createSubmittalDocument,
  createSubmittalGroup,
  createUnattachedPhoto,
  deleteBillingPeriod,
  deleteChangeOrderAttachment,
  deleteComment,
  deleteCommitmentAttachment,
  deleteDailyLogEquipmentEntry,
  deleteDailyLogPersonnelEntry,
  deleteDocument,
  deleteDrawingSetPage,
  deleteFolder,
  deleteInvoiceSubmission,
  deleteInvoiceSubmissionAttachment,
  destroyRfiQuestionAttachment,
  destroyRfiResponseAttachment,
  distributeSubmittal,
  editComment,
  failedPendingAttachment,
  logActivity,
  mergeTopics,
  moveIssueFromNewToDraft,
  newEditorCreateIssue,
  newEditorUpdateIssue,
  publishDrawingSetPages,
  putDailyLog,
  putDailyLogEquipmentEntry,
  putDailyLogPersonnelEntry,
  putMarkupSession,
  putMarkupShape,
  removeIssueMembership,
  renameDocument,
  renameFolder,
  renameRfi,
  renameSubmittal,
  reopenCommentThread,
  resolveCommentThread,
  respondToRfi,
  respondToSubmittal,
  saveInvoiceSubmissionLineItems,
  setDrawingSetPageMeasure,
  shareFolder,
  startCommentThread,
  startUploadingPendingAttachment,
  submitInvoice,
  syncFeature,
  tagCommentThread,
  unarchiveIssue,
  unconfirmDrawingSetPage,
  unpublishDrawingSetPages,
  untagCommentThread,
  updateChangeEventLineItems,
  updateChangeEventOverview,
  updateCommitmentOriginalContractSov,
  updateDrawingAreas,
  updateDrawingSet,
  updateDrawingSetPage,
  updateIssue,
  updatePunchListItem,
  updateRfiDescription,
  updateRfiOverview,
  updateSubmittalDescription,
  updateSubmittalOverview,
  uploadedPendingAttachment,
} as const);

export const createProjectReplicache = ({
  userId,
  organizationId,
  projectId,
  token,
  kvStore,
}: {
  userId: string;
  organizationId: string;
  projectId: string;
  token: string;
  kvStore: KVStoreProvider | 'mem' | 'idb';
}) => {
  const replicache = new Replicache({
    name: `project/${userId}/${organizationId}/${projectId}`,
    auth: `Bearer ${token}`,
    licenseKey: import.meta.env.VITE_REPLICACHE_LICENSE_KEY,
    pullURL: apiEndpoint(`/replicache/pulls/project/${projectId}`),
    pushURL: apiEndpoint(`/replicache/pushes/project/${projectId}`),
    schemaVersion: PROJECT_SCHEMA_VERSION,
    pullInterval: 60000,
    mutators: projectMutators,
    kvStore,
  });

  return replicache;
};

export type ProjectReplicache = ReturnType<typeof createProjectReplicache>;

export const ProjectCacheContext = React.createContext<ReturnType<
  typeof createProjectReplicache
> | null>(null);

export const useProjectCache = () => {
  const cache = useContext(ProjectCacheContext);
  if (!cache) throw new Error('Project replicache not configured');

  return cache;
};

//
// Other Stuff
//

// We wrap all the callbacks in a `unstable_batchedUpdates` call to ensure that
// we do not render things more than once over all of the changed subscriptions.

let hasPendingCallback = false;
let callbacks: (() => void)[] = [];

function doCallback() {
  const cbs = callbacks;
  callbacks = [];
  hasPendingCallback = false;
  unstable_batchedUpdates(() => {
    for (const callback of cbs) {
      callback();
    }
  });
}

function wrapPromise<T>(promise: Promise<T>) {
  let status = 'pending';
  let result: any;
  let suspender = promise.then(
    (r: T) => {
      status = 'success';
      result = r;
    },
    (e: any) => {
      status = 'error';
      result = e;
    }
  );
  return {
    read() {
      if (status === 'pending') {
        throw suspender;
      } else if (status === 'error') {
        throw result;
      } else if (status === 'success') {
        return result;
      }
    },
  };
}

const cache = new Map<string, any>();

function getSuspender<T>(
  rep: Replicache,
  query: (tx: ReadTransaction) => Promise<T>,
  keys: Array<any>
) {
  const key = JSON.stringify(keys);
  const existing = cache.get(key);
  if (existing) return existing;
  const suspender = wrapPromise(rep.query(query));
  cache.set(key, suspender);
  return suspender;
}

/**
 * React Suspense integration for Replicache.
 * Taken and modified from https://gist.github.com/dpeek/030270187e49de7116cad3ccf2ea030f.
 * Hopefully built into Replicache some day - see https://github.com/rocicorp/replicache-react/issues/19.
 *
 * @param rep Replicache instance
 * @param query Query function to run.
 * The caller does not need to memoize this function -
 * useSuspendSubscribe memoizes it based on the combined set of keys and deps.
 * @param keys A set of keys which, along with `deps`, uniquely identifies the query.
 * @param deps useCallback dependences for `query`.
 */
export function useSuspendSubscribe<R>(
  rep: Replicache,
  query: (tx: ReadTransaction) => Promise<R>,
  keys: Array<any>,
  deps: Array<any>
): R {
  const fullKeys = [...keys, ...deps];

  const cachedQuery = useCallback(query, fullKeys);
  const [snapshot, setSnapshot] = useState(() =>
    getSuspender(rep, cachedQuery, fullKeys).read()
  );

  useEffect(() => {
    return rep.subscribe(cachedQuery, {
      onData: (data: any) => {
        if (data === snapshot) {
          return;
        }
        const updatedSuspender = wrapPromise(Promise.resolve(data));
        cache.set(JSON.stringify(fullKeys), updatedSuspender);
        callbacks.push(() => setSnapshot(data));
        if (!hasPendingCallback) {
          void Promise.resolve().then(doCallback);
          hasPendingCallback = true;
        }
      },
    });
  }, [rep, cachedQuery]);

  return snapshot;
}
