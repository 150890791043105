import { OrganizationPreference } from '@/models/organization-preference';
import { WriteTransaction } from 'replicache';

export default async function projectVisited(
  tx: WriteTransaction,
  args: {
    organizationPreferenceId: string;
    userId: string;
    projectId: string;
    organizationId: string;
    now: string;
  }
) {
  const op = await OrganizationPreference.get(
    tx,
    args.organizationPreferenceId
  );

  if (op) {
    const ids = JSON.parse(op.recentProjectIdListJson);
    const newIds = [args.projectId, ...ids].slice(0, 10);
    await OrganizationPreference.set(tx, {
      ...op,
      recentProjectIdListJson: JSON.stringify(newIds),
      updatedAt: args.now,
    });
  } else {
    await OrganizationPreference.set(tx, {
      id: args.organizationPreferenceId,
      organizationId: args.organizationId,
      userId: args.userId,
      recentProjectIdListJson: JSON.stringify([args.projectId]),
      createdAt: args.now,
      updatedAt: args.now,
    });
  }
}
