import { DrawingSetPage } from '@/models/drawing-set-page';
import { WriteTransaction } from 'replicache';

export async function setDrawingSetPageMeasure(
  tx: WriteTransaction,
  args: {
    id: string;
    updatedAt: string;
    pageMeasure: number | null | undefined;
    realMeasure: number | null | undefined;
    measureConversion: string | null | undefined;
    measureShape: string | null | undefined;
    roundToNearest: string | null | undefined;
  }
) {
  const page = await DrawingSetPage.mustGet(tx, args.id);
  await DrawingSetPage.set(tx, {
    ...page,
    updatedAt: args.updatedAt,
    pageMeasure: args.pageMeasure,
    realMeasure: args.realMeasure,
    measureConversion: args.measureConversion,
    measureShape: args.measureShape,
    roundToNearest: args.roundToNearest,
  });
}
