import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ObjectPool } from './object-pool';

export class Permission extends ApplicationRecord {
  id: string;
  rpa: string;
  role: string;
  policy: string;
  action: string;
  description: string;
  policyLabel: string;

  static prefix = 'permissions' as const;

  static schema = z.object({
    id: z.string(),
    rpa: z.string(),
    role: z.string(),
    policy: z.string(),
    action: z.string(),
    description: z.string(),
    policyLabel: z.string(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.rpa = this.attribute('rpa');
    this.role = this.attribute('role');
    this.policy = this.attribute('policy');
    this.action = this.attribute('action');
    this.description = this.attribute('description');
    this.policyLabel = this.attribute('policyLabel');
  }

  get policyAction() {
    return `${this.policy}.${this.action}`;
  }
}
