import { DrawingDiscipline } from '@/models/drawing-discipline';
import { DrawingDisciplineAbbreviation } from '@/models/drawing-discipline-abbreviation';
import { WriteTransaction } from 'replicache';

export const createDrawingDiscipline = async (
  tx: WriteTransaction,
  {
    id,
    organizationId,
    projectId,
    name,
    position,
    abbreviations,
    now,
  }: {
    id: string;
    organizationId: string;
    projectId: string;
    name: string;
    position: number;
    abbreviations: { id: string; name: string }[];
    now: string;
  }
) => {
  await DrawingDiscipline.set(tx, {
    id,
    organizationId,
    projectId,
    name,
    position,
    createdAt: now,
    updatedAt: now,
  });

  for (const abbreviation of abbreviations) {
    await DrawingDisciplineAbbreviation.set(tx, {
      id: abbreviation.id,
      drawingDisciplineId: id,
      projectId,
      organizationId,
      name: abbreviation.name,
      createdAt: now,
      updatedAt: now,
    });
  }
};
