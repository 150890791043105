import { Topic, TopicStatus } from '@/models/topic';
import { TopicMembership } from '@/models/topic-membership';
import { WriteTransaction } from 'replicache';

export async function createIssue(
  tx: WriteTransaction,
  args: {
    id: string;
    title: string;
    status: TopicStatus;
    companyAccessible: boolean;
    organizationAccessible: boolean;
    createdAt: string;
    updatedAt: string;
    membershipId: string;
    userId: string;
  }
) {
  await Topic.set(tx, { ...args, type: 'topic' });
  await TopicMembership.set(tx, {
    id: args.membershipId,
    issueId: args.id,
    userId: args.userId,
    createdAt: args.createdAt,
    updatedAt: args.updatedAt,
  });
}
