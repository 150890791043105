import { Comment, MediaAttachment } from '@/models/comment';
import { CommentThread } from '@/models/comment-thread';
import { WriteTransaction } from 'replicache';

export async function startCommentThread(
  tx: WriteTransaction,
  {
    discussionId,
    commentThreadId,
    commentId,
    commenterId,
    commentContent,
    commentCreatedAt,
    mediaAttachments,
  }: {
    discussionId: string;
    commentThreadId: string;
    commentId: string;
    commenterId: string;
    commentContent: string;
    commentCreatedAt: string;
    mediaAttachments: Array<MediaAttachment>;
    signedMediaAttachmentFileIds: Array<string>;
    signedScreenRecordingId: string | null;
  }
) {
  await CommentThread.set(tx, {
    id: commentThreadId,
    discussionId,
    createdAt: commentCreatedAt,
    updatedAt: commentCreatedAt,
  });

  await Comment.set(tx, {
    id: commentId,
    content: commentContent,
    commentThreadId,
    commenterId,
    mediaAttachments,
    screenRecordingInfo: null,
    createdAt: commentCreatedAt,
    updatedAt: commentCreatedAt,
  });
}
