import { WriteTransaction } from 'replicache';

export async function updateMyEmail(
  tx: WriteTransaction,
  args: {
    userId: string;
    email: string;
    updatedAt: string;
  }
) {}
