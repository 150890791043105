import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { DailyLog } from './daily-log';
import { ObjectPool } from './object-pool';

export class DailyLogPersonnelEntry extends ApplicationRecord {
  id: string;
  dailyLogId: string;
  workerCount: number;
  hoursWorked: number;
  companyId: string;
  notes: string;
  createdAt: string;
  updatedAt: string;

  static prefix = 'dailyLogPersonnelEntries' as const;

  static schema = z.object({
    id: z.string(),
    dailyLogId: z.string(),
    workerCount: z.number(),
    hoursWorked: z.coerce.number(),
    companyId: z.string(),
    notes: z.string(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.dailyLogId = this.attribute('dailyLogId');
    this.workerCount = this.attribute('workerCount');
    this.hoursWorked = this.attribute('hoursWorked');
    this.companyId = this.attribute('companyId');
    this.notes = this.attribute('notes');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
  }

  get dailyLog() {
    return this.belongsTo(DailyLog, this.dailyLogId);
  }
}
