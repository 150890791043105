import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ChangeOrder } from './change-order';
import { ObjectPool } from './object-pool';
import { PendingAttachment } from './pending-attachment';

export class ChangeOrderAttachment extends ApplicationRecord {
  id: string;
  projectId: string;
  changeOrderId: string;
  fileName: string | null | undefined;
  fileUrl: string | null | undefined;
  createdAt: string;
  updatedAt: string;

  static prefix = 'changeOrderAttachments' as const;

  static schema = z.object({
    id: z.string(),
    projectId: z.string(),
    changeOrderId: z.string(),
    fileName: z.string().nullish(),
    fileUrl: z.string().nullish(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.projectId = this.attribute('projectId');
    this.changeOrderId = this.attribute('changeOrderId');
    this.fileUrl = this.attribute('fileUrl');
    this.fileName = this.attribute('fileName');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
  }

  get changeOrder() {
    return this.belongsTo(ChangeOrder, this.changeOrderId);
  }

  get pendingAttachment() {
    return this.hasOne(PendingAttachment, 'recordId');
  }
}
