import invariant from '@/lib/invariant';
import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ObjectPool } from './object-pool';
import { Organization } from './organization';
import { User } from './user';

export const organizationMembershipRoles = [
  'tombstone',
  'manager',
  'admin',
] as const;

export type OrganizationMembershipRole =
  (typeof organizationMembershipRoles)[number];

export const organizationMembershipRoleDisplay = {
  manager: 'Manager - Internal',
  admin: 'Admin - Internal',
  tombstone: 'Deactivated',
};

export class OrganizationMembership extends ApplicationRecord {
  id: string;
  userId: string;
  organizationId: string;
  role: OrganizationMembershipRole;
  createdAt: string;
  updatedAt: string;

  static prefix = 'organizationMemberships' as const;

  static schema = z.object({
    id: z.string(),
    userId: z.string(),
    organizationId: z.string(),
    role: z.enum(organizationMembershipRoles),
    createdAt: z.string(),
    updatedAt: z.string(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.userId = this.attribute('userId');
    this.organizationId = this.attribute('organizationId');
    this.role = this.attribute('role');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
  }

  get humanizedRole() {
    const res = organizationMembershipRoleDisplay[this.role];
    invariant(res, 'Invalid organization membership role');
    return res;
  }

  get user() {
    const u = this.belongsToOptional(User, this.userId);
    invariant(u, 'No user for an organization membership');
    return u;
  }

  get organization() {
    return this.belongsToOptional(Organization, this.organizationId);
  }

  get companyOrOrganization() {
    invariant(
      this.organization,
      'No organization for an organization membership'
    );

    return this.organization;
  }

  get typeOfCompanyOrOrganization() {
    return this.companyOrOrganization.type;
  }

  get active() {
    return this.role !== 'tombstone';
  }
}
