import { Rfi } from '@/models/rfi';
import { RfiRespondent } from '@/models/rfi-respondent';
import { RfiResponse } from '@/models/rfi-response';
import { RfiSubscriber } from '@/models/rfi-subscriber';
import { uniq } from 'lodash';
import { WriteTransaction } from 'replicache';

export default async function updateRfiOverview(
  tx: WriteTransaction,
  args: {
    id: string;
    now: string;
    dueOn: string | null;
    managerUserId: string;
    subscribers: { id: string; userId: string }[];
    respondents: { id: string; userId: string }[];
  }
) {
  const rfi = await Rfi.get(tx, args.id);
  if (!rfi) return;

  await Rfi.set(tx, {
    ...rfi,
    dueOn: args.dueOn,
    managerUserId: args.managerUserId,
    updatedAt: args.now,
  });

  const rfiSubscribers = (await RfiSubscriber.list(tx)).filter(
    (x) => x.rfiId === args.id
  );
  const subscriberIds = uniq([
    ...rfiSubscribers.map((x) => x.id),
    ...args.subscribers.map((x) => x.id),
  ]);
  for (const id of subscriberIds) {
    const before = rfiSubscribers.find((x) => x.id === id);
    const after = args.subscribers.find((x) => x.id === id);

    if (before && after) {
      await RfiSubscriber.set(tx, {
        ...before,
        userId: after.userId,
        updatedAt: args.now,
      });
    } else if (before) {
      await RfiSubscriber.delete(tx, before.id);
    } else if (after) {
      await RfiSubscriber.set(tx, {
        id: after.id,
        rfiId: args.id,
        userId: after.userId,
        createdAt: args.now,
        updatedAt: args.now,
      });
    }
  }

  const rfiResponses = (await RfiResponse.list(tx)).filter(
    (x) => x.rfiId === args.id
  );
  const rfiRespondents = (await RfiRespondent.list(tx)).filter(
    (x) => x.rfiId === args.id
  );
  const respondentIds = uniq([
    ...rfiRespondents.map((x) => x.id),
    ...args.respondents.map((x) => x.id),
  ]);
  for (const id of respondentIds) {
    const before = rfiRespondents.find((x) => x.id === id);
    const after = args.respondents.find((x) => x.id === id);

    const response = rfiResponses.find((x) => x.respondentId === id);
    if (response) continue;

    if (before && after) {
      await RfiRespondent.set(tx, {
        ...before,
        userId: after.userId,
        updatedAt: args.now,
      });
    } else if (before) {
      await RfiRespondent.delete(tx, before.id);
    } else if (after) {
      await RfiRespondent.set(tx, {
        id: after.id,
        rfiId: args.id,
        userId: after.userId,
        createdAt: args.now,
        updatedAt: args.now,
      });
    }
  }
}
