import { Topic } from '@/models/topic';
import { TopicMembership } from '@/models/topic-membership';
import { WriteTransaction } from 'replicache';

export async function newEditorCreateIssue(
  tx: WriteTransaction,
  args: {
    id: string;
    title: string;
    companyAccessible: boolean;
    organizationAccessible: boolean;
    createdAt: string;
    memberships: { id: string; userId: string }[];
  }
) {
  await Topic.set(tx, {
    id: args.id,
    title: args.title,
    companyAccessible: args.companyAccessible,
    organizationAccessible: args.organizationAccessible,
    createdAt: args.createdAt,
    updatedAt: args.createdAt,
    status: 'editing',
    type: 'topic',
  });

  for (const membership of args.memberships) {
    await TopicMembership.set(tx, {
      id: membership.id,
      issueId: args.id,
      userId: membership.userId,
      createdAt: args.createdAt,
      updatedAt: args.createdAt,
    });
  }
}
