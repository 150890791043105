import { InvoiceSubmission } from '@/models/invoice-submission';
import { WriteTransaction } from 'replicache';

export default async function approveInvoiceSubmission(
  tx: WriteTransaction,
  args: { invoiceSubmissionId: string }
) {
  const submission = await InvoiceSubmission.mustGet(
    tx,
    args.invoiceSubmissionId
  );
  await InvoiceSubmission.set(tx, {
    ...submission,
    status: 'approved',
  });

  // TODO: Create journal entries
}
