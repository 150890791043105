import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { CommentThread } from './comment-thread';
import { ObjectPool } from './object-pool';
import { Photo } from './photo';

export class Discussion extends ApplicationRecord {
  id: string;
  discussableType: string;
  discussableId: string;
  createdAt: string;
  updatedAt: string;

  static prefix = 'discussions' as const;

  static schema = z.object({
    id: z.string(),
    discussableType: z.string(),
    discussableId: z.string(),
    createdAt: z.string().datetime({ offset: true }),
    updatedAt: z.string().datetime({ offset: true }),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.discussableType = this.attribute('discussableType');
    this.discussableId = this.attribute('discussableId');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
  }

  get commentThreads() {
    return this.hasMany(CommentThread, 'discussionId');
  }

  get photos() {
    return this.hasMany(Photo, 'discussionId');
  }
}
