import { join } from '@/lib/file-utils';
import { WriteTransaction } from 'replicache';

import { Document } from '../models/document';
import { Folder } from '../models/folder';
import { UsersFoldersGrant } from '../models/users-folders-grant';

export async function addFolder(
  tx: WriteTransaction,
  args: {
    folderId: string;
    documentId: string;
    grantId: string;
    userId: string;
    path: string;
    now: string;
    organizationGrant: boolean;
    projectGrant: boolean;
    shareWithUserIds: { grantId: string; userId: string }[];
  }
) {
  await Folder.set(tx, {
    id: args.folderId,
    path: join(args.path),
    organizationGrant: args.organizationGrant,
    projectGrant: args.projectGrant,
    createdAt: args.now,
    updatedAt: args.now,
  });

  await Document.set(tx, {
    id: args.documentId,
    uploaderId: args.userId,
    path: join([args.path, '.keep']),
    updatedAt: args.now,
    createdAt: args.now,
    deletedAt: null,
    mimeType: 'application/octet-stream',
    sizeBytes: 0,
    modifiedAt: new Date(0).toISOString(),
  });

  // folder creator grant
  await UsersFoldersGrant.set(tx, {
    id: args.grantId,
    folderId: args.folderId,
    userId: args.userId,
    createdAt: args.now,
    updatedAt: args.now,
  });

  // share with users
  for (const grant of args.shareWithUserIds) {
    await UsersFoldersGrant.set(tx, {
      id: grant.grantId,
      folderId: args.folderId,
      userId: grant.userId,
      createdAt: args.now,
      updatedAt: args.now,
    });
  }
}
