import { TopicMembership } from '@/models/topic-membership';
import { WriteTransaction } from 'replicache';

export async function createIssueMembership(
  tx: WriteTransaction,
  args: {
    id: string;
    userId: string;
    issueId: string;
    createdAt: string;
    updatedAt: string;
  }
) {
  await TopicMembership.set(tx, args);
}
