import { DrawingSetPage } from '@/models/drawing-set-page';
import { WriteTransaction } from 'replicache';

export async function unconfirmDrawingSetPage(
  tx: WriteTransaction,
  { id }: { id: string }
) {
  const page = await DrawingSetPage.mustGet(tx, id);
  await DrawingSetPage.set(tx, {
    ...page,
    state: 'awaiting_confirmation',
  });
}
