import { startsWithDir } from '@/lib/file-utils';
import { WriteTransaction } from 'replicache';
import { Document } from '../models/document';

export async function deleteFolder(
  tx: WriteTransaction,
  args: {
    prefix: string;
    deletedAt: string;
  }
) {
  const docs = await Document.list(tx);
  for (const doc of docs) {
    if (startsWithDir(doc.path, args.prefix)) {
      await Document.delete(tx, doc.id);
    }
  }
}
