import { basename } from '@/lib/file-utils';
import { File, FileImage, FileText, FileVideo, Folder } from 'lucide-react';
import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { DocumentPage } from './document-page';
import { ObjectPool } from './object-pool';
import { User } from './user';

export class Document extends ApplicationRecord {
  id: string;
  uploaderId: string;
  path: string;
  mimeType?: string | null;
  sizeBytes?: number | null;
  modifiedAt?: string | null;
  updatedAt: string;
  createdAt: string;
  deletedAt?: string | null;
  fileUrl?: string | null;
  thumbnailUrl?: string | null;

  static prefix = 'documents' as const;

  static schema = z.object({
    id: z.string(),
    uploaderId: z.string(),
    path: z.string(),
    mimeType: z.string().nullish(),
    sizeBytes: z.number().nullish(),
    modifiedAt: z.string().datetime().nullish(),
    updatedAt: z.string().datetime(),
    createdAt: z.string().datetime(),
    deletedAt: z.string().datetime().nullish(),
    fileUrl: z.string().nullish(),
    thumbnailUrl: z.string().nullish(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.uploaderId = this.attribute('uploaderId');
    this.path = this.attribute('path');
    this.mimeType = this.attribute('mimeType');
    this.sizeBytes = this.attribute('sizeBytes');
    this.modifiedAt = this.attribute('modifiedAt');
    this.updatedAt = this.attribute('updatedAt');
    this.createdAt = this.attribute('createdAt');
    this.deletedAt = this.attribute('deletedAt');
    this.fileUrl = this.attribute('fileUrl');
    this.thumbnailUrl = this.attribute('thumbnailUrl');
  }

  get uploader() {
    return this.belongsTo(User, this.uploaderId);
  }

  get fileName() {
    return basename(this.path);
  }

  get isKeep() {
    return this.fileName == '.keep';
  }

  get isDownloadable() {
    if (this.isKeep) return false;
    if (this.isDeleted) return false;
    return !!this.fileUrl;
  }

  get isDeleted() {
    return !!this.deletedAt;
  }

  get depth() {
    return this.path.split('/').length - 1;
  }

  get Icon() {
    if (this.isKeep || this.mimeType === 'inode/directory') return Folder;
    if (this.mimeType === 'application/pdf') return FileText;
    if (this.mimeType?.startsWith('image')) return FileImage;
    if (this.mimeType?.startsWith('video')) return FileVideo;

    return File;
  }

  get pages() {
    return this.hasMany(DocumentPage, 'documentId');
  }
}
