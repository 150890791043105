import { useEffect } from 'react';
import { ConstructableLogo } from '../constructable-logo';

export type LogoScreenProps = {
  message?: string;
  alertAfterMs?: number;
  disableAlert?: boolean;
};

export function LogoScreen({
  message,
  alertAfterMs = 10 * 1000,
  disableAlert,
}: LogoScreenProps) {
  console.log(`Showing logo screen: ${message || '<no message>'}`);

  useEffect(() => {
    const start = new Date().getTime();

    if (!disableAlert) {
      const timeoutId = setTimeout(() => {
        const now = new Date().getTime();

        const fiveMinutes = 60 * 5000;
        if (now - start < fiveMinutes) {
          const message = `Logo screen has been showing for longer than ${alertAfterMs} ms`;
          console.error(message);
        }
      }, alertAfterMs);
      return () => clearTimeout(timeoutId);
    }
  }, [alertAfterMs, disableAlert]);

  return (
    <div className="h-[100vh] w-[100vw] flex flex-col items-center justify-center relative">
      <div className="flex flex-col items-center gap-2">
        <div className="animate-pulse">
          <ConstructableLogo />
        </div>
        <div className="flex flex-col items-center gap-1">
          <div className="text-sm text-muted-foreground">Constructable</div>
        </div>
      </div>
      {message && (
        <div className="absolute bottom-0 w-full text-center pb-safe-offset-2 text-xs font-thin text-muted-foreground">
          {message}
        </div>
      )}
    </div>
  );
}
