import { DailyLogPersonnelEntry } from '@/models/daily-log-personnel-entry';
import { WriteTransaction } from 'replicache';

export async function putDailyLogPersonnelEntry(
  tx: WriteTransaction,
  args: {
    id: string;
    dailyLogId: string;
    workerCount: number;
    hoursWorked: number;
    companyId: string;
    notes: string;
    createdAt: string;
    updatedAt: string;
  }
) {
  await DailyLogPersonnelEntry.set(tx, args);
}
