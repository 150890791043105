import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import JournalEntry from './journal-entry';
import { ObjectPool } from './object-pool';

export default class Account extends ApplicationRecord {
  static prefix = 'accounts' as const;

  id: string;
  code: string;
  name: string;
  type: string;
  organizationId: string;

  static schema = z.object({
    id: z.string(),
    code: z.string(),
    name: z.string(),
    type: z.string(),
    organizationId: z.string(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.code = this.attribute('code');
    this.name = this.attribute('name');
    this.type = this.attribute('type');
    this.organizationId = this.attribute('organizationId');
  }

  static keyAccounts(objectPool: ObjectPool) {
    const accounts = objectPool.all(Account);
    return {
      // Assets
      contractReceivables: accounts.find((account) => account.code === '1122')!,
      retentionReceivables: accounts.find(
        (account) => account.code === '1123'
      )!,

      // Liabilities
      subcontractorPayables: accounts.find(
        (account) => account.code === '2111'
      )!,
      retentionPayables: accounts.find((account) => account.code === '2112')!,

      // Revenue
      contractRevenue: accounts.find((account) => account.code === '4110')!,

      // Expenses
      subcontractorExpenses: accounts.find(
        (account) => account.code === '5110'
      )!,
    };
  }

  get journalEntries() {
    return this.hasMany(JournalEntry, 'accountId');
  }
}
