import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import JournalCommit from './journal-commit';
import { ObjectPool } from './object-pool';
import { SubProject } from './sub-project';

export default class InvoiceLineItem extends ApplicationRecord {
  static prefix = 'invoiceLineItems' as const;

  id: string;
  description?: string | null | undefined;
  createdAt: string;
  costCodeId: string;
  organizationId: string;
  invoiceId: string;
  journalCommitId: string;
  subProjectId?: string | null | undefined;

  static schema = z.object({
    id: z.string(),
    description: z.string().nullish(),
    createdAt: z.string().datetime(),
    costCodeId: z.string(),
    invoiceId: z.string(),
    journalCommitId: z.string(),
    organizationId: z.string(),
    subProjectId: z.string().nullish(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.description = this.attribute('description');
    this.createdAt = this.attribute('createdAt');
    this.costCodeId = this.attribute('costCodeId');
    this.invoiceId = this.attribute('invoiceId');
    this.journalCommitId = this.attribute('journalCommitId');
    this.organizationId = this.attribute('organizationId');
    this.subProjectId = this.attribute('subProjectId');
  }

  get journalCommit() {
    return this.belongsTo(JournalCommit, this.journalCommitId);
  }

  get journalEntries() {
    return this.journalCommit.journalEntries;
  }

  get subProject() {
    return this.belongsToOptional(SubProject, this.subProjectId);
  }
}
