import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ObjectPool } from './object-pool';
import { PendingAttachment } from './pending-attachment';
import { PhotoAlbum } from './photo-album';
import { User } from './user';

export class Photo extends ApplicationRecord {
  id: string;
  uploaderId: string;
  photoAlbumId?: string | null | undefined;
  discussionId?: string | null | undefined;
  fileUrl?: string | null | undefined;
  thumbnailUrl?: string | null | undefined;
  fileName?: string | null | undefined;
  createdAt: string;
  capturedAt?: string | null | undefined;

  static prefix = 'photos' as const;

  static schema = z.object({
    id: z.string(),
    uploaderId: z.string(),
    photoAlbumId: z.string().nullish(),
    discussionId: z.string().nullish(),
    fileUrl: z.string().nullish(),
    thumbnailUrl: z.string().nullish(),
    fileName: z.string().nullish(),
    createdAt: z.string().datetime(),
    capturedAt: z.string().datetime().nullish(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.uploaderId = this.attribute('uploaderId');
    this.photoAlbumId = this.attribute('photoAlbumId');
    this.discussionId = this.attribute('discussionId');
    this.fileUrl = this.attribute('fileUrl');
    this.thumbnailUrl = this.attribute('thumbnailUrl');
    this.fileName = this.attribute('fileName');
    this.createdAt = this.attribute('createdAt');
    this.capturedAt = this.attribute('capturedAt');
  }

  get src() {
    return this.thumbnailUrl || this.fileUrl;
  }

  get pendingFileName() {
    return this.fileName || this.pendingAttachment?.fileName;
  }

  get photoAlbum() {
    return this.belongsToOptional(PhotoAlbum, this.photoAlbumId);
  }

  get uploader() {
    return this.belongsToOptional(User, this.uploaderId);
  }

  get pendingAttachment() {
    return this.hasOne(PendingAttachment, 'recordId');
  }

  get isPending() {
    const notFromPhoto = !this.thumbnailUrl && !this.fileUrl;
    return notFromPhoto && !!this.pendingAttachment;
  }

  get capturedOrCreatedAt() {
    return this.capturedAt ?? this.createdAt;
  }

  get belongsToAlbum() {
    return !!this.photoAlbumId;
  }
}
