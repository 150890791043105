import { ChangeEvent } from '@/models/change-event';
import { WriteTransaction } from 'replicache';

export async function updateChangeEventOverview(
  tx: WriteTransaction,
  {
    id,
    title,
    description,
    now,
  }: {
    id: string;
    title: string;
    description: string;
    now: string;
  }
) {
  const event = await ChangeEvent.mustGet(tx, id);
  await ChangeEvent.set(tx, {
    ...event,
    title: title,
    description: description,
    updatedAt: now,
  });
}
