import { BillingPeriod } from '@/models/billing-period';
import { WriteTransaction } from 'replicache';

export async function deleteBillingPeriod(
  tx: WriteTransaction,
  {
    id,
  }: {
    id: string;
  }
) {
  await BillingPeriod.delete(tx, id);
}
