import { compact } from 'lodash';
import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { Company } from './company';
import { CompanyMembership } from './company-membership';
import { ObjectPool } from './object-pool';
import { OrganizationMembership } from './organization-membership';
import { Project } from './project';

export const OrganizationType = ['organization'] as const;

export type OrganizationType = (typeof OrganizationType)[number];

export class Organization extends ApplicationRecord {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  url?: string | null | undefined;

  static prefix = 'organizations' as const;

  static schema = z.object({
    id: z.string(),
    name: z.string(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    url: z.string().nullish(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.name = this.attribute('name');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
    this.url = this.attribute('url');
  }

  get projects() {
    return this.hasMany(Project, 'organizationId');
  }

  get organizationMemberships() {
    return this.hasMany(OrganizationMembership, 'organizationId');
  }

  get memberships() {
    return this.organizationMemberships;
  }

  get companyMemberships() {
    return this.hasMany(CompanyMembership, 'organizationId');
  }

  get companies() {
    return this.hasMany(Company, 'organizationId');
  }

  get type() {
    return 'organization' as OrganizationType;
  }

  get humanizedType() {
    return this.name;
  }

  get users() {
    return compact(
      this.organizationMemberships.map((membership) => membership.user)
    );
  }

  get faviconUrl() {
    if (!this.url) return null;

    try {
      return `https://www.google.com/s2/favicons?domain=${new URL(this.url).hostname}&sz=256`;
    } catch (e) {
      return null;
    }
  }

  get organizationId() {
    return this.id;
  }
}
