import { MarkupShape } from '@/models/markup-shape';
import { WriteTransaction } from 'replicache';

export async function putMarkupShape(
  tx: WriteTransaction,
  args: {
    id: string;
    tldrawId: string;
    drawingSetPageId: string;
    issueId: string;
    props: string;
    rotation: number;
    x: number;
    y: number;
    type: string;
    deleted: boolean;
    lastModifiedOnCanvasId?: string;
    createdAt?: string;
    updatedAt: string;
  }
) {
  const existing = await MarkupShape.get(tx, args.id);
  if (existing) {
    await MarkupShape.set(tx, { ...args, createdAt: existing.createdAt });
  } else {
    await MarkupShape.set(tx, { ...args, createdAt: args.updatedAt });
  }
}
