import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import JournalEntry from './journal-entry';
import { ObjectPool } from './object-pool';
import InvoiceLineItem from './invoice-line-item';

export default class JournalCommit extends ApplicationRecord {
  static prefix = 'journalCommits' as const;

  id: string;
  createdAt: string;
  organizationId: string;

  static schema = z.object({
    id: z.string(),
    createdAt: z.string().datetime(),
    organizationId: z.string(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.createdAt = this.attribute('createdAt');
    this.organizationId = this.attribute('organizationId');
  }

  get journalEntries() {
    return this.hasMany(JournalEntry, 'journalCommitId');
  }

  get invoiceLineItem() {
    return this.hasOne(InvoiceLineItem, 'journalCommitId');
  }
}
