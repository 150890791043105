import { PendingAttachment } from '@/models/pending-attachment';
import { WriteTransaction } from 'replicache';

export async function createPendingAttachment(
  tx: WriteTransaction,
  args: {
    id: string;
    recordAttachmentName: string;
    fileName: string;
    state: string;
    recordType: string;
    recordId: string;
    updatedAt: string;
  }
) {
  await PendingAttachment.set(tx, args);
}
