import { InvoiceSubmission } from '@/models/invoice-submission';
import { WriteTransaction } from 'replicache';

export default async function createInvoiceSubmission(
  tx: WriteTransaction,
  args: {
    id: string;
    commitmentId: string;
    billingPeriodId: string;
    createdAt: string;
  }
) {
  await InvoiceSubmission.set(tx, {
    id: args.id,
    commitmentId: args.commitmentId,
    billingPeriodId: args.billingPeriodId,
    createdAt: args.createdAt,
    updatedAt: args.createdAt,
    status: 'draft',
    submittedById: null,
  });
}
