import { floor } from 'lodash';

export const centsToHuman = (cents: number) => {
  return `${new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(floor(cents / 100, 2))}`;
};

export const humanToCents = (human: string) => {
  if (human.trim().length === 0) {
    return 0;
  }

  return Math.round(parseFloat(human.replace(/[$,]/g, '')) * 100);
};
