import { InvoiceSubmissionLineItem } from '@/models/invoice-submission-line-item';
import { WriteTransaction } from 'replicache';

export default async function saveInvoiceSubmissionLineItems(
  tx: WriteTransaction,
  args: {
    invoiceSubmissionId: string;
    projectId: string;
    savedAt: string;
    lineItems: {
      id: string;
      amountCents: number;
      completedPercent: number;
      notes: string;
      retentionPercent: number;
      sovLineItemId: string;
    }[];
  }
) {
  for (const attrs of args.lineItems) {
    let current = await InvoiceSubmissionLineItem.get(tx, attrs.id);
    if (current) {
      await InvoiceSubmissionLineItem.set(tx, {
        ...current,
        ...attrs,
        updatedAt: args.savedAt,
      });
    } else {
      await InvoiceSubmissionLineItem.set(tx, {
        ...attrs,
        createdAt: args.savedAt,
        updatedAt: args.savedAt,
        invoiceSubmissionId: args.invoiceSubmissionId,
        projectId: args.projectId,
      });
    }
  }

  const allLineItems = await InvoiceSubmissionLineItem.list(tx);
  for (const currentLineItem of allLineItems) {
    if (currentLineItem.invoiceSubmissionId !== args.invoiceSubmissionId) {
      continue;
    }

    if (!args.lineItems.find((li) => li.id === currentLineItem.id)) {
      await InvoiceSubmissionLineItem.delete(tx, currentLineItem.id);
    }
  }
}
