import { DrawingSetPage } from '@/models/drawing-set-page';
import { WriteTransaction } from 'replicache';

export async function publishDrawingSetPages(
  tx: WriteTransaction,
  args: { drawingSetPageIds: string[] }
) {
  const idSet = new Set(args.drawingSetPageIds);
  for (const id of idSet) {
    const page = await DrawingSetPage.mustGet(tx, id);
    await DrawingSetPage.set(tx, {
      ...page,
      state: 'published',
    });
  }
}
