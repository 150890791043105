import { compact, last, sortBy, uniq } from 'lodash';
import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ObjectPool } from './object-pool';
import { SubmittalDocument } from './submittal-document';
import { SubmittalGroup } from './submittal-group';
import { SubmittalItem } from './submittal-item';
import { SubmittalStage } from './submittal-stage';
import { SubmittalSubscriber } from './submittal-subscriber';
import { Topic } from './topic';
import { User } from './user';

export const submittalStatuses = ['open', 'done'] as const;
export const submittalStatusLabels = {
  open: 'Open',
  done: 'Done',
};
export type SubmittalStatus = (typeof submittalStatuses)[number];
export const submittalTypes = [
  'shop_drawings',
  'product_details',
  'samples',
  'documents',
] as const;
export type SubmittalType = (typeof submittalTypes)[number];
export const submittalTypeLabels = {
  shop_drawings: 'Shop Drawings',
  product_details: 'Product Details',
  samples: 'Samples',
  documents: 'Documents',
};
export class Submittal extends ApplicationRecord {
  id: string;
  description?: string | null | undefined;
  number: number;
  status: SubmittalStatus;
  title: string;
  type?: SubmittalType | null | undefined;
  createdAt: string;
  updatedAt: string;
  contractorUserId?: string | null | undefined;
  managerUserId: string;
  submittalGroupId?: string | null | undefined;
  topicId: string;
  currentStageId?: string | null | undefined;
  parentSubmittalId?: string | null | undefined;

  static prefix = 'submittals' as const;

  static schema = z.object({
    id: z.string(),
    description: z.string().nullish(),
    number: z.number().positive(),
    status: z.enum(submittalStatuses),
    title: z.string(),
    type: z.enum(submittalTypes).nullish(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    contractorUserId: z.string().nullish(),
    managerUserId: z.string(),
    submittalGroupId: z.string().nullish(),
    topicId: z.string(),
    currentStageId: z.string().nullish(),
    parentSubmittalId: z.string().nullish(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.description = this.attribute('description');
    this.number = this.attribute('number');
    this.status = this.attribute('status');
    this.title = this.attribute('title');
    this.type = this.attribute('type');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
    this.contractorUserId = this.attribute('contractorUserId');
    this.managerUserId = this.attribute('managerUserId');
    this.submittalGroupId = this.attribute('submittalGroupId');
    this.topicId = this.attribute('topicId');
    this.currentStageId = this.attribute('currentStageId');
    this.parentSubmittalId = this.attribute('parentSubmittalId');
  }

  get contractorUser() {
    return this.belongsToOptional(User, this.contractorUserId);
  }

  get managerUser() {
    return this.belongsTo(User, this.managerUserId);
  }

  get subscribers() {
    return this.hasMany(SubmittalSubscriber, 'submittalId');
  }

  get subscriberUsers() {
    return this.subscribers.flatMap((s) => s.user);
  }

  get currentStage() {
    return (
      this.stages.find((s) => !s.rejected && !s.approved) || this.stages[0]
    );
  }

  get topic() {
    return this.belongsTo(Topic, this.topicId);
  }

  get submittalGroup() {
    return this.belongsToOptional(SubmittalGroup, this.submittalGroupId);
  }

  get items() {
    return this.hasMany(SubmittalItem, 'submittalId');
  }

  get stages() {
    return sortBy(this.hasMany(SubmittalStage, 'submittalId'), 'createdAt');
  }

  get approvers() {
    return this.stages.flatMap((s) => s.approvers);
  }

  get approversUsers() {
    return uniq(this.approvers.map((a) => a.user));
  }

  get documents() {
    return this.hasMany(SubmittalDocument, 'submittalId');
  }

  get rejected() {
    if (this.stages.length === 0) return false;

    return this.stages.some((s) => s.rejected);
  }

  get approved() {
    if (this.rejected) return false;
    if (this.stages.length === 0) return false;

    return this.stages.every((s) => s.approved);
  }

  get readyToDistribute() {
    return this.approved && this.status === 'open';
  }

  get isDone() {
    return this.status === 'done';
  }

  get approvedAt() {
    return compact(
      this.stages
        .flatMap((s) => s.approvers)
        .filter((a) => a.approved)
        .map((a) => a.respondedAt)
    )
      .sort()
      .reverse()[0];
  }

  get finalDocuments() {
    if (!this.approved) return [];

    return compact(
      (last(this.stages)?.approvers || []).flatMap((a) => a.documents)
    );
  }

  get parentSubmittal() {
    return this.belongsToOptional(Submittal, this.parentSubmittalId);
  }

  get nextRevision() {
    return this.hasOne(Submittal, 'parentSubmittalId');
  }

  get originalSubmittal(): Submittal {
    if (!this.parentSubmittal) {
      return this;
    } else {
      return this.parentSubmittal.originalSubmittal;
    }
  }

  get orderedRevisions(): Submittal[] {
    const original = this.originalSubmittal;
    const revisions = [original];
    let current = original;
    while (current.nextRevision) {
      revisions.push(current.nextRevision);
      current = current.nextRevision;
    }
    return revisions;
  }

  get hasRevisions(): boolean {
    return this.orderedRevisions.length > 1;
  }

  get latestRevision(): Submittal {
    return this.orderedRevisions[this.orderedRevisions.length - 1]!;
  }

  get isLatestRevision(): boolean {
    return this.id === this.latestRevision.id;
  }

  get revisionNumber(): number {
    if (!this.parentSubmittal) {
      return 0;
    } else {
      return this.parentSubmittal.revisionNumber + 1;
    }
  }
}
