import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ObjectPool } from './object-pool';
import { InvoiceSubmission } from './invoice-submission';
import { PendingAttachment } from './pending-attachment';

export class InvoiceSubmissionAttachment extends ApplicationRecord {
  id: string;
  projectId: string;
  invoiceSubmissionId: string;
  fileName: string | null | undefined;
  fileUrl: string | null | undefined;
  createdAt: string;
  updatedAt: string;

  static prefix = 'invoiceSubmissionAttachments' as const;

  static schema = z.object({
    id: z.string(),
    projectId: z.string(),
    invoiceSubmissionId: z.string(),
    fileName: z.string().nullish(),
    fileUrl: z.string().nullish(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.projectId = this.attribute('projectId');
    this.invoiceSubmissionId = this.attribute('invoiceSubmissionId');
    this.fileUrl = this.attribute('fileUrl');
    this.fileName = this.attribute('fileName');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
  }

  get invoiceSubmission() {
    return this.belongsTo(InvoiceSubmission, this.invoiceSubmissionId);
  }

  get pendingAttachment() {
    return this.hasOne(PendingAttachment, 'recordId');
  }
}
