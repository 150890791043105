import { ReadonlyJSONObject } from '@rocicorp/rails';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ObjectPool } from './object-pool';

export class MarkupSession extends ApplicationRecord {
  id: string;
  shapesCreated: string[];
  shapesDeleted: string[];
  shapesUpdated: string[];
  userId: string;
  issueId: string;
  createdAt: string;
  updatedAt: string;

  static prefix = 'markupSessions' as const;

  static schema = z.object({
    id: z.string(),
    shapesCreated: z.array(z.string()),
    shapesDeleted: z.array(z.string()),
    shapesUpdated: z.array(z.string()),
    userId: z.string(),
    issueId: z.string(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.shapesCreated = this.attribute('shapesCreated');
    this.shapesDeleted = this.attribute('shapesDeleted');
    this.shapesUpdated = this.attribute('shapesUpdated');
    this.userId = this.attribute('userId');
    this.issueId = this.attribute('issueId');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
  }
}
