import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ChangeOrder } from './change-order';
import { Company } from './company';
import { InvoiceSubmission } from './invoice-submission';
import { ObjectPool } from './object-pool';
import { Project } from './project';
import { SovLineItem } from './sov-line-item';
import CommitmentAttachment from './commitment-attachment';

export const commitmentStatuses = [
  'void',
  'draft',
  'signing',
  'countersigning',
  'executed',
] as const;

export type CommitmentStatus = (typeof commitmentStatuses)[number];

export const commitmentStatusLabels = {
  void: 'Void',
  draft: 'Draft',
  signing: 'Out for Signing',
  countersigning: 'Ready to Countersign',
  executed: 'Fully Executed',
} as const;

export const commitmentStatusVariants = {
  void: 'destructiveOutline',
  draft: 'warningOutline',
  signing: 'warning',
  countersigning: 'primary',
  executed: 'success',
} as const;

export class Commitment extends ApplicationRecord {
  id: string;
  projectId: string;
  companyId: string;
  createdAt: string;
  updatedAt: string;
  status: CommitmentStatus;

  static prefix = 'commitments' as const;

  static schema = z.object({
    id: z.string(),
    projectId: z.string(),
    companyId: z.string(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    status: z.enum(commitmentStatuses),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.projectId = this.attribute('projectId');
    this.companyId = this.attribute('companyId');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
    this.status = this.attribute('status');
  }

  static primeCommitmentsForProject(pool: ObjectPool, project: Project) {
    const commitments = pool
      .all(Commitment)
      .filter((c) => c.isPrime && c.projectId === project.id);

    const memberships = project.projectMemberships
      .filter((m) => m.companyOrOrganization.type === 'owner')
      .filter((m) => m.actuallyActive);

    return commitments.filter((c) => {
      const membershipsForCommitment = memberships.filter(
        (m) => m.companyOrOrganization.id === c.company.id
      );

      return membershipsForCommitment.length > 0;
    });
  }

  get invoiceSubmissions() {
    return this.hasMany(InvoiceSubmission, 'commitmentId');
  }

  get isPrime() {
    return this.company.type === 'owner';
  }

  get isVendor() {
    return this.company.type !== 'owner';
  }

  get project() {
    return this.belongsTo(Project, this.projectId);
  }

  get company() {
    return this.belongsTo(Company, this.companyId);
  }

  get isExecuted() {
    return this.status === 'executed';
  }

  get changeOrders() {
    return this.hasMany(ChangeOrder, 'commitmentId');
  }

  get executedChangeOrders() {
    return this.changeOrders.filter((co) => co.status === 'executed');
  }

  get sovLineItems() {
    return this.hasMany(SovLineItem, 'commitmentId');
  }

  get attachments() {
    return this.hasMany(CommitmentAttachment, 'commitmentId');
  }

  get originalContractSovLineItems() {
    return this.sovLineItems.filter((i) => !i.isFromChangeOrder);
  }

  get hasOriginalContractSovLineItems() {
    return this.originalContractSovLineItems.length > 0;
  }

  get changeOrderSovLineItems() {
    return this.sovLineItems.filter((i) => i.isFromChangeOrder);
  }

  get hasChangeOrderSovLineItems() {
    return this.changeOrderSovLineItems.length > 0;
  }

  get originalContractAmountCents() {
    return this.originalContractSovLineItems.reduce((sum, i) => {
      return sum + i.amountCents;
    }, 0);
  }

  get executedChangeOrdersAmountCents() {
    return this.executedChangeOrders
      .flatMap((co) => co.changeOrderLineItems)
      .reduce((sum, item) => sum + item.amountCents, 0);
  }

  get revisedContractAmountCents() {
    return (
      this.originalContractAmountCents + this.executedChangeOrdersAmountCents
    );
  }

  get invoicedToDateCents() {
    return this.sovLineItems.reduce((sum, i) => {
      return sum + i.invoicedToDateCents;
    }, 0);
  }

  get contractBalanceCents() {
    return this.revisedContractAmountCents - this.invoicedToDateCents;
  }
}
