import type { KVRead, KVStore, KVStoreProvider, KVWrite } from 'replicache';
import type { GenericDatabase, GenericTransaction } from './generic-database';

export class SqliteKvStoreProvider implements KVStoreProvider {
  constructor(private readonly database: GenericDatabase) {
    this.create = this.create.bind(this);
    this.drop = this.drop.bind(this);
  }

  create(name: string) {
    return new SqliteKvStore(this.database, name);
  }

  async drop(name: string) {
    await this.database.dropTable(name);
  }
}

class SqliteKvStore implements KVStore {
  constructor(
    private readonly database: GenericDatabase,
    private readonly name: string
  ) {}

  async read() {
    await this.database.createTable(this.name);
    const tx = await this.database.beginTransaction();
    return new SqliteKvRead(this.name, tx);
  }

  async write() {
    await this.database.createTable(this.name);
    const tx = await this.database.beginTransaction();
    return new SqliteKvWrite(this.name, tx);
  }

  async close() {
    this.closed = true;
  }

  closed = false;
}

class SqliteKvRead implements KVRead {
  constructor(
    private readonly tableName: string,
    private readonly transaction: GenericTransaction
  ) {}

  async has(key: string) {
    const result = await this.transaction.query(
      `select count(*) as count from "${this.tableName}" where key = ?`,
      [key]
    );
    return result[0].count > 0;
  }

  async get(key: string) {
    const result = await this.transaction.query(
      `select value from "${this.tableName}" where key = ?`,
      [key]
    );
    const value = result[0]?.value;
    if (!value) return value;
    try {
      return deepFreeze(JSON.parse(value));
    } catch {
      return deepFreeze(value);
    }
  }

  closed = false;

  async release() {
    await this.transaction.commit();
  }
}

function deepFreeze(object: any) {
  if (typeof object !== 'object' || object === null) {
    return object;
  }
  // Retrieve the property names defined on object
  const propNames = Reflect.ownKeys(object);

  // Freeze properties before freezing self
  for (const name of propNames) {
    const value = object[name];

    if ((value && typeof value === 'object') || typeof value === 'function') {
      deepFreeze(value);
    }
  }

  return Object.freeze(object);
}

class SqliteKvWrite implements KVWrite {
  private read: KVRead;

  constructor(
    private readonly tableName: string,
    private readonly transaction: GenericTransaction
  ) {
    this.read = new SqliteKvRead(tableName, transaction);
  }

  async has(key: string) {
    return this.read.has(key);
  }

  async get(key: string) {
    return this.read.get(key);
  }

  closed = false;

  async release() {
    await this.transaction.commit();
  }

  async put(key: string, value: any) {
    await this.transaction.query(
      `insert into "${this.tableName}" (key, value) values (?, ?) on conflict(key) do update set value = ?`,
      [key, JSON.stringify(value), JSON.stringify(value)]
    );
  }

  async del(key: string) {
    await this.transaction.query(
      `delete from "${this.tableName}" where key = ?`,
      [key]
    );
  }

  async commit() {}
}
