import { ChangeOrder, ChangeOrderStatus } from '@/models/change-order';
import { ChangeOrderLineItem } from '@/models/change-order-line-item';
import { SovLineItem } from '@/models/sov-line-item';
import { WriteTransaction } from 'replicache';

export default async function changeChangeOrderStatus(
  tx: WriteTransaction,
  {
    id,
    newStatus,
    now,
    orderSovIdMapping,
  }: {
    id: string;
    newStatus: ChangeOrderStatus;
    now: string;
    orderSovIdMapping: { [id: string]: string };
  }
) {
  const order = await ChangeOrder.mustGet(tx, id);

  if (order.status === newStatus) return;
  if (order.status === 'executed') return;

  await ChangeOrder.set(tx, {
    ...order,
    status: newStatus,
    updatedAt: now,
  });

  if (newStatus === 'executed') {
    const itemsToCopy = (await ChangeOrderLineItem.list(tx)).filter(
      (x) => x.changeOrderId === id
    );

    for (const coli of itemsToCopy) {
      await SovLineItem.set(tx, {
        id: coli.id,
        amountCents: coli.amountCents,
        amountCurrency: coli.amountCurrency,
        description: coli.description,
        retentionRate: coli.retentionRate,
        createdAt: now,
        updatedAt: now,
        commitmentId: coli.commitmentId,
        costCodeId: coli.costCodeId,
        changeOrderLineItemId: coli.id,
        subProjectId: coli.subProjectId,
      });
    }
  }
}
