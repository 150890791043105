import { RfiResponseAttachment } from '@/models/rfi-response-attachment';
import { WriteTransaction } from 'replicache';

export async function createRfiResponseAttachment(
  tx: WriteTransaction,
  args: {
    id: string;
    responseId: string;
    createdAt: string;
  }
) {
  await RfiResponseAttachment.set(tx, args);
}
