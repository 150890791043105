import { humanToCents } from '@/lib/money-utils';
import { humanToRate } from '@/lib/percent-utils';
import { SovLineItem } from '@/models/sov-line-item';
import { WriteTransaction } from 'replicache';

export default async function updateCommitmentOriginalContractSov(
  tx: WriteTransaction,
  {
    commitmentId,
    now,
    sovLineItems,
  }: {
    commitmentId: string;
    now: string;
    sovLineItems: {
      id?: string | null | undefined;
      newId: string;
      costCodeId: string;
      subProjectId?: string | null | undefined;
      description: string;
      amountHuman: string;
      retentionPercent: string;
    }[];
  }
) {
  const sovLineItemsForCommitment = (await SovLineItem.list(tx)).filter(
    (sovLineItem) => sovLineItem.commitmentId === commitmentId
  );

  for (const sovLineItem of sovLineItemsForCommitment) {
    if (sovLineItem.changeOrderLineItemId) continue;

    await SovLineItem.delete(tx, sovLineItem.id);
  }

  for (const sovLineItem of sovLineItems) {
    await SovLineItem.set(tx, {
      id: sovLineItem.id || sovLineItem.newId,
      commitmentId,
      costCodeId: sovLineItem.costCodeId,
      subProjectId: sovLineItem.subProjectId,
      description: sovLineItem.description,
      amountCents: humanToCents(sovLineItem.amountHuman),
      amountCurrency: 'USD',
      retentionRate:
        sovLineItem.retentionPercent.trim() !== ''
          ? humanToRate(sovLineItem.retentionPercent)
          : 0.0,
      createdAt: now,
      updatedAt: now,
    });
  }
}
