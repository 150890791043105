import { DrawingSetPage } from '@/models/drawing-set-page';
import { WriteTransaction } from 'replicache';

export async function confirmDrawingSetPage(
  tx: WriteTransaction,
  args: {
    id: string;
    drawingDisciplineId: string;
    sheetNumber: string;
    sheetDate: string | null;
    title: string;
    updatedAt: string;
  }
) {
  const page = await DrawingSetPage.mustGet(tx, args.id);
  await DrawingSetPage.set(tx, {
    ...page,
    drawingDisciplineId: args.drawingDisciplineId,
    sheetNumber: args.sheetNumber,
    sheetDate: args.sheetDate,
    title: args.title,
    updatedAt: args.updatedAt,
    state: 'awaiting_publish',
  });
}
