import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ObjectPool } from './object-pool';
import { CostCode } from './cost-code';

export class CostCodeGroup extends ApplicationRecord {
  id: string;
  title: string;
  createdAt: string;
  updatedAt: string;

  static prefix = 'costCodeGroups' as const;

  static schema = z.object({
    id: z.string(),
    title: z.string(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.title = this.attribute('title');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
  }

  get costCodes() {
    return this.hasMany(CostCode, 'costCodeGroupId');
  }
}
