import { WriteTransaction } from 'replicache';
import { Folder } from '../models/folder';
import { UsersFoldersGrant } from '../models/users-folders-grant';

export async function shareFolder(
  tx: WriteTransaction,
  args: {
    folderId: string;
    now: string;
    organizationGrant: boolean;
    projectGrant: boolean;
    shareWithUserIds: { grantId: string; userId: string }[];
  }
) {
  const folder = await Folder.mustGet(tx, args.folderId);
  await Folder.set(tx, {
    ...folder,
    organizationGrant: args.organizationGrant,
    projectGrant: args.projectGrant,
  });

  const existingGrants = (await UsersFoldersGrant.list(tx)).filter(
    (x) => x.folderId === args.folderId
  );

  for (const grant of existingGrants) {
    await UsersFoldersGrant.delete(tx, grant.id);
  }

  for (const grant of args.shareWithUserIds) {
    await UsersFoldersGrant.set(tx, {
      id: grant.grantId,
      folderId: args.folderId,
      userId: grant.userId,
      createdAt: args.now,
      updatedAt: args.now,
    });
  }
}
