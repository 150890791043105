import { round } from 'lodash';

export const rateToHuman = (rate: number, decimalPlaces: number = 2) => {
  return `${new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  }).format(round(rate * 100, decimalPlaces))}`;
};

export const humanToRate = (human: string, decimalPlaces: number = 4) => {
  if (human.trim().length === 0) return 0;
  return round(parseFloat(human.replace(/%/g, '')) / 100, decimalPlaces);
};
