import { BillingPeriod } from '@/models/billing-period';
import { WriteTransaction } from 'replicache';

export async function addOrEditBillingPeriod(
  tx: WriteTransaction,
  {
    id,
    startOn,
    endOn,
    dueOn,
    now,
  }: {
    id: string;
    startOn: string;
    endOn: string;
    dueOn: string;
    now: string;
  }
) {
  await BillingPeriod.set(tx, {
    id,
    startOn,
    endOn,
    dueOn,
    createdAt: now,
    updatedAt: now,
  });
}
