import { SyncedFeature } from '@/models/synced-feature';
import { WriteTransaction } from 'replicache';

export default async function syncFeature(
  tx: WriteTransaction,
  args: { id: string; feature: string }
) {
  await SyncedFeature.set(tx, {
    ...args,
    feature: args.feature,
    isSynced: false,
  });
}
