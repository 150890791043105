import { InvoiceSubmission } from '@/models/invoice-submission';
import { WriteTransaction } from 'replicache';

export default async function deleteInvoiceSubmission(
  tx: WriteTransaction,
  args: {
    id: string;
  }
) {
  await InvoiceSubmission.delete(tx, args.id);
}
