import { Notification } from '@/models/notification';
import { WriteTransaction } from 'replicache';

export async function markNotificationAsRead(
  tx: WriteTransaction,
  args: { id: string }
) {
  const notification = await Notification.mustGet(tx, args.id);
  await Notification.set(tx, {
    ...notification,
    readAt: new Date().toISOString(),
  });
}
