import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ObjectPool } from './object-pool';
import { Rfi } from './rfi';
import { RfiRespondent } from './rfi-respondent';
import { RfiResponseAttachment } from './rfi-response-attachment';

export class RfiResponse extends ApplicationRecord {
  id: string;
  content: string;
  rfiId: string;
  respondentId: string;
  acceptedAt?: string | null;
  createdAt: string;
  updatedAt: string;

  static prefix = 'rfiResponses' as const;

  static schema = z.object({
    id: z.string(),
    content: z.string(),
    rfiId: z.string(),
    respondentId: z.string(),
    acceptedAt: z.string().datetime().nullish(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.content = this.attribute('content');
    this.rfiId = this.attribute('rfiId');
    this.respondentId = this.attribute('respondentId');
    this.acceptedAt = this.attribute('acceptedAt');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
  }

  get rfi() {
    return this.belongsTo(Rfi, this.rfiId);
  }

  get respondent() {
    return this.belongsTo(RfiRespondent, this.respondentId);
  }

  get user() {
    return this.respondent.user;
  }

  get responseAttachments() {
    return this.hasMany(RfiResponseAttachment, 'responseId');
  }
}
