import { ReadonlyJSONObject } from '@rocicorp/rails';
import { compact, orderBy, uniqBy } from 'lodash';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { MarkupShape } from './markup-shape';
import { ObjectPool } from './object-pool';
import { Rfi } from './rfi';
import { TopicMembership } from './topic-membership';

export const topicStatuses = [
  'new',
  'draft',
  'editing',
  'converted',
  'archived',
] as const;

export type TopicStatus = (typeof topicStatuses)[number];

export const topicTypes = ['topic', 'punch_list', 'rfi', 'submittal'] as const;

export type TopicType = (typeof topicTypes)[number];

export class Topic extends ApplicationRecord {
  id: string;
  title: string;
  status: TopicStatus;
  type: TopicType;
  companyAccessible: boolean;
  organizationAccessible: boolean;
  createdAt: string;
  updatedAt: string;

  static prefix = 'issues' as const; // one day this will be topics...

  static schema = z.object({
    id: z.string(),
    title: z.string(),
    status: z.enum(topicStatuses),
    type: z.enum(topicTypes),
    companyAccessible: z.boolean(),
    organizationAccessible: z.boolean(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.title = this.attribute('title');
    this.status = this.attribute('status');
    this.type = this.attribute('type');
    this.companyAccessible = this.attribute('companyAccessible');
    this.organizationAccessible = this.attribute('organizationAccessible');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
  }

  static punchListTopic(objectPool: ObjectPool) {
    return objectPool.all(Topic).find((topic) => topic.type === 'punch_list');
  }

  get rfi() {
    return this.hasOne(Rfi, 'topicId');
  }

  get isArchived() {
    return this.status === 'archived';
  }

  get topicMemberships() {
    return this.hasMany(TopicMembership, 'issueId');
  }

  get pages() {
    return uniqBy(
      this.markupShapes.map((x) => x.drawingSetPage),
      (x) => x.sheetNumber
    );
  }

  get commentThreads() {
    return this.filteredCommentThreads([]);
  }

  filteredCommentThreads(tags: string[]) {
    const threads = uniqBy(
      compact(this.commentThreadShapes.map((x) => x.commentThread)),
      'id'
    );

    return threads.filter((thread) => {
      const threadTags = thread.tags.map((t) => t.name);
      return tags.every((tag) => threadTags.includes(tag));
    });
  }

  get commentTags() {
    return uniqBy(
      this.commentThreadShapes.flatMap((x) => x.commentThread?.tags),
      'name'
    );
  }

  get commentThreadShapes() {
    return this.markupShapes.filter((x) => x.type === 'comment');
  }

  get markupShapes() {
    return orderBy(
      this.hasMany(MarkupShape, 'issueId'),
      [
        (x) => x.drawingSetPage.drawingSet.receivedOn,
        (x) => x.drawingSetPage.drawingSet.createdAt,
      ],
      ['desc', 'desc']
    );
  }
}
