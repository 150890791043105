import { Rfi } from '@/models/rfi';
import { WriteTransaction } from 'replicache';

export async function cancelRfi(
  tx: WriteTransaction,
  args: {
    id: string;
  }
) {
  const rfi = await Rfi.mustGet(tx, args.id);

  await Rfi.set(tx, {
    ...rfi,
    status: 'cancelled',
  });
}
