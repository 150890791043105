import { InvoiceSubmissionAttachment } from '@/models/invoice-submission-attachment';
import { WriteTransaction } from 'replicache';

type Args = {
  id: string;
};

export default async function (tx: WriteTransaction, args: Args) {
  await InvoiceSubmissionAttachment.delete(tx, args.id);
}
