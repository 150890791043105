import { DailyLogEquipmentEntry } from '@/models/daily-log-equipment-entry';
import { WriteTransaction } from 'replicache';

export async function putDailyLogEquipmentEntry(
  tx: WriteTransaction,
  args: {
    id: string;
    dailyLogId: string;
    name: string;
    inspectedAt?: string | null | undefined;
    notes: string;
    createdAt: string;
    updatedAt: string;
  }
) {
  await DailyLogEquipmentEntry.set(tx, args);
}
