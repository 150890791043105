import { Question } from '@/models/question';
import { WriteTransaction } from 'replicache';

export async function askQuestion(
  tx: WriteTransaction,
  args: {
    id: string;
    projectId: string;
    content: string;
    createdAt: string;
    updatedAt: string;
  }
) {
  await Question.set(tx, args);
}
