import { ChangeOrderAttachment } from '@/models/change-order-attachment';
import { WriteTransaction } from 'replicache';

type Args = {
  id: string;
};

export default async function (tx: WriteTransaction, args: Args) {
  await ChangeOrderAttachment.delete(tx, args.id);
}
