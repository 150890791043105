import { CommentThreadTag } from '@/models/comment-thread-tag';
import { WriteTransaction } from 'replicache';

export default async function untagCommentThread(
  tx: WriteTransaction,
  {
    commentThreadTagId,
  }: {
    commentThreadTagId: string;
  }
) {
  await CommentThreadTag.delete(tx, commentThreadTagId);
}
