import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ChangeOrder } from './change-order';
import { Commitment } from './commitment';
import { CostCode } from './cost-code';
import { ObjectPool } from './object-pool';
import { SovLineItem } from './sov-line-item';
import { SubProject } from './sub-project';

export class ChangeOrderLineItem extends ApplicationRecord {
  id: string;
  amountCents: number;
  amountCurrency: string;
  description: string;
  retentionRate: number;
  createdAt: string;
  updatedAt: string;
  changeOrderId: string;
  commitmentId: string;
  costCodeId: string;
  organizationId: string;
  projectId: string;
  subProjectId?: string | null | undefined;

  static prefix = 'changeOrderLineItems' as const;

  static schema = z.object({
    id: z.string(),
    amountCents: z.number(),
    amountCurrency: z.string(),
    description: z.string(),
    retentionRate: z.number(),
    createdAt: z.string(),
    updatedAt: z.string(),
    changeOrderId: z.string(),
    commitmentId: z.string(),
    costCodeId: z.string(),
    organizationId: z.string(),
    projectId: z.string(),
    subProjectId: z.string().nullish(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.amountCents = this.attribute('amountCents');
    this.amountCurrency = this.attribute('amountCurrency');
    this.description = this.attribute('description');
    this.retentionRate = this.attribute('retentionRate');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
    this.changeOrderId = this.attribute('changeOrderId');
    this.commitmentId = this.attribute('commitmentId');
    this.costCodeId = this.attribute('costCodeId');
    this.organizationId = this.attribute('organizationId');
    this.projectId = this.attribute('projectId');
    this.subProjectId = this.attribute('subProjectId');
  }

  get changeOrder() {
    return this.belongsTo(ChangeOrder, this.changeOrderId);
  }

  get commitment() {
    return this.belongsTo(Commitment, this.commitmentId);
  }

  get costCode() {
    return this.belongsTo(CostCode, this.costCodeId);
  }

  get subProject() {
    return this.belongsToOptional(SubProject, this.subProjectId);
  }

  get sovLineItem() {
    return this.hasOne(SovLineItem, 'changeOrderLineItemId');
  }

  get invoicedToDateCents() {
    return 0;
  }
}
