import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { CommentThread } from './comment-thread';
import { ObjectPool } from './object-pool';
import { User } from './user';

export const mediaAttachmentSchema = z.object({
  id: z.string().nullish(),
  name: z.string(),
  originalUrl: z.string(),
  largeUrl: z.string().nullish(),
  thumbnailUrl: z.string().nullish(),
});
export type MediaAttachment = z.infer<typeof mediaAttachmentSchema>;

export const screenRecordingInfoSchema = z.object({
  id: z.string(),
  url: z.string().nullish(),
});
export type ScreenRecordingInfo = z.infer<typeof screenRecordingInfoSchema>;

export class Comment extends ApplicationRecord {
  id: string;
  content: string;
  commentThreadId: string;
  commenterId: string;
  mediaAttachments?: MediaAttachment[] | null;
  screenRecordingInfo?: ScreenRecordingInfo | null;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null | undefined;
  editedAt: string | null | undefined;

  static prefix = 'comments' as const;

  static schema = z.object({
    id: z.string(),
    content: z.string(),
    commentThreadId: z.string(),
    commenterId: z.string(),
    mediaAttachments: z.array(mediaAttachmentSchema).nullish(),
    screenRecordingInfo: screenRecordingInfoSchema.nullish(),
    editedAt: z.string().datetime().nullish(),
    deletedAt: z.string().datetime().nullish(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.content = this.attribute('content');
    this.commentThreadId = this.attribute('commentThreadId');
    this.commenterId = this.attribute('commenterId');
    this.mediaAttachments = this.attribute('mediaAttachments');
    this.screenRecordingInfo = this.attribute('screenRecordingInfo');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
    this.deletedAt = this.attribute('deletedAt');
    this.editedAt = this.attribute('editedAt');
  }

  get commentThread() {
    return this.belongsTo(CommentThread, this.commentThreadId);
  }

  get thread() {
    return this.commentThread;
  }

  get commenter() {
    return this.user;
  }

  get user() {
    return this.belongsTo(User, this.commenterId);
  }
}
