import { Topic } from '@/models/topic';
import { WriteTransaction } from 'replicache';

export async function archiveIssue(
  tx: WriteTransaction,
  args: { id: string; updatedAt: string }
) {
  const topic = await Topic.mustGet(tx, args.id);
  await Topic.set(tx, {
    ...topic,
    status: 'archived',
    updatedAt: args.updatedAt,
  });
}
