import { MarkupShape } from '@/models/markup-shape';
import { Topic } from '@/models/topic';
import { WriteTransaction } from 'replicache';

export async function mergeTopics(
  tx: WriteTransaction,
  args: { fromTopicId: string; toTopicId: string; updatedAt: string }
) {
  const fromTopic = await Topic.mustGet(tx, args.fromTopicId);
  const toTopic = await Topic.mustGet(tx, args.toTopicId);
  const shapes = (await MarkupShape.list(tx)).filter(
    (s) => s.issueId === fromTopic.id
  );

  for (const shape of shapes) {
    await MarkupShape.set(tx, {
      ...shape,
      issueId: toTopic.id,
      updatedAt: args.updatedAt,
    });
  }

  await Topic.set(tx, {
    ...fromTopic,
    status: 'new',
    updatedAt: args.updatedAt,
  });
}
