import { DailyLog } from '@/models/daily-log';
import { WriteTransaction } from 'replicache';

export async function putDailyLog(
  tx: WriteTransaction,
  args: {
    id: string;
    date: string;
    notes: string;
    weatherNotes: string;
    createdAt: string;
    updatedAt: string;
    photoAlbumId?: string | null | undefined;
  }
) {
  await DailyLog.set(tx, args);
}
