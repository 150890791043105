import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ObjectPool } from './object-pool';
import { Organization } from './organization';
import { User } from './user';

export class ProjectPreference extends ApplicationRecord {
  id: string;
  createdAt: string;
  updatedAt: string;
  organizationId: string;
  projectId: string;
  userId: string;
  starredSheetNumbersJson: string;

  static prefix = 'projectPreferences' as const;

  static schema = z.object({
    id: z.string(),
    starredSheetNumbersJson: z.string(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    organizationId: z.string(),
    projectId: z.string(),
    userId: z.string(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.starredSheetNumbersJson = this.attribute('starredSheetNumbersJson');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
    this.organizationId = this.attribute('organizationId');
    this.projectId = this.attribute('projectId');
    this.userId = this.attribute('userId');
  }

  get user() {
    return this.belongsTo(User, this.userId);
  }

  get organization() {
    return this.belongsTo(Organization, this.organizationId);
  }

  get starredSheetNumbers() {
    return JSON.parse(this.starredSheetNumbersJson);
  }
}
