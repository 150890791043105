import { RfiResponse } from '@/models/rfi-response';
import { WriteTransaction } from 'replicache';

export async function respondToRfi(
  tx: WriteTransaction,
  args: {
    id: string;
    rfiId: string;
    content: string;
    createdAt: string;
    updatedAt: string;
    respondentId: string;
  }
) {
  await RfiResponse.set(tx, {
    id: args.id,
    rfiId: args.rfiId,
    respondentId: args.respondentId,
    content: args.content,
    createdAt: args.createdAt,
    updatedAt: args.updatedAt,
    acceptedAt: null,
  });
}
