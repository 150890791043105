import { PendingAttachment } from '@/models/pending-attachment';
import { WriteTransaction } from 'replicache';

export async function uploadedPendingAttachment(
  tx: WriteTransaction,
  {
    id,
  }: {
    id: string;
    signedId: string;
  }
) {
  // This mutation differs from the server implementation so that we can still show
  // the pending mutation before we receive the next pull from the server.

  const attachment = await PendingAttachment.get(tx, id);
  if (!attachment) {
    console.warn('uploadedPendingAttachment: attachment not found', { id });
    return;
  }

  await PendingAttachment.set(tx, { ...attachment, state: 'uploaded' });
}
