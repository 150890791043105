import { Company, CompanyType } from '@/models/company';
import { WriteTransaction } from 'replicache';

export default async function createCompany(
  tx: WriteTransaction,
  {
    id,
    name,
    type,
    url,
    now,
    organizationId,
  }: {
    id: string;
    name: string;
    type: string;
    url?: string | null | undefined;
    now: string;
    organizationId: string;
  }
) {
  await Company.set(tx, {
    id,
    name,
    type: type as CompanyType,
    url: url,
    createdAt: now,
    updatedAt: now,
    organizationId: organizationId,
  });
}
