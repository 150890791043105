import invariant from '@/lib/invariant';
import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { Company } from './company';
import { ObjectPool } from './object-pool';
import { Organization } from './organization';
import { User } from './user';

export const companyMembershipRoles = [
  'tombstone',
  'subcontractor',
  'consultant',
  'owner',
] as const;

export type CompanyMembershipRole = (typeof companyMembershipRoles)[number];

export const companyMembershipRoleDisplay = {
  subcontractor: 'Subcontractor - External',
  consultant: 'Consultant - External',
  owner: 'Owner - External',
  tombstone: 'Deactivated',
};

export class CompanyMembership extends ApplicationRecord {
  id: string;
  userId: string;
  companyId: string;
  organizationId: string;
  role: CompanyMembershipRole;
  lastInvitedAt?: string | null | undefined;
  createdAt: string;
  updatedAt: string;

  static prefix = 'companyMemberships' as const;

  static schema = z.object({
    id: z.string(),
    userId: z.string(),
    companyId: z.string(),
    organizationId: z.string(),
    role: z.enum(companyMembershipRoles),
    lastInvitedAt: z.string().datetime().nullish(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.userId = this.attribute('userId');
    this.companyId = this.attribute('companyId');
    this.organizationId = this.attribute('organizationId');
    this.role = this.attribute('role');
    this.lastInvitedAt = this.attribute('lastInvitedAt');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
  }

  get company() {
    return this.belongsTo(Company, this.companyId);
  }

  get companyOrOrganization() {
    invariant(this.company, 'No company for a company membership');

    return this.company;
  }

  get typeOfCompanyOrOrganization() {
    return this.companyOrOrganization.type;
  }

  get user() {
    const u = this.belongsTo(User, this.userId);
    invariant(u, 'No user for a company membership');
    return u;
  }

  get organization() {
    return this.belongsTo(Organization, this.organizationId);
  }

  get active() {
    return this.role !== 'tombstone';
  }

  get humanizedRole() {
    const res = companyMembershipRoleDisplay[this.role];
    invariant(res, 'Invalid company membership role');
    return res;
  }
}
