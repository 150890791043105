import { CommentThread } from '@/models/comment-thread';
import { WriteTransaction } from 'replicache';

export async function reopenCommentThread(
  tx: WriteTransaction,
  {
    commentThreadId,
    updatedAt,
  }: {
    commentThreadId: string;
    updatedAt: string;
  }
) {
  const thread = await CommentThread.mustGet(tx, commentThreadId);
  await CommentThread.set(tx, {
    ...thread,
    resolvedAt: null,
    resolvedById: null,
    updatedAt,
  });
}
