import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ObjectPool } from './object-pool';
import { Project } from './project';

export class SubProject extends ApplicationRecord {
  id: string;
  projectId: string;
  organizationId: string;
  name: string;
  createdAt: string;
  updatedAt: string;

  static prefix = 'subProjects' as const;

  static schema = z.object({
    id: z.string(),
    projectId: z.string(),
    organizationId: z.string(),
    name: z.string(),
    createdAt: z.string(),
    updatedAt: z.string(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.projectId = this.attribute('projectId');
    this.organizationId = this.attribute('organizationId');
    this.name = this.attribute('name');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
  }

  get organization() {
    return this.belongsTo(Project, this.projectId);
  }
}
