import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ObjectPool } from './object-pool';

export class Folder extends ApplicationRecord {
  id: string;
  path: string;
  organizationGrant: boolean;
  projectGrant: boolean;
  createdAt: string;
  updatedAt: string;

  static prefix = 'folders' as const;

  static schema = z.object({
    id: z.string(),
    path: z.string(),
    organizationGrant: z.boolean(),
    projectGrant: z.boolean(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.path = this.attribute('path');
    this.organizationGrant = this.attribute('organizationGrant');
    this.projectGrant = this.attribute('projectGrant');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
  }
}
